.i-service__body {
  max-width: 385px;
}

.i-service__image {
  --size: 182px;

  flex: 0 0 var(--size);
  max-width: var(--size);
  height: var(--size);
}
