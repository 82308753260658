@use "settings/s-colors" as colors;

.i-person__detail-link {
  min-height: 40px;
  padding-block: 4px;
}

.i-person__description.entry-content {
  ul:first-child {
    margin-top: 0;
  }

  ul.columns {
    @include media-breakpoint-up(sm) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
      column-gap: 16px;
    }
  }
}

.i-person__title.--link {
  text-decoration: none;
  color: var(--c-primary-900);
  transition: color 0.3s ease;

  &:hover {
    text-decoration: underline;
    color: var(--c-conversion-600);
  }
}
