@use "settings/s-colors" as colors;

.g-references-slider__button {
  --offset: calc(50% + 8px);

  &.--prev {
    transform: translateX(calc(-1 * var(--offset)));
  }

  &.--next {
    transform: translateX(var(--offset));
  }

  @include media-breakpoint-down(sm) {
    --offset: 16px;
  }

  &.swiper-button-disabled {
    opacity: 0;
    pointer-events: none;
  }
}

.g-references-slider {
  &.swiper {
    display: flex;
    align-items: center;
    overflow: initial;
    height: 100%;
  }

  & .swiper-wrapper {
    display: flex;
  }

  .swiper-slide {
    box-sizing: border-box;
    height: auto;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;
  }

  .swiper-slide-visible {
    opacity: 1;
    pointer-events: auto;
  }
}

.g-references-slider__buttons::before {
  content: "";
  position: absolute;
  background-color: colors.$white;
  top: 50%;
  left: 0;
  transform: translate(calc(-100% + 80px), -50%);
  width: 416px;
  height: 1px;
  z-index: 1;
  pointer-events: none;
}
