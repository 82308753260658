.i-partner {
  --logo-width: 156px;
  --logo-height: 70px;
}

.i-partner__logo {
  width: var(--logo-width);
  height: var(--logo-height);
  max-width: var(--logo-width);
  margin-inline: auto;
}
