.m-person-filter {
  .c-modal__header,
  .c-modal__footer {
    padding: 0;
  }

  &:not(:has(.i-selected-tag)) .m-person-filter__reset-btn {
    display: none;
  }
}

.m-person-filter__reset,
.m-person-filter__submit {
  @include media-breakpoint-up(md) {
    width: 50%;
  }
}
