@use "settings/s-general.scss" as general;
@use "settings/s-colors.scss" as colors;
/* stylelint-disable no-descending-specificity */

.entry-content {
  .table-responsive {
    margin: general.$spacing * 2 0;
    border: general.$spacing * 2 solid var(--c-white);
    overflow: auto;
  }

  th,
  td,
  tr {
    border: none !important;
  }

  td,
  th {
    padding: 16px;
    text-align: center;
    position: relative;
  }

  tfoot {
    background-color: var(--c-white);
    color: var(--c-primary-900);
    font-size: 16px;
    font-weight: 400;

    th,
    td {
      &::before {
        content: "";
        position: absolute;
        height: 1px;
        width: 100%;
        background: var(--c-primary-300);
        top: 0;
        right: 0;
      }
    }
  }

  thead {
    background-color: var(--c-primary-300);
    color: var(--c-primary-900);
    font-size: 16px;
    font-weight: 400;

    th,
    td {
      &:first-child {
        border-radius: 24px 0 0 24px;
      }

      &:last-child {
        border-radius: 0 24px 24px 0;
      }
    }
  }

  tbody {
    background-color: var(--c-white);
    color: var(--c-primary-900);
    font-size: 16px;
    font-weight: 325;

    tr {
      &:nth-child(2n) {
        background-color: colors.$gray-100;
      }
    }

    td,
    th {
      padding: 16px general.$spacing * 3;

      &:first-child {
        border-radius: 24px 0 0 24px;
      }

      &:last-child {
        border-radius: 0 24px 24px 0;
      }
    }
  }

  table {
    margin-bottom: 0 !important;
    width: 100%;
    max-width: 100%;

    p {
      margin-bottom: 0 !important;
      font-size: 16px;
      transform: none;
    }
  }
}
/* stylelint-enable no-descending-specificity */
