@use "settings/s-colors.scss" as colors;

.c-modal__content {
  pointer-events: all;
  width: var(--width);
  background-clip: padding-box;
  background-color: var(--content-background-color);
  border-radius: var(--content-border-radius);
  box-shadow: var(--content-box-shadow);
  transform: translateY(var(--content-translate));
  transition: transform var(--transtion-length) ease;
  max-height: calc(100vh - 64px);

  .os-scrollbar {
    --os-padding-axis: 40px;

    @include media-breakpoint-down(sm) {
      --os-padding-axis: 32px;
    }
  }
}

.c-modal__dialog {
  margin: 32px auto;
}

.c-modal__body {
  padding: var(--content-padding-y) var(--content-padding-x);
}

.c-modal__header {
  padding: var(--content-padding-y) var(--content-padding-x) 0;
}

.c-modal__button-container {
  top: 16px;
  right: 16px;

  @include media-breakpoint-down(sm) {
    top: 12px;
    right: 12px;
  }
}

.c-modal__close-cross.btn {
  --min-height: 24px;
  --svg-size: 24px;

  background: none;
}

.c-modal__footer {
  padding: 0 var(--content-padding-x) var(--content-padding-y);
}

.c-modal {
  --transtion-length: 300ms;
  --width: min(100% - 32px, 928px);
  --dialog-background-color: rgb(0 0 0 / 25%);
  --content-translate: 32px;
  --content-padding-x: 40px;
  --content-padding-y: 40px;
  --content-background-color: var(--c-white);
  --content-border-radius: 40px;
  --content-box-shadow: 0px 1px 40px 0px #40414040;

  z-index: 9002;
  background-color: var(--dialog-background-color);
  transition: opacity var(--transtion-length) ease;

  &.--open {
    --content-translate: 0;

    opacity: 1;
    pointer-events: all;
    visibility: visible;
  }

  &.--hidden {
    visibility: hidden;
    pointer-events: none;
    z-index: -1;
  }

  &.--size-sm {
    --width: min(100% - 32px, 480px);
  }

  &.--size-xl {
    --width: min(100% - 32px, 1140px);
  }

  @include media-breakpoint-down(sm) {
    --content-padding-x: 32px;
    --content-padding-y: 32px;
    --content-border-radius: 32px;
  }
}
