@use "settings/s-colors.scss" as colors;

.wp-breadcrumbs {
  --gap: 24px;
}

.wp-breadcrumbs__group {
  // actuall wrapping group 🙈
  > span {
    display: flex;
    gap: var(--gap);
  }

  @include media-breakpoint-down(md) {
    display: none;
  }
}

// Breadcrumb item 🙈
.wp-breadcrumbs__group > span span {
  --height: 56px;

  min-height: var(--height);
  display: flex;
  align-items: center;

  a {
    display: block;
  }

  // Breadcrumb item -> first one 🙈
  &:first-child a {
    display: inline-block;
    color: transparent;
    width: 24px;
    height: var(--height);
    position: relative;
    background-image: url("../images/svg/home.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px;
    user-select: none;
  }

  &.breadcrumb_last {
    font-weight: 600;
  }

  &:not(:first-child) {
    position: relative;

    // separator
    &::before {
      content: "";
      position: absolute;
      display: block;
      width: 4px;
      height: 4px;
      background-color: colors.$primary;
      left: calc(var(--gap) / -2);
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
}
