@use "settings/s-colors" as colors;

.i-social {
  --size: 40px;
  --img-size: 24px;

  width: var(--size);
  height: var(--size);
  flex: 0 0 var(--size);
  max-width: var(--size);
  transition: background-color 0.3s ease;

  img {
    width: var(--img-size);
    height: var(--img-size);
  }
}

a.i-social {
  &:hover {
    background-color: colors.$gray-100;
  }
}
