@use "settings/s-colors.scss" as colors;

.w-job {
  box-shadow: 0 1px 40px 0 #40414040;
  border: 8px solid colors.$white;
  aspect-ratio: 1 / 1.27;

  @include media-breakpoint-down(xl) {
    width: min(100%, 448px);
    margin-inline: auto;
  }
}

.w-job__content {
  bottom: 16px;
  left: 50%;
  width: calc(100% - 32px);
  transform: translate(-50%, 0);
  background-color: rgba(colors.$white, 80%);

  @include media-breakpoint-down(sm) {
    width: 100%;
    height: 100%;
    bottom: 0;
    padding: 16px;
    justify-content: center;
  }
}
