@use "settings/s-shadows" as shadows;

.u-shadow {
  box-shadow: shadows.$shadow;
}

.u-shadow-sm {
  box-shadow: shadows.$shadow-sm;
}

.u-shadow-drop {
  filter: drop-shadow(0 1px 8px shadows.$color);
}
