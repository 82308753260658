.u-links-black {
  a {
    color: var(--c-primary-900);
  }
}

.u-links-fw-400 {
  a {
    font-weight: 400;
  }
}
