@use "settings/s-colors" as colors;
@use "settings/s-shadows" as shadows;

.c-language-select {
  --dropdown-opacity: 0;
  --dropdown-pointer-events: none;
  --color: var(--c-primary-900);
  --background: transparent;

  &:hover {
    --dropdown-opacity: 1;
    --dropdown-pointer-events: auto;
    --color: var(--c-primary-900);
    --background: var(--c-primary-100);
  }
}

.c-language-select__drowpdown {
  background-color: colors.$white;
  transition: opacity 0.3s ease;
  top: 100%;
  left: 0;
  opacity: var(--dropdown-opacity);
  pointer-events: var(--dropdown-pointer-events);
  box-shadow: shadows.$shadow;
  border-radius: 16px;
}

.c-language-select__active {
  min-height: 40px;
  color: var(--color);
  background-color: var(--background);
  transition:
    background-color 0.3s ease,
    color 0.3s ease;

  @media (width <= 440px) {
    margin-right: 8px;
  }
}

.c-language-select__dropdown-link {
  color: var(--color);
  text-decoration: none;
  transition: background-color 0.3s ease;

  &:hover {
    color: var(--color);
    background-color: var(--c-conversion-100);
  }

  &:focus {
    color: var(--color);
  }
}
