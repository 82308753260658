.u-border-none {
  border: none;
}

.u-border-conversion-500 {
  border-color: var(--c-conversion-500);
}

.u-border-conversion-300 {
  border-color: var(--c-conversion-300);
}

.u-border {
  border-width: 1px;
  border-style: solid;
}

.u-border-bottom-4px-primary-300 {
  border-bottom: 4px solid var(--c-primary-300);
}
