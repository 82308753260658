.c-svg {
  opacity: 0;
  transition: opacity 0.3s ease;

  &.entered {
    opacity: 1;
  }

  &.--colored {
    path {
      fill: var(--color);
      transition: fill 0.3s ease;
    }
  }

  &.--primary {
    --color: var(--c-primary-500);
  }

  &.--conversion {
    --color: var(--c-conversion-500);
  }

  &.--conversion-600 {
    --color: var(--c-conversion-600);
  }

  &.--text {
    --color: var(--c-primary-900);
  }

  &.--white {
    --color: var(--c-white);
  }
}
