@use "settings/s-colors" as colors;

.c-overlay {
  --opacity: 0;
  --pointer-events: none;
  --position: fixed;

  inset: 0;
  background-color: var(--c-primary-900);
  opacity: var(--opacity);
  pointer-events: var(--pointer-events);
  transition: opacity 0.3s ease;
  position: var(--position);

  &.--show {
    --opacity: 0.5;
    --pointer-events: auto;
  }

  &.--absolute {
    --position: absolute;
  }
}
