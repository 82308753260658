@use "settings/s-colors" as colors;

.f-range-slider {
  --height: 4px;
  --thumb-size: 16px;
  --thumb-offset: 8px;
  --color: var(--c-primary-900);
  --background: var(--c-conversion-300);
  --input-width: 160px;
  --gradient-from: calc(var(--background-from) - var(--thumb-offset));
  --gradient-to: calc(var(--background-to) + var(--thumb-offset));

  .f-label {
    line-height: 24px;
  }
}

.f-range-slider__control {
  border: 1px solid var(--c-primary-500);
  border-radius: 37px;
  padding: 12px 16px;
  min-height: 48px;
  font-weight: 350;
  width: min(var(--input-width), var(--input-width));

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.f-range-slider__number {
  appearance: textfield;
  border: none;
  padding: 0;
  font-weight: 350;
  margin-right: 4px;

  &:focus {
    border: none;
    outline: none;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.f-range-slider__ranges {
  height: var(--height);
}

.f-range-slider__range-wrap {
  &::before {
    content: "";
    position: absolute;
    height: var(--height);
    inset: 0;
    background-color: var(--background);
    z-index: -1;
    border-radius: var(--height);
  }
}

.f-range-slider__inputs {
  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }
}

.f-range-slider__range {
  appearance: none;
  outline: none;
  pointer-events: none;
  inset: 0 var(--thumb-offset);
  height: var(--height);
  width: calc(100% - var(--thumb-size));
  position: absolute;
  background: linear-gradient(
    90deg,
    transparent 0%,
    transparent var(--gradient-from),
    var(--color) var(--gradient-from),
    var(--color) var(--gradient-to),
    transparent var(--gradient-to),
    transparent 100%
  );

  &::-webkit-slider-thumb,
  &::-moz-range-thumb {
    appearance: none;
    pointer-events: all;
    width: var(--thumb-size);
    height: var(--thumb-size);
    background-color: var(--c-conversion-300);
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    transform: translateX(calc(var(--thumb-offset) * var(--side)));
    transition: 0.2s ease background-color;
    z-index: 2;
  }

  &.--from {
    --side: -1;
  }

  &.--to {
    --side: 1;
  }
}
