.u-scrollbar {
  .os-scrollbar {
    --os-handle-bg: var(--c-conversion-300);
    --os-handle-bg-hover: var(--c-conversion-300);
    --os-handle-bg-active: var(--c-conversion-500);
    --os-handle-border-radius: 8px;
    --os-size: 16px;
    --os-track-bg: var(--c-primary-300);
    --os-track-bg-hover: var(--c-primary-300);
    --os-track-bg-active: var(--c-primary-300);
    --os-padding-perpendicular: 6px;
    --os-padding-axis: 16px;

    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }
}

.u-scrollbar-native {
  --sb-track-color: var(--c-white);
  --sb-thumb-color: var(--c-conversion-500);
  --sb-size: 8px;

  &::-webkit-scrollbar {
    width: var(--sb-size);
  }

  &::-webkit-scrollbar:horizontal {
    height: var(--sb-size);
  }

  &::-webkit-scrollbar-track {
    background: var(--sb-track-color);
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--sb-thumb-color);
    border-radius: 8px;
    border: 1px solid #fff;
  }
}

@supports not selector(::-webkit-scrollbar) {
  .u-scrollbar-native {
    scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
  }
}
