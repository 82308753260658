@use "settings/s-colors" as colors;

.f-single-select-inline__select {
  --ss-primary-color: var(--c-conversion-300);
  --ss-bg-color: transparent;
  --ss-font-color: var(--c-primary-900);
  --ss-border-color: transparent;
  --ss-success-color: #{colors.$success};
  --ss-error-color: #{colors.$danger};
  --ss-main-height: 24px;
  --ss-content-height: auto;
  --ss-spacing-l: 0;
  --ss-spacing-m: 4px;
  --ss-spacing-s: 2px;
  --ss-animation-timing: 0.3s;
  --ss-border-radius: 12px;
  --text-decoration-color: var(--c-primary-900);
  --selected-text-color: var(--c-primary-900);
  --option-text-color: var(--c-primary-900);
  --option-text-decoration-color: transparent;

  font-size: 16px;
  border-radius: 8px;

  &:hover {
    --selected-text-color: var(--c-conversion-500);
    --text-decoration-color: transparent;
  }

  .ss-single,
  .ss-option {
    color: var(--selected-text-color);
    text-decoration: underline;
    text-decoration-color: var(--text-decoration-color);
    transition: text-decoration-color 0.3s ease;
    transition-property: text-decoration-color, color;
    will-change: text-decoration-color, color;
  }

  &.ss-content {
    min-width: 142px;
    border-top: 0;
    border-radius: 12px;
    font-size: 14px;
    line-height: 16px;
    box-shadow: none;
    transform: translateY(8px) !important;
    pointer-events: none;

    .ss-list {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 8px;
      pointer-events: none;

      .ss-option {
        font-weight: 350;
        text-decoration-color: var(--option-text-decoration-color);

        &:hover {
          --option-text-decoration-color: transparent;

          color: var(--c-conversion-500);
          background-color: transparent;
        }
      }
    }
  }

  &.ss-open-above,
  &.ss-open-below {
    --ss-bg-color: #{colors.$white};

    &.ss-content {
      .ss-list {
        pointer-events: auto;
      }
    }
  }

  &.ss-open-above {
    &.ss-content {
      transform: translateY(-22px) !important;
    }
  }

  &.ss-main {
    width: auto;
    font-weight: 400;
    border: none;
    outline: none;
    background-color: transparent;
    padding: 0;
    border-radius: 0;

    &::after {
      display: block;
      content: "";
      width: 32px;
      height: 24px;
      margin-left: 4px;
      background-image: url("../images/svg/ss-inline-arrow.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      align-items: center;
      justify-content: center;
      pointer-events: none;
      scale: 1;
      transition: scale 0.3s ease;
    }

    &:focus {
      box-shadow: none;
    }

    &.ss-open-above,
    &.ss-open-below {
      border-color: var(--ss-border-color);
      border-bottom-color: transparent;
    }

    .ss-arrow {
      display: none;
    }
  }
}
