.g-persons__cell {
  &.--inline {
    order: -1;
    width: 100%;
    max-width: 100%;

    @include media-breakpoint-down(lg) {
      max-width: calc(480px + 48px);
    }

    @include media-breakpoint-down(md) {
      max-width: calc(480px + 32px);
    }
  }

  @include media-breakpoint-down(xxl) {
    max-width: 480px;
  }
}
