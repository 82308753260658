@use "settings/s-colors" as colors;
@use "settings/s-shadows" as shadows;

.m-header {
  --shadow-color: transparent;
  --background: transparent;
  --min-height: 72px;

  background-color: var(--background);
  box-shadow: 4px 8px 24px var(--shadow-color);
  transition: box-shadow 0.3s ease;
  transition-property: box-shadow, transform;

  &.headroom--not-top {
    --background: transparent;
  }

  @include media-breakpoint-down(xl) {
    .m-header-search {
      display: none;
    }

    .c-language-select {
      display: none;
    }
  }
}

.m-header__body {
  min-height: var(--min-height);
}

.m-header__side-menu {
  @media (width <= 440px) {
    gap: 8px;
  }
}

.m-header__contact-button {
  &.btn,
  &.btn.--xs {
    --font-size: 16px;

    line-height: 20px;
  }
}

.m-header__functions {
  position: relative;
}

.m-header__phone {
  @include media-breakpoint-up(xl) {
    margin-left: 8px;

    &::before {
      content: "";
      height: calc(100% - 5px);
      width: 1px;
      background-color: var(--c-primary-300);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -8px;
    }
  }

  @include media-breakpoint-down(md) {
    .link__title {
      display: none;
    }
  }

  .i-social {
    background-color: transparent;

    @include media-breakpoint-down(md) {
      border: 1px solid var(--c-conversion-500);
      background-color: colors.$white;

      --size: 48px;
    }
  }
}
