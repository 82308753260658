@use "settings/s-general" as general;
@use "settings/s-colors" as colors;

$padding-x: 16px;
$padding-y: 10px;

$background: colors.$white;
$color-placeholder: colors.$gray;

$text-area-min-height: 115px;

.f-base {
  display: block;
  width: 100%;
  color: colors.$gray-900;
  font-weight: 350;
  background-color: $background;
  background-clip: padding-box;
  padding: $padding-y $padding-x;
  appearance: none;
  border: none;
  min-height: 46px;

  &:focus {
    border-color: none;
    outline: none;
  }

  &::placeholder {
    opacity: 1;
    color: $color-placeholder;
    font-weight: 325;
  }
}

textarea.f-base {
  min-height: $text-area-min-height;
  resize: none;
}
