.i-clinic__opening-hour {
  grid-column: span 2;
  grid-template-columns: subgrid;
}

.i-clinic__opening-hours-list {
  max-width: 285px;
}

.i-clinic-detail__contact .i-social {
  --size: 24px;

  background-color: transparent;
}
