.i-contact-pill__icon {
  --size: 72px;
  --svg-size: 40px;

  max-width: var(--size);
  height: var(--size);
  flex: 0 0 var(--size);

  svg {
    width: var(--svg-size);
    height: var(--svg-size);
  }

  @include media-breakpoint-down(sm) {
    --size: 48px;
    --svg-size: 24px;
  }
}
