@use "settings/s-colors" as colors;

.f-textarea {
  --border-color: var(--c-primary-500);
  --radius: 24px;

  &.--active {
    --border-color: var(--c-conversion-500);
  }

  &.--invalid {
    --border-color: #{colors.$danger};
  }
}

.f-textarea__wrap {
  border: 1px solid var(--border-color);
  border-radius: var(--radius);
}

.f-textarea__input {
  border-radius: var(--radius);
}
