:root {
  /* Colors */
  --c-primary-100: #f3f3f4;
  --c-primary-300: #d2c1d0;
  --c-primary-500: #824f7d;
  --c-primary-900: #42123d;
  --c-secondary-500: #d7dcf2;
  --c-conversion-100: #fef4f7;
  --c-conversion-300: #fabed1;
  --c-conversion-500: #f67ea3;
  --c-conversion-600: #be5475;
  --c-white: #fff;
  --c-white-o-70: #ffffffb3;

  /* Section spacing */
  --section-spacing: 112px;
  --section-background-color: var(--c-primary-100);

  @include media-breakpoint-down(sm) {
    --section-spacing: 64px;
  }
}

@font-face {
  font-family: "Adjusted Georgia";
  size-adjust: 110%;
  src: local(georgia);
}

@font-face {
  font-family: gotham-rounded;
  src: url("../fonts/GothamRnd-Book.woff2") format("woff2");
  font-weight: 325;
  font-display: swap;
}

@font-face {
  font-family: gotham-rounded;
  src: url("../fonts/GothamRnd-Bold.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: gotham-rounded;
  src: url("../fonts/GothamRnd-Medium.woff2") format("woff2");
  font-weight: 350;
  font-display: swap;
}
