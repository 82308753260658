@use "settings/s-colors.scss" as colors;

.img {
  --placeholder-color: var(--c-conversion-100);

  &.--ratio-4-3 {
    aspect-ratio: 4/3;
  }

  &.--ratio-16-9 {
    aspect-ratio: 16/9;
  }

  &.--ratio-3-2 {
    aspect-ratio: 3/2;
  }

  &.--ratio-1-1 {
    aspect-ratio: 1/1;
  }

  &.--ratio-3-4 {
    aspect-ratio: 3/4;
  }

  &.--contain {
    width: 100%;
    height: 100%;

    img {
      object-fit: contain;
      height: 100%;
      width: 100%;
    }

    picture {
      height: 100%;
      width: 100%;
    }
  }

  &.--cover {
    width: 100%;
    height: 100%;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    picture {
      height: 100%;
      width: 100%;
    }
  }

  &.--placeholder {
    --opacity: 0.3;

    &:has(img.loaded) {
      --opacity: 0;
    }

    &::before {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      background-color: var(--placeholder-color);
      inset: 0;
      z-index: 0;
      opacity: var(--opacity);
      transition: opacity 0.3s ease;
    }
  }
}
