*, :before, :after {
  box-sizing: border-box;
}

body {
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  margin: 0;
}

hr {
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: .25;
  margin: .5rem 0;
}

h6, h5, h4, h3, h2, h1 {
  color: var(--bs-heading-color);
  margin-top: 0;
  margin-bottom: .25rem;
  font-weight: 500;
  line-height: 1.2;
}

h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (width >= 75em) {
  h1 {
    font-size: 2.5rem;
  }
}

h2 {
  font-size: calc(1.325rem + .9vw);
}

@media (width >= 75em) {
  h2 {
    font-size: 2rem;
  }
}

h3 {
  font-size: calc(1.3rem + .6vw);
}

@media (width >= 75em) {
  h3 {
    font-size: 1.75rem;
  }
}

h4 {
  font-size: calc(1.275rem + .3vw);
}

@media (width >= 75em) {
  h4 {
    font-size: 1.5rem;
  }
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul {
  padding-left: 2rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

small {
  font-size: .875em;
}

mark {
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg);
  padding: .1875em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}

a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre, code {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: .875em;
  display: block;
  overflow: auto;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  color: var(--bs-code-color);
  word-wrap: break-word;
  font-size: .875em;
}

a > code {
  color: inherit;
}

img, svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  color: var(--bs-secondary-color);
  text-align: left;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead, tbody, tfoot, tr, td, th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input, button, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button, [type="button"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  resize: vertical;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-datetime-edit-text {
  padding: 0;
}

::-webkit-datetime-edit-minute {
  padding: 0;
}

::-webkit-datetime-edit-hour-field {
  padding: 0;
}

::-webkit-datetime-edit-day-field {
  padding: 0;
}

::-webkit-datetime-edit-month-field {
  padding: 0;
}

::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -.125rem;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

iframe {
  border: 0;
}

[hidden] {
  display: none !important;
}

.container, .container-fluid {
  --bs-gutter-x: 2rem;
  --bs-gutter-y: 0;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (width >= 36em) {
  .container {
    max-width: 33.75rem;
  }
}

@media (width >= 48em) {
  .container {
    max-width: 45rem;
  }
}

@media (width >= 62em) {
  .container {
    max-width: 60rem;
  }
}

@media (width >= 75em) {
  .container {
    max-width: 71.25rem;
  }
}

@media (width >= 93.75em) {
  .container {
    max-width: 91.25rem;
  }
}

:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 36rem;
  --bs-breakpoint-md: 48rem;
  --bs-breakpoint-lg: 62rem;
  --bs-breakpoint-xl: 75rem;
  --bs-breakpoint-xxl: 93.75rem;
  --bs-breakpoint-xxxl: 120rem;
}

.row {
  --bs-gutter-x: 2rem;
  --bs-gutter-y: 0;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
  flex-wrap: wrap;
  display: flex;
}

.row > * {
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
}

.col {
  flex: 1 0;
}

.col-6 {
  flex: none;
  width: 50%;
}

.col-8 {
  flex: none;
  width: 66.6667%;
}

.col-12 {
  flex: none;
  width: 100%;
}

.gx-0 {
  --bs-gutter-x: 0;
}

.g-2, .gx-2 {
  --bs-gutter-x: 1rem;
}

.g-2, .gy-2 {
  --bs-gutter-y: 1rem;
}

.gx-3 {
  --bs-gutter-x: 1.5rem;
}

.g-4, .gx-4 {
  --bs-gutter-x: 2rem;
}

.g-4, .gy-4 {
  --bs-gutter-y: 2rem;
}

.gy-5 {
  --bs-gutter-y: 2.5rem;
}

.gy-8 {
  --bs-gutter-y: 4rem;
}

@media (width >= 36em) {
  .col-sm-6 {
    flex: none;
    width: 50%;
  }

  .col-sm-12 {
    flex: none;
    width: 100%;
  }

  .g-sm-4 {
    --bs-gutter-x: 2rem;
  }

  .g-sm-4, .gy-sm-4 {
    --bs-gutter-y: 2rem;
  }
}

@media (width >= 48em) {
  .col-md-3 {
    flex: none;
    width: 25%;
  }

  .col-md-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-md-6 {
    flex: none;
    width: 50%;
  }

  .g-md-4, .gx-md-4 {
    --bs-gutter-x: 2rem;
  }

  .g-md-4 {
    --bs-gutter-y: 2rem;
  }
}

@media (width >= 62em) {
  .col-lg-3 {
    flex: none;
    width: 25%;
  }

  .col-lg-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-lg-6 {
    flex: none;
    width: 50%;
  }

  .col-lg-8 {
    flex: none;
    width: 66.6667%;
  }
}

@media (width >= 75em) {
  .col-xl {
    flex: 1 0;
  }

  .col-xl-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-xl-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-xl-3 {
    flex: none;
    width: 25%;
  }

  .col-xl-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-xl-6 {
    flex: none;
    width: 50%;
  }

  .col-xl-10 {
    flex: none;
    width: 83.3333%;
  }
}

@media (width >= 93.75em) {
  .col-xxl-3 {
    flex: none;
    width: 25%;
  }

  .col-xxl-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-xxl-8 {
    flex: none;
    width: 66.6667%;
  }
}

.ratio {
  width: 100%;
  position: relative;
}

.ratio:before {
  padding-top: var(--bs-aspect-ratio);
  content: "";
  display: block;
}

.ratio > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.opacity-0 {
  opacity: 0;
}

.opacity-50 {
  opacity: .5;
}

.opacity-100 {
  opacity: 1;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.d-block {
  display: block;
}

.d-grid {
  display: grid;
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.d-none {
  display: none;
}

.shadow-sm {
  box-shadow: var(--bs-box-shadow-sm);
}

.position-relative {
  position: relative;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.start-0 {
  left: 0;
}

.w-100 {
  width: 100%;
}

.w-auto {
  width: auto;
}

.mw-100 {
  max-width: 100%;
}

.h-100 {
  height: 100%;
}

.flex-fill {
  flex: auto;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-center {
  align-items: center;
}

.align-self-start {
  align-self: flex-start;
}

.align-self-end {
  align-self: flex-end;
}

.align-self-center {
  align-self: center;
}

.order-first {
  order: -1;
}

.m-1 {
  margin: .5rem;
}

.mx-2 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-8 {
  margin-left: 4rem;
  margin-right: 4rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-2 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-4 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mt-1 {
  margin-top: .5rem;
}

.mt-2 {
  margin-top: 1rem;
}

.mt-3 {
  margin-top: 1.5rem;
}

.mt-4 {
  margin-top: 2rem;
}

.mt-5 {
  margin-top: 2.5rem;
}

.mt-auto {
  margin-top: auto;
}

.me-1 {
  margin-right: .5rem;
}

.me-auto {
  margin-right: auto;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: .5rem;
}

.mb-2 {
  margin-bottom: 1rem;
}

.mb-3 {
  margin-bottom: 1.5rem;
}

.mb-4 {
  margin-bottom: 2rem;
}

.mb-5 {
  margin-bottom: 2.5rem;
}

.mb-7 {
  margin-bottom: 3.5rem;
}

.mb-10 {
  margin-bottom: 5rem;
}

.ms-0 {
  margin-left: 0;
}

.ms-1 {
  margin-left: .5rem;
}

.ms-auto {
  margin-left: auto;
}

.p-1 {
  padding: .5rem;
}

.p-2 {
  padding: 1rem;
}

.p-3 {
  padding: 1.5rem;
}

.p-4 {
  padding: 2rem;
}

.px-1 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-2 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-3 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-4 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-5 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-1 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-3 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-4 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-5 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.pt-3 {
  padding-top: 1.5rem;
}

.pt-4 {
  padding-top: 2rem;
}

.pt-5 {
  padding-top: 2.5rem;
}

.pe-2 {
  padding-right: 1rem;
}

.pe-5 {
  padding-right: 2.5rem;
}

.pb-2 {
  padding-bottom: 1rem;
}

.pb-3 {
  padding-bottom: 1.5rem;
}

.pb-4 {
  padding-bottom: 2rem;
}

.pb-5 {
  padding-bottom: 2.5rem;
}

.ps-2 {
  padding-left: 1rem;
}

.ps-5 {
  padding-left: 2.5rem;
}

.gap-0 {
  gap: 0;
}

.gap-1 {
  gap: .5rem;
}

.gap-2 {
  gap: 1rem;
}

.gap-3 {
  gap: 1.5rem;
}

.gap-4 {
  gap: 2rem;
}

.gap-5 {
  gap: 2.5rem;
}

.gap-6 {
  gap: 3rem;
}

.gap-7 {
  gap: 3.5rem;
}

.row-gap-1 {
  row-gap: .5rem;
}

.row-gap-2 {
  row-gap: 1rem;
}

.row-gap-3 {
  row-gap: 1.5rem;
}

.column-gap-1 {
  column-gap: .5rem;
}

.column-gap-3 {
  column-gap: 1.5rem;
}

.column-gap-5 {
  column-gap: 2.5rem;
}

.text-start {
  text-align: left;
}

.text-end {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-decoration-none {
  text-decoration: none;
}

.text-nowrap {
  white-space: nowrap;
}

.text-break {
  word-wrap: break-word;
  word-break: break-word;
}

.user-select-none {
  -webkit-user-select: none;
  user-select: none;
}

.pe-none {
  pointer-events: none;
}

.pe-auto {
  pointer-events: auto;
}

@media (width >= 36em) {
  .d-sm-flex {
    display: flex;
  }

  .w-sm-auto {
    width: auto;
  }

  .flex-sm-row {
    flex-direction: row;
  }

  .align-items-sm-center {
    align-items: center;
  }

  .mx-sm-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .mt-sm-1 {
    margin-top: .5rem;
  }

  .mt-sm-2 {
    margin-top: 1rem;
  }

  .mt-sm-5 {
    margin-top: 2.5rem;
  }

  .me-sm-2 {
    margin-right: 1rem;
  }

  .ms-sm-auto {
    margin-left: auto;
  }

  .p-sm-3 {
    padding: 1.5rem;
  }

  .p-sm-4 {
    padding: 2rem;
  }

  .px-sm-3 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .py-sm-1 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .gap-sm-1 {
    gap: .5rem;
  }

  .gap-sm-2 {
    gap: 1rem;
  }

  .gap-sm-3 {
    gap: 1.5rem;
  }

  .gap-sm-4 {
    gap: 2rem;
  }

  .text-sm-start {
    text-align: left;
  }
}

@media (width >= 48em) {
  .d-md-block {
    display: block;
  }

  .w-md-auto {
    width: auto;
  }

  .flex-md-row {
    flex-direction: row;
  }

  .flex-md-grow-0 {
    flex-grow: 0;
  }

  .justify-content-md-end {
    justify-content: flex-end;
  }

  .justify-content-md-center {
    justify-content: center;
  }

  .align-items-md-start {
    align-items: flex-start;
  }

  .align-items-md-center {
    align-items: center;
  }

  .mx-md-5 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .mb-md-0 {
    margin-bottom: 0;
  }

  .ms-md-auto {
    margin-left: auto;
  }

  .p-md-1 {
    padding: .5rem;
  }

  .p-md-5 {
    padding: 2.5rem;
  }

  .p-md-7 {
    padding: 3.5rem;
  }

  .ps-md-2 {
    padding-left: 1rem;
  }

  .gap-md-2 {
    gap: 1rem;
  }

  .gap-md-3 {
    gap: 1.5rem;
  }

  .gap-md-5 {
    gap: 2.5rem;
  }

  .text-md-start {
    text-align: left;
  }
}

@media (width >= 62em) {
  .d-lg-grid {
    display: grid;
  }

  .flex-lg-row {
    flex-direction: row;
  }

  .justify-content-lg-center {
    justify-content: center;
  }

  .align-items-lg-center {
    align-items: center;
  }

  .mx-lg-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .mb-lg-4 {
    margin-bottom: 2rem;
  }

  .p-lg-4 {
    padding: 2rem;
  }

  .p-lg-5 {
    padding: 2.5rem;
  }

  .px-lg-5 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .gap-lg-3 {
    gap: 1.5rem;
  }

  .gap-lg-7 {
    gap: 3.5rem;
  }

  .column-gap-lg-10 {
    column-gap: 5rem;
  }
}

@media (width >= 75em) {
  .d-xl-block {
    display: block;
  }

  .d-xl-flex {
    display: flex;
  }

  .d-xl-none {
    display: none;
  }

  .flex-xl-row {
    flex-direction: row;
  }

  .flex-xl-column {
    flex-direction: column;
  }

  .justify-content-xl-center {
    justify-content: center;
  }

  .justify-content-xl-between {
    justify-content: space-between;
  }

  .ms-xl-0 {
    margin-left: 0;
  }

  .gap-xl-3 {
    gap: 1.5rem;
  }
}

@media (width >= 93.75em) {
  .ms-xxl-4 {
    margin-left: 2rem;
  }
}

.container {
  padding-left: 1.625rem;
  padding-right: 1.625rem;
}

@media (width <= 35.999em) {
  .container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width <= 359px) {
  .container {
    padding-left: .5rem;
    padding-right: .5rem;
  }
}

.gap-0\.5 {
  gap: .25rem;
}

.fancybox__container {
  --fancybox-accent-color: var(--c-conversion-500);
}

.fancybox__container img {
  border-radius: 1rem;
}

.flatpickr-calendar {
  --theme-color: #00a9a6;
  --theme-color-hover: #476cfe;
}

.flatpickr-calendar .flatpickr-day.selected, .flatpickr-calendar .flatpickr-day.selected:hover {
  background: var(--theme-color);
  border-color: var(--theme-color);
}

.flatpickr-calendar .flatpickr-months .flatpickr-prev-month:hover svg, .flatpickr-calendar .flatpickr-months .flatpickr-next-month:hover svg {
  fill: var(--theme-color);
}

.swiper {
  --swiper-pagination-color: var(--c-conversion-500);
  overflow: hidden;
}

.swiper-wrapper {
  display: flex;
}

@media (width <= 35.999em) {
  :root {
    --section-spacing: 4rem;
  }
}

@font-face {
  font-family: Adjusted Georgia;
  size-adjust: 110%;
  src: local(georgia);
}

@font-face {
  font-family: gotham-rounded;
  src: url("GothamRnd-Book.5369c531.woff2") format("woff2");
  font-weight: 325;
  font-display: swap;
}

@font-face {
  font-family: gotham-rounded;
  src: url("GothamRnd-Bold.bf977a88.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: gotham-rounded;
  src: url("GothamRnd-Medium.010b5b65.woff2") format("woff2");
  font-weight: 350;
  font-display: swap;
}

body {
  color: var(--c-primary-900);
  background-color: var(--c-primary-100);
  -webkit-font-smoothing: antialiased;
  min-height: calc(var(--vh, 1vh) * 100);
  flex-direction: column;
  min-height: 100vh;
  font-family: gotham-rounded, Adjusted Georgia;
  font-weight: 325;
  line-height: 1.5;
  display: flex;
  overflow-x: hidden;
  -moz-osx-font-smoothing: grayscale !important;
}

@supports (-webkit-touch-callout: none) {
  body {
    min-height: -webkit-fill-available;
  }
}

body.--uro {
  --c-primary-100: #f3f3f4;
  --c-primary-300: #b2b8c1;
  --c-primary-500: #5d6e89;
  --c-primary-900: #0a2144;
  --c-conversion-100: #e8f6ff;
  --c-conversion-300: #bde4ff;
  --c-conversion-500: #0c58a9;
  --c-conversion-600: #09427e;
}

body.--inter {
  --c-primary-100: #f3f3f4;
  --c-primary-300: #cec5c0;
  --c-primary-500: #87614c;
  --c-primary-900: #331606;
  --c-conversion-100: #f5ecee;
  --c-conversion-300: #e2c7cc;
  --c-conversion-500: #8e2136;
  --c-conversion-600: #6a1828;
}

body.--dent {
  --c-primary-100: #f3f3f4;
  --c-primary-300: #acbcc6;
  --c-primary-500: #4d8299;
  --c-primary-900: #062634;
  --c-conversion-100: #e9f7f7;
  --c-conversion-300: #bfe9e8;
  --c-conversion-500: #00a9a6;
  --c-conversion-600: #007e7c;
}

body.--lab {
  --c-primary-100: #f3f3f4;
  --c-primary-300: #aab0b6;
  --c-primary-500: #4f5d6c;
  --c-primary-900: #222f3a;
  --c-conversion-100: #f9f2f2;
  --c-conversion-300: #eed8da;
  --c-conversion-500: #b04e4b;
  --c-conversion-600: #933b3b;
}

figure {
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  text-wrap: balance;
  margin-bottom: 0;
  font-weight: 400;
}

html {
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
  height: 100%;
}

iframe {
  max-width: 100%;
  display: block;
}

img {
  opacity: 1;
  max-width: 100%;
  height: auto;
  transition: opacity .5s;
  display: block;
}

img[data-src], img[data-srcset] {
  min-width: .0625rem;
  min-height: .0625rem;
  display: block;
}

img[data-src]:not(.loaded) {
  opacity: 0;
}

a {
  color: var(--c-conversion-500);
  text-decoration: underline;
  transition: text-decoration-color .3s;
  display: inline;
}

a:hover, a:focus {
  color: var(--c-conversion-500);
  text-decoration-color: #0000;
}

ul, ol {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

main {
  flex-grow: 1;
  display: block;
}

p {
  text-wrap: pretty;
}

:root {
  --c-primary-100: #f3f3f4;
  --c-primary-300: #d2c1d0;
  --c-primary-500: #824f7d;
  --c-primary-900: #42123d;
  --c-secondary-500: #d7dcf2;
  --c-conversion-100: #fef4f7;
  --c-conversion-300: #fabed1;
  --c-conversion-500: #f67ea3;
  --c-conversion-600: #be5475;
  --c-white: #fff;
  --c-white-o-70: #ffffffb3;
  --section-spacing: 7rem;
  --section-background-color: var(--c-primary-100);
}

@media (width <= 35.999em) {
  :root {
    --section-spacing: 4rem;
  }
}

@font-face {
  font-family: Adjusted Georgia;
  size-adjust: 110%;
  src: local(georgia);
}

@font-face {
  font-family: gotham-rounded;
  src: url("GothamRnd-Book.5369c531.woff2") format("woff2");
  font-weight: 325;
  font-display: swap;
}

@font-face {
  font-family: gotham-rounded;
  src: url("GothamRnd-Bold.bf977a88.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: gotham-rounded;
  src: url("GothamRnd-Medium.010b5b65.woff2") format("woff2");
  font-weight: 350;
  font-display: swap;
}

strong, b {
  font-weight: 350;
}

h1, h2, h3, h4, button, input, label {
  line-height: 1.1;
}

fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
}

progress {
  vertical-align: baseline;
}

* {
  min-width: 0;
}

label[for] {
  cursor: pointer;
}

img, picture, video, canvas, svg {
  max-width: 100%;
  display: block;
}

.entry-content .alignnone {
  margin: .3125rem 1.25rem 1.25rem 0;
}

.entry-content .alignright {
  float: right;
  margin-bottom: 1rem;
  margin-left: 1.5rem;
}

.entry-content .alignright img {
  margin-block: 0;
}

.entry-content .alignleft {
  float: left;
  margin-bottom: 1rem;
  margin-right: 1.5rem;
}

.entry-content .alignleft img {
  margin-block: 0;
}

.entry-content .aligncenter, .entry-content div.aligncenter {
  margin: 3rem auto;
  display: block;
}

.entry-content .aligncenter img, .entry-content div.aligncenter img {
  margin-block: 0;
  margin-inline: auto;
}

@media (width <= 35.999em) {
  .entry-content .alignleft, .entry-content .alignright {
    float: none;
    margin: 0;
    margin: 1rem auto;
    display: block;
  }
}

.screen-reader-text {
  clip: rect(.0625rem, .0625rem, .0625rem, .0625rem);
  width: .0625rem;
  height: .0625rem;
  overflow: hidden;
  position: absolute !important;
}

.screen-reader-text:focus {
  color: #21759b;
  z-index: 100000;
  background-color: #f1f1f1;
  border-radius: .1875rem;
  width: auto;
  height: auto;
  padding: .9375rem 1.4375rem .875rem;
  font-size: .875rem;
  font-weight: bold;
  line-height: normal;
  text-decoration: none;
  display: block;
  top: .3125rem;
  left: .3125rem;
  box-shadow: 0 0 .125rem .125rem #0009;
  clip: auto !important;
}

.u-bg-white {
  background-color: var(--c-white);
}

.u-bg-white-o-70 {
  background-color: var(--c-white-o-70);
}

.u-bg-conversion {
  background-color: var(--c-conversion-500);
}

.u-bg-conversion-100 {
  background-color: var(--c-conversion-100);
}

.u-bg-conversion-300 {
  background-color: var(--c-conversion-300);
}

.u-bg-primary-900 {
  background-color: var(--c-primary-900);
}

.u-bg-primary-100 {
  background-color: var(--c-primary-100);
}

.u-bg-primary-300 {
  background-color: var(--c-primary-300);
}

.u-border-none {
  border: none;
}

.u-border-conversion-500 {
  border-color: var(--c-conversion-500);
}

.u-border-conversion-300 {
  border-color: var(--c-conversion-300);
}

.u-border {
  border-style: solid;
  border-width: .0625rem;
}

.u-border-bottom-4px-primary-300 {
  border-bottom: .25rem solid var(--c-primary-300);
}

.u-clamp {
  --lines: 2;
  -webkit-line-clamp: var(--lines);
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.u-clamp-3 {
  --lines: 3;
}

.u-clamp-4 {
  --lines: 4;
}

.u-clamp-1 {
  --lines: 1;
}

.u-color-conversion-100 {
  color: var(--c-conversion-100);
}

.u-color-conversion-500 {
  color: var(--c-conversion-500);
}

.u-color-white {
  color: var(--c-white);
}

.u-color-text {
  color: var(--c-primary-900);
}

.u-color-primary-500 {
  color: var(--c-primary-500);
}

.u-cursor-pointer {
  cursor: pointer;
}

.u-cursor-auto {
  cursor: auto;
}

.u-fz-xs {
  --size: .75rem;
  font-size: var(--size);
}

.u-fz-sm {
  --size: .875rem;
  font-size: var(--size);
}

.u-fz-md {
  --size: 1rem;
  font-size: var(--size);
}

.u-fz-lg {
  --size: 1.125rem;
  font-size: var(--size);
}

@media (width <= 35.999em) {
  .u-fz-lg {
    --size: 1rem;
  }
}

.u-fz-xl {
  --size: 1.25rem;
  font-size: var(--size);
}

.u-lh-3 {
  line-height: 1.5rem;
}

.u-fw-400 {
  font-weight: 400;
}

.u-fw-700 {
  font-weight: 700;
}

.u-fw-350 {
  font-weight: 350;
}

.u-fw-325 {
  font-weight: 325;
}

.u-h1 {
  --size: 2.75rem;
}

@media (width <= 47.999em) {
  .u-h1 {
    --size: 2.25rem;
  }
}

.u-h2 {
  --size: 2.25rem;
}

@media (width <= 47.999em) {
  .u-h2 {
    --size: 1.75rem;
  }
}

.u-h3 {
  --size: 1.875rem;
}

@media (width <= 47.999em) {
  .u-h3 {
    --size: 1.25rem;
  }
}

.u-h4 {
  --size: 1.5rem;
}

@media (width <= 47.999em) {
  .u-h4 {
    --size: 1.125rem;
  }
}

.u-h5 {
  --size: 1.125rem;
}

@media (width <= 47.999em) {
  .u-h5 {
    --size: 1rem;
  }
}

.u-h1, .u-h2, .u-h3, .u-h4, .u-h5 {
  font-size: var(--size);
}

.u-img-hover {
  backface-visibility: hidden;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.u-img-hover img {
  transition-property: opacity, transform;
}

.u-img-hover:hover img {
  transform: scale(1.03);
}

.u-links-black a {
  color: var(--c-primary-900);
}

.u-links-fw-400 a {
  font-weight: 400;
}

.--loading {
  pointer-events: none;
  z-index: 2;
  position: relative;
}

.--loading:before {
  content: "";
  background-color: var(--c-primary-100);
  z-index: 9000;
  opacity: .8;
  width: 100%;
  height: 100%;
  transition: opacity .3s;
  animation: 2s ease-out infinite opacity-pulse;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.--loading:after {
  content: "";
  border: .25rem solid var(--c-conversion-300);
  border-bottom-color: var(--c-conversion-500);
  z-index: 9001;
  border-radius: 50%;
  width: 1.875rem;
  height: 1.875rem;
  margin-top: -.9375rem;
  margin-left: -.9375rem;
  animation: 1s cubic-bezier(.68, -.55, .27, 1.55) infinite rotation;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

@keyframes rotation {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes opacity-pulse {
  0% {
    opacity: .5;
  }

  50% {
    opacity: .7;
  }

  100% {
    opacity: .5;
  }
}

.u-outline-0 {
  outline: 0;
}

.u-outline-8px {
  outline: .5rem solid var(--c-white);
}

.u-outline-conversion {
  outline: .0625rem solid var(--c-conversion-500);
}

.u-outline-conversion-300 {
  outline: .0625rem solid var(--c-conversion-300);
}

.u-outline-primary-100 {
  outline: .0625rem solid var(--c-primary-100);
}

.u-scrollbar .os-scrollbar {
  --os-handle-bg: var(--c-conversion-300);
  --os-handle-bg-hover: var(--c-conversion-300);
  --os-handle-bg-active: var(--c-conversion-500);
  --os-handle-border-radius: .5rem;
  --os-size: 1rem;
  --os-track-bg: var(--c-primary-300);
  --os-track-bg-hover: var(--c-primary-300);
  --os-track-bg-active: var(--c-primary-300);
  --os-padding-perpendicular: .375rem;
  --os-padding-axis: 1rem;
  cursor: grab;
}

.u-scrollbar .os-scrollbar:active {
  cursor: grabbing;
}

.u-scrollbar-native {
  --sb-track-color: var(--c-white);
  --sb-thumb-color: var(--c-conversion-500);
  --sb-size: .5rem;
}

.u-scrollbar-native::-webkit-scrollbar {
  width: var(--sb-size);
}

.u-scrollbar-native::-webkit-scrollbar:horizontal {
  height: var(--sb-size);
}

.u-scrollbar-native::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: .5rem;
}

.u-scrollbar-native::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border: .0625rem solid #fff;
  border-radius: .5rem;
}

@supports not selector(::-webkit-scrollbar) {
  .u-scrollbar-native {
    scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
  }
}

.u-posr {
  position: relative;
}

.u-posa {
  position: absolute;
}

.u-fixed {
  position: fixed;
}

.u-sticky {
  position: sticky;
}

.u-top-50-p {
  top: 50%;
}

.u-left-50-p {
  left: 50%;
}

.u-top-0 {
  top: 0;
}

.u-bottom-0 {
  bottom: 0;
}

.u-left-0 {
  left: 0;
}

.u-right-0 {
  right: 0;
}

.u-translate-middle-x {
  transform: translateX(-50%);
}

.u-translate-middle-y {
  transform: translateY(-50%);
}

.u-translate-middle {
  transform: translateX(-50%)translateY(-50%);
}

.u-rounded-full {
  border-radius: 624.938rem;
}

.u-rounded-2 {
  border-radius: 1rem;
}

.u-rounded-3 {
  border-radius: 1.5rem;
}

.u-rounded-4 {
  border-radius: 2rem;
}

.u-rounded-5 {
  border-radius: 2.5rem;
}

.u-rounded-9 {
  border-radius: 4.5rem;
}

.u-rounded-10 {
  border-radius: 5rem;
}

@media (width >= 36em) {
  .u-rounded-sm-9 {
    border-radius: 4.5rem;
  }
}

@media (width >= 48em) {
  .u-rounded-md-10 {
    border-radius: 5rem;
  }
}

@media (width >= 62em) {
  .u-rounded-lg-10 {
    border-radius: 5rem;
  }

  .u-rounded-lg-5 {
    border-radius: 2.5rem;
  }
}

@media (width >= 48em) {
  .u-rounded-md-5 {
    border-radius: 2.5rem;
  }

  .u-rounded-md-4 {
    border-radius: 2rem;
  }
}

.u-shadow {
  box-shadow: 0 .0625rem 2.5rem #40414040;
}

.u-shadow-sm {
  box-shadow: 0 .0625rem 1.25rem #42123d26;
}

.u-shadow-drop {
  filter: drop-shadow(0 .0625rem .5rem #40414040);
}

.u-stack {
  grid: [stack] 1fr / [stack] 1fr;
  display: grid;
}

.u-stack > * {
  grid-area: stack;
}

.u-table-responsive {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

.u-z-index-1 {
  z-index: 1;
}

.u-z-index-2 {
  z-index: 2;
}

.u-z-index-3 {
  z-index: 3;
}

.u-z-index-negative {
  z-index: -1;
}

.u-z-index-10 {
  z-index: 10;
}

.a-user-panel {
  box-shadow: 0 0 1.25rem #40414026;
}

@media (width >= 75em) and (width <= 93.749em) {
  .a-user-panel {
    border-radius: 1.5rem;
  }
}

.c-base-decoration {
  --inset: 0;
  --translate-x: 0;
  --translate-y: 0;
  --depth: 0;
  inset: var(--inset);
  transform: translateX(var(--translate-x)) translateY(var(--translate-y));
  z-index: var(--depth);
}

.c-base-decoration.--depth-under {
  --depth: -1;
}

.c-base-decoration.--position-center-left {
  --inset: 50% auto auto auto;
  --translate-y: -50%;
}

.c-base-decoration.--position-center-left.--translate-center {
  --translate-x: -50%;
}

.c-base-decoration.--position-center-left.--translate-out {
  --translate-x: -100%;
}

.c-base-decoration.--position-center-right {
  --inset: 50% 0 auto auto;
  --translate-y: -50%;
}

.c-base-decoration.--position-center-right.--translate-center {
  --translate-x: 50%;
}

.c-base-decoration.--position-center-right.--translate-out {
  --translate-x: 100%;
}

.c-base-decoration.--position-top-left {
  --inset: 0 auto auto 0;
}

.c-base-decoration.--position-top-left.--translate-center {
  --translate-x: -50%;
  --translate-y: -50%;
}

.c-base-decoration.--position-top-left.--translate-out {
  --translate-x: -100%;
  --translate-y: -100%;
}

.c-base-decoration.--position-top-right {
  --inset: 0 0 auto auto;
}

.c-base-decoration.--position-top-right.--translate-center {
  --translate-x: 50%;
  --translate-y: -50%;
}

.c-base-decoration.--position-top-right.--translate-out {
  --translate-x: 100%;
  --translate-y: -100%;
}

.c-base-decoration.--position-bottom-left {
  --inset: auto auto 0 0;
}

.c-base-decoration.--position-bottom-left.--translate-center {
  --translate-x: -50%;
  --translate-y: 50%;
}

.c-base-decoration.--position-bottom-left.--translate-out {
  --translate-x: -100%;
  --translate-y: 100%;
}

.c-base-decoration.--position-bottom-right {
  --inset: auto 0 0 auto;
}

.c-base-decoration.--position-bottom-right.--translate-center {
  --translate-x: 50%;
  --translate-y: 50%;
}

.c-base-decoration.--position-bottom-right.--translate-out {
  --translate-x: 100%;
  --translate-y: 100%;
}

.base-header {
  --spacing: 0;
  margin-inline: auto;
  margin-bottom: var(--spacing);
  max-width: 58rem;
}

.base-header.--left {
  text-align: left;
  align-items: flex-start;
  margin-inline: 0;
}

.base-header:has( ~ *) {
  --spacing: 3.5rem;
}

@media (width <= 35.999em) {
  .base-header:has( ~ *) {
    --spacing: 2rem;
  }
}

.base-header:has( ~ *).--spacing-none {
  --spacing: 0;
}

.base-header:has( ~ *).--spacing-sm {
  --spacing: 1.5rem;
}

.base-header:has( ~ *).--spacing-md {
  --spacing: 2.5rem;
}

@media (width <= 35.999em) {
  .base-header:has( ~ *).--spacing-md {
    --spacing: 2rem;
  }
}

.base-header__description p {
  margin-bottom: 0;
}

.base-header__toggle {
  --short-display: block;
  --long-display: none;
}

.base-header__toggle.--open {
  --short-display: none;
  --long-display: block;
}

.base-header__toggle-long {
  display: var(--long-display);
}

.base-header__toggle-long .link__icon {
  rotate: 180deg;
}

.base-header__toggle-short {
  display: var(--short-display);
}

.base-section {
  --spacing: var(--section-spacing);
  --background: #fff;
  padding-block: var(--spacing);
  background-color: var(--background);
}

.base-section.--background {
  --background: var(--section-background-color);
}

.base-section.--top-0 {
  padding-top: 0;
}

.base-section.--bottom-0 {
  padding-bottom: 0;
}

@media (width <= 35.999em) {
  .base-section.--bottom-0:has( + .s-separator-wave) {
    padding-bottom: var(--spacing);
  }
}

.base-section.--none {
  --spacing: 0;
}

.btn {
  --direction-offset: .5rem;
}

.btn.--size-xs {
  --direction-offset: .25rem;
}

@media (hover: hover) {
  .btn.--animation-right:hover svg {
    transform: translateX(var(--direction-offset));
    transition-timing-function: cubic-bezier(.68, -.55, .27, 1.55);
  }

  .btn.--animation-left:hover svg {
    transform: translateX(calc(-1 * var(--direction-offset)));
    transition-timing-function: cubic-bezier(.68, -.55, .27, 1.55);
  }

  .btn.--animation-zoom:hover svg {
    transform: scale(1.1);
  }

  .btn.--animation-down:hover svg {
    transform: translateY(var(--direction-offset));
  }
}

.btn.--sm {
  --font-size: .875rem;
  --padding-y: .75rem;
  --padding-x: 1.5rem;
  --min-height: 3rem;
}

.btn.--xs {
  --font-size: .75rem;
  --padding-y: .625rem;
  --padding-x: 1.125rem;
  --min-height: 2.5rem;
  --svg-size: 1rem;
}

.btn.--xxs {
  --font-size: .875rem;
  --padding-y: .5rem;
  --padding-x: .5rem;
  --min-height: 2rem;
}

@media (width >= 36em) {
  .btn.--size-default {
    min-width: 15rem;
  }
}

@media (width <= 35.999em) {
  .btn.--size-default {
    --font-size: .75rem;
    --padding-y: .625rem;
    --padding-x: 1.5rem;
    --min-height: 2.5rem;
    --svg-size: 1rem;
  }
}

.btn.--primary {
  --background: var(--c-conversion-500);
  background-color: var(--background);
  transition: background-color .3s, opacity .3s;
}

@media (hover: hover) {
  .btn.--primary:hover {
    --background: var(--c-conversion-600);
  }
}

.btn.--outline {
  --font-color: var(--c-conversion-500);
  --border-color: var(--c-conversion-500);
  --background: var(--c-white);
  background-color: var(--background);
  border: .0625rem solid var(--border-color);
  transition: color .3s, border-color .3s, opacity .3s, background-color .3s;
}

@media (hover: hover) {
  .btn.--outline:hover {
    --background: var(--c-conversion-100);
  }
}

.btn.--semi-transparent {
  --font-color: var(--c-conversion-500);
  --background: #ffffffb3;
  background-color: var(--background);
  transition: background-color .3s, opacity .3s, box-shadow .3s;
  box-shadow: 0 0 1.25rem #40414026;
}

@media (hover: hover) {
  .btn.--semi-transparent:hover {
    --font-color: var(--c-conversion-500);
    --background: var(--c-white);
    box-shadow: 0 .0625rem 2.5rem #40414040;
  }
}

.btn.--ghost {
  --background: transparent;
  --font-color: var(--c-conversion-500);
  background-color: var(--background);
  transition: color .3s, background-color .3s;
}

@media (hover: hover) {
  .btn.--ghost:hover {
    --background: #f3f3f4;
    --font-color: var(--c-conversion-600);
  }
}

.btn.--link {
  --border-radius: 0;
  --font-size: .875rem;
  --font-weight: 400;
  --font-color: var(--c-primary-900);
  --background: transparent;
  --underline-color: var(--c-primary-900);
  --min-height: 1.25rem;
  background-color: var(--background);
  color: var(--font-color);
  text-transform: none;
  width: fit-content;
  min-width: auto;
  padding: 0;
  transition: color .3s;
}

.btn.--link .btn__title {
  text-decoration: underline;
  -webkit-text-decoration-color: var(--underline-color);
  text-decoration-color: var(--underline-color);
  transition: text-decoration-color .3s;
}

.btn.--link path {
  will-change: fill;
  transition: fill .3s;
}

.btn.--link:hover {
  --font-color: var(--c-conversion-500);
  --underline-color: transparent;
}

.btn.--facebook {
  --background: #3b5998;
  --border-radius: .5rem;
  --background-hover: #3b5998cc;
  background-color: var(--background);
  transition: background-color .3s;
}

.btn.--facebook.--icon-only {
  flex-shrink: 1;
  width: 100%;
}

@media (hover: hover) {
  .btn.--facebook:hover {
    background-color: var(--background-hover);
  }
}

.btn.--google {
  --background: var(--c-white);
  --border-radius: .5rem;
  --background-hover: #0000000d;
  background-color: var(--background);
  border: .0625rem solid #b1afbd;
  transition: background-color .3s;
}

.btn.--google path {
  fill: revert-layer;
}

.btn.--google.--icon-only {
  flex-shrink: 1;
  width: 100%;
}

@media (hover: hover) {
  .btn.--google:hover {
    background-color: var(--background-hover);
  }
}

.btn.--apple {
  --background: #111;
  --border-radius: .5rem;
  --background-hover: #111c;
  background-color: var(--background);
  transition: background-color .3s;
}

.btn.--apple.--icon-only {
  flex-shrink: 1;
  width: 100%;
}

@media (hover: hover) {
  .btn.--apple:hover {
    background-color: var(--background-hover);
  }
}

.btn__icon .icon {
  --size: var(--svg-size);
}

.btn__icon svg {
  transition: transform .3s;
}

.btn__icon path {
  transition: fill .3s;
}

.btn {
  --border-radius: 3.5rem;
  --font-size: 1rem;
  --font-weight: 350;
  --font-color: #fff;
  --font-family: gotham-rounded, Adjusted Georgia;
  --padding-y: .75rem;
  --padding-x: 1.5rem;
  --min-height: 3.5rem;
  --svg-size: 1.25rem;
  font-size: var(--font-size);
  font-family: var(--font-family);
  color: var(--font-color);
  font-weight: var(--font-weight);
  border-radius: var(--border-radius);
  padding-inline: var(--padding-x);
  padding-block: var(--padding-y);
  min-height: var(--min-height);
  touch-action: manipulation;
}

.btn:hover {
  color: var(--font-color);
  text-decoration: none;
}

.btn:focus, .btn:active {
  color: var(--font-color);
  outline: 0;
  text-decoration: none;
}

.btn path {
  fill: var(--font-color);
}

.btn.--icon-before .btn__icon {
  order: -1;
}

.btn.--icon-only {
  width: var(--min-height);
  height: var(--min-height);
  justify-content: center;
  align-items: center;
  min-width: auto;
  min-height: auto;
  padding: 0;
  display: flex;
}

.btn.--icon-only .btn__title {
  display: none;
}

@media (width <= 35.999em) {
  .btn.--full-width-mobile {
    width: 100%;
  }
}

.content-w-aside__content .base-section:not(.--none) {
  --spacing: 3.5rem;
}

.content-w-aside__content .base-section .container {
  padding-inline: 0;
}

.content-w-aside__content .base-section:first-child {
  padding-top: 0;
}

.content-w-aside__content .base-section:last-child {
  padding-bottom: 0;
}

@media (width >= 75em) {
  .content-w-aside__content {
    grid-column: 1 / span 8;
  }
}

.content-w-aside__row {
  gap: 4rem 2rem;
}

@media (width >= 75em) {
  .content-w-aside__row {
    grid-template-columns: repeat(12, 1fr);
    justify-content: center;
    display: grid;
  }
}

@media (width <= 74.999em) {
  .content-w-aside__row {
    flex-direction: column;
    display: flex;
  }
}

.content-w-aside__row > .content-w-aside__content:last-child {
  grid-column: 3 / span 8;
}

.content-w-aside__aside .widget:last-child {
  margin-bottom: 0;
}

@media (width >= 75em) {
  .content-w-aside__aside {
    grid-column: span 4 / -1;
  }
}

.content-w-aside {
  --spacing: var(--section-spacing);
  padding-block: var(--spacing);
}

.content-w-aside.--top-0 {
  padding-top: 0;
}

.content-w-aside.--bottom-0 {
  padding-bottom: 0;
}

.content-w-aside.--none {
  --spacing: 0;
}

.content-w-aside.--reverse .content-w-aside__aside {
  order: -1;
  grid-column: span 3;
}

.content-w-aside.--reverse .content-w-aside__content {
  grid-column: 4 / span 9;
}

.f-category {
  --min-height: 4rem;
  min-height: var(--min-height);
  transition: background-color .3s, color .3s;
}

@media (width <= 35.999em) {
  .f-category {
    --min-height: 3rem;
  }
}

.f-category:has(.f-category__input:checked) {
  background-color: var(--c-conversion-100);
  color: var(--c-conversion-500);
}

.f-checkbox__visual {
  width: var(--size);
  height: var(--size);
  border: .125rem solid var(--color);
  border-radius: var(--radius);
  background-color: #fff;
  transition: background-color .3s;
}

.f-checkbox__visual .f-checkbox__check-svg {
  opacity: 0;
  width: 1rem;
  height: 1rem;
  transition: opacity .3s, transform .3s;
  transform: scale(.2);
}

.f-checkbox__visual:after {
  content: "";
  width: calc(var(--size) / 2);
  height: calc(var(--size) / 2);
  border-radius: calc(var(--size) / 2);
  opacity: 0;
  background-color: #fff;
  transition: opacity .3s, transform .3s;
  display: none;
  position: absolute;
  transform: scale(.2);
}

.f-checkbox__input {
  left: calc(var(--size) / 2);
  top: calc(var(--size) / 2);
  transform: translateX(-50%);
}

.f-checkbox__input:checked ~ .f-checkbox__visual {
  background-color: var(--color);
}

.f-checkbox__input:checked ~ .f-checkbox__visual:after, .f-checkbox__input:checked ~ .f-checkbox__visual .f-checkbox__check-svg {
  opacity: 1;
  transform: scale(1);
}

.f-checkbox__title a {
  color: var(--color);
  font-weight: 700;
}

.f-checkbox {
  --color: var(--c-conversion-500);
  --radius: .25rem;
  --size: 1.5rem;
}

.f-checkbox.--disabled {
  --color: var(--c-primary-300);
}

.f-checkbox.--disabled .f-checkbox__title {
  color: var(--color);
}

.f-checkbox.--radio {
  --radius: calc(var(--size) / 2);
}

.f-checkbox.--radio .f-checkbox__visual:after {
  display: block;
}

.f-checkbox__body {
  margin-top: .3125rem;
}

.f-checkbox-select__header {
  background-color: var(--header-background);
  border: .0625rem solid #ebebeb;
  min-height: 3rem;
}

.f-checkbox-select__clear.--active {
  display: inline-flex;
}

.f-checkbox-select__toggler {
  width: 2rem;
  height: 2rem;
  transition: background-color .3s;
}

.f-checkbox-select__toggler svg {
  transform: scale(var(--toggler-svg-scale));
  width: .875rem;
  height: .875rem;
  transition: scale .3s;
}

.f-checkbox-select__toggler:hover {
  background-color: #ebebeb;
}

.f-checkbox-select__label {
  --end: 0;
  height: 2rem;
}

.f-checkbox-select__label > span {
  transition: left 2s linear;
}

.f-checkbox-select__label:after {
  content: "";
  pointer-events: none;
  background: transparent linear-gradient(270deg, var(--header-background) 0%, #fff0 100%) 0% 0% no-repeat padding-box;
  z-index: 1;
  width: 1rem;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}

.f-checkbox-select__label:hover > span {
  left: var(--end);
}

.f-checkbox-select__body {
  z-index: 5;
  transform: var(--body-transform);
  transform-origin: top;
  pointer-events: var(--body-pointer-events);
  opacity: var(--body-opacity);
  background-color: #fff;
  border: .0625rem solid #abacac;
  width: calc(100% - .0625rem);
  transition: opacity .3s, transform .3s;
  top: calc(100% - .0625rem);
  left: 50%;
}

.f-checkbox-select__body-inner {
  max-height: 10rem;
}

.f-checkbox-select {
  --header-background: #fff;
  --body-opacity: 0;
  --body-pointer-events: none;
  --body-transform: translateX(-50%) scaleY(.25);
  --toggler-svg-scale: 1;
  min-width: 0;
}

.f-checkbox-select.--active {
  --header-background: #bfe9e8;
  --body-opacity: 1;
  --body-pointer-events: auto;
  --body-transform: translateX(-50%) scaleY(1);
  --toggler-svg-scale: -1;
}

.f-date__icon {
  display: var(--icon-display);
}

.f-date__input {
  border-radius: var(--radius);
  cursor: var(--cursor);
}

.f-date {
  --border-color: #abacac;
  --radius: .25rem;
  --icon-display: block;
  --cursor: pointer;
}

.f-date:not(.--native) .f-date__input {
  padding-right: 3rem;
}

.f-date.--native {
  --icon-display: none;
  --cursor: pointer;
}

.f-date.--invalid {
  --border-color: #e21c3d;
}

.f-date__wrap {
  border: .0625rem solid var(--border-color);
  border-radius: var(--radius);
  transition: border-color .3s;
}

.f-error {
  --display: none;
  color: #e21c3d;
  display: var(--display);
  top: calc(100% + .25rem);
  left: .25rem;
}

.f-error.--show {
  --display: flex;
}

.f-error__icon {
  height: 1.375rem;
}

.f-base {
  color: #404140;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  width: 100%;
  min-height: 2.875rem;
  padding: .625rem 1rem;
  font-weight: 350;
  display: block;
}

.f-base:focus {
  border-color: none;
  outline: none;
}

.f-base::placeholder {
  opacity: 1;
  color: #abacac;
  font-weight: 325;
}

textarea.f-base {
  resize: none;
  min-height: 7.1875rem;
}

.i-selected-file {
  background-color: var(--c-conversion-100);
  cursor: pointer;
  border-radius: .5rem;
  min-height: 1.5rem;
}

.i-selected-file__remove {
  justify-content: center;
  align-items: center;
  width: .75rem;
  height: .75rem;
  display: flex;
}

.i-selected-file__remove svg {
  width: 100%;
  height: 100%;
}

.f-file {
  --files-display: flex;
}

.f-file.--empty {
  --files-display: none;
}

.f-file__files {
  display: var(--files-display, flex);
}

.f-label {
  line-height: 1rem;
}

.f-label.--required:after {
  content: "*";
  padding-left: .25rem;
}

.f-multicheckbox {
  --rows: 9.5rem;
  --transform: scale(1);
}

.f-multicheckbox.--open {
  --rows: 1fr;
  --transform: scale(-1);
}

.f-multicheckbox.--no-more {
  --rows: 1fr;
}

.f-multicheckbox .f-label {
  line-height: 1.5rem;
}

.f-multicheckbox__body {
  grid-template-rows: var(--rows);
  transition: grid-template-rows .5s;
}

.f-multicheckbox__body-inner {
  overflow: clip;
}

.f-multicheckbox__more.btn {
  justify-content: flex-start;
  padding-bottom: .5rem;
}

.f-multicheckbox__more.btn .icon {
  transform: var(--transform);
}

.f-range-slider {
  --height: .25rem;
  --thumb-size: 1rem;
  --thumb-offset: .5rem;
  --color: var(--c-primary-900);
  --background: var(--c-conversion-300);
  --input-width: 10rem;
  --gradient-from: calc(var(--background-from)  - var(--thumb-offset));
  --gradient-to: calc(var(--background-to)  + var(--thumb-offset));
}

.f-range-slider .f-label {
  line-height: 1.5rem;
}

.f-range-slider__control {
  border: .0625rem solid var(--c-primary-500);
  width: min(var(--input-width), var(--input-width));
  border-radius: 2.3125rem;
  min-height: 3rem;
  padding: .75rem 1rem;
  font-weight: 350;
}

@media (width <= 35.999em) {
  .f-range-slider__control {
    width: 100%;
  }
}

.f-range-slider__number {
  -webkit-appearance: textfield;
  appearance: textfield;
  border: none;
  margin-right: .25rem;
  padding: 0;
  font-weight: 350;
}

.f-range-slider__number:focus {
  border: none;
  outline: none;
}

.f-range-slider__number::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.f-range-slider__number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

@media (width <= 35.999em) {
  .f-range-slider__number {
    width: 100%;
  }
}

.f-range-slider__ranges {
  height: var(--height);
}

.f-range-slider__range-wrap:before {
  content: "";
  height: var(--height);
  background-color: var(--background);
  z-index: -1;
  border-radius: var(--height);
  position: absolute;
  inset: 0;
}

@media (width <= 35.999em) {
  .f-range-slider__inputs {
    flex-direction: column;
  }
}

.f-range-slider__range {
  -webkit-appearance: none;
  appearance: none;
  pointer-events: none;
  inset: 0 var(--thumb-offset);
  height: var(--height);
  width: calc(100% - var(--thumb-size));
  background: linear-gradient(90deg, transparent 0%, transparent var(--gradient-from), var(--color) var(--gradient-from), var(--color) var(--gradient-to), transparent var(--gradient-to), transparent 100%);
  outline: none;
  position: absolute;
}

.f-range-slider__range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  pointer-events: all;
  width: var(--thumb-size);
  height: var(--thumb-size);
  background-color: var(--c-conversion-300);
  cursor: pointer;
  transform: translateX(calc(var(--thumb-offset) * var(--side)));
  z-index: 2;
  border-radius: 50%;
  transition: background-color .2s;
  position: relative;
}

.f-range-slider__range::-moz-range-thumb {
  -webkit-appearance: none;
  appearance: none;
  pointer-events: all;
  width: var(--thumb-size);
  height: var(--thumb-size);
  background-color: var(--c-conversion-300);
  cursor: pointer;
  transform: translateX(calc(var(--thumb-offset) * var(--side)));
  z-index: 2;
  border-radius: 50%;
  transition: background-color .2s;
  position: relative;
}

.f-range-slider__range.--from {
  --side: -1;
}

.f-range-slider__range.--to {
  --side: 1;
}

.f-single-select__select.slim-select.ss-main {
  padding-inline: var(--padding-inline);
  box-shadow: none;
  transition: border .3s, border-radius .3s;
}

.f-single-select__select.slim-select.ss-main.ss-disabled {
  --ss-font-color: #d3d4d4;
}

.f-single-select__select.slim-select.ss-values.ss-single {
  margin: 0;
}

.f-single-select__select.slim-select.ss-content {
  position: absolute;
}

.f-single-select__select.slim-select .ss-list .ss-option {
  padding-inline: 1rem;
}

.f-single-select__select.slim-select .ss-arrow {
  display: none;
}

.f-single-select__select.slim-select .ss-search input {
  --ss-input-border-color: var(--c-primary-500);
  border: .0625rem solid var(--ss-input-border-color);
  border-radius: var(--radius);
  padding: .625rem 1rem;
  font-weight: 350;
  transition: border-color .3s;
}

.f-single-select__select.slim-select .ss-search input:focus {
  --ss-input-border-color: var(--c-conversion-500);
  box-shadow: none;
}

.f-single-select__select.slim-select .ss-search input::placeholder {
  color: #abacac;
  font-weight: 325;
}

.f-single-select, .f-single-select__select.slim-select {
  --radius: 1.5rem;
  --height: 3rem;
  --padding-inline: 1rem;
  --padding-block: .75rem;
  --border-color: var(--c-primary-500);
  --ss-primary-color: var(--c-conversion-300);
  --ss-bg-color: #fff;
  --ss-font-color: var(--c-primary-900);
  --ss-font-placeholder-color: #abacac;
  --ss-disabled-color: var(--c-primary-300);
  --ss-border-color: var(--border-color);
  --ss-highlight-color: #fffb8c;
  --ss-success-color: #00b755;
  --ss-error-color: #dc3545;
  --ss-main-height: var(--height);
  --ss-content-height: 18.75rem;
  --ss-spacing-l: .4375rem;
  --ss-spacing-m: .75rem;
  --ss-spacing-s: 0;
  --ss-animation-timing: .2s;
  --ss-border-radius: var(--radius);
  --arrow-scale: 1;
  font-size: 1rem;
  font-weight: 350;
  line-height: 1.5rem;
}

.f-single-select:has(.ss-open-above, .ss-open-below), .f-single-select__select.slim-select:has(.ss-open-above, .ss-open-below) {
  --arrow-scale: -1;
}

.f-single-select.--native .f-single-select__wrap, .f-single-select__select.slim-select.--native .f-single-select__wrap {
  grid-template-areas: "select";
  align-items: center;
  display: grid;
}

.f-single-select.--native .f-single-select__select, .f-single-select__select.slim-select.--native .f-single-select__select {
  color: #404140;
  border-radius: var(--radius);
  min-height: var(--height);
  border: .0625rem solid var(--border-color);
  padding-block: var(--padding-block);
  padding-inline: var(--padding-inline);
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  outline: none;
  grid-area: select;
  width: 100%;
  margin: 0;
  padding-right: 2.5rem;
  font-weight: 350;
  transition: border .3s;
}

.f-single-select.--native .f-single-select__select:focus, .f-single-select__select.slim-select.--native .f-single-select__select:focus, .f-single-select.ss-open-below, .f-single-select__select.slim-select.ss-open-below {
  --border-color: var(--c-conversion-500);
}

.f-single-select.ss-open-below:after, .f-single-select__select.slim-select.ss-open-below:after {
  scale: -1;
}

.f-single-select.--invalid, .f-single-select__select.slim-select.--invalid, .f-single-select.--invalid .ss-main, .f-single-select__select.slim-select.--invalid .ss-main {
  --border-color: #e21c3d;
}

.f-single-select .ss-single, .f-single-select__select.slim-select .ss-single {
  padding-right: 1.5rem;
}

.f-single-select__arrow {
  right: 1rem;
}

.f-single-select__arrow svg {
  transform: scale(var(--arrow-scale));
  transition: transform .3s;
}

.f-single-select-inline__select {
  --ss-primary-color: var(--c-conversion-300);
  --ss-bg-color: transparent;
  --ss-font-color: var(--c-primary-900);
  --ss-border-color: transparent;
  --ss-success-color: #48a463;
  --ss-error-color: #e21c3d;
  --ss-main-height: 1.5rem;
  --ss-content-height: auto;
  --ss-spacing-l: 0;
  --ss-spacing-m: .25rem;
  --ss-spacing-s: .125rem;
  --ss-animation-timing: .3s;
  --ss-border-radius: .75rem;
  --text-decoration-color: var(--c-primary-900);
  --selected-text-color: var(--c-primary-900);
  --option-text-color: var(--c-primary-900);
  --option-text-decoration-color: transparent;
  border-radius: .5rem;
  font-size: 1rem;
}

.f-single-select-inline__select:hover {
  --selected-text-color: var(--c-conversion-500);
  --text-decoration-color: transparent;
}

.f-single-select-inline__select .ss-single, .f-single-select-inline__select .ss-option {
  color: var(--selected-text-color);
  text-decoration: underline;
  -webkit-text-decoration-color: var(--text-decoration-color);
  text-decoration-color: var(--text-decoration-color);
  will-change: text-decoration-color, color;
  transition: text-decoration-color .3s, color .3s;
}

.f-single-select-inline__select.ss-content {
  box-shadow: none;
  pointer-events: none;
  border-top: 0;
  border-radius: .75rem;
  min-width: 8.875rem;
  font-size: .875rem;
  line-height: 1rem;
  transform: translateY(.5rem) !important;
}

.f-single-select-inline__select.ss-content .ss-list {
  pointer-events: none;
  flex-direction: column;
  gap: 1rem;
  padding: .5rem;
  display: flex;
}

.f-single-select-inline__select.ss-content .ss-list .ss-option {
  -webkit-text-decoration-color: var(--option-text-decoration-color);
  text-decoration-color: var(--option-text-decoration-color);
  font-weight: 350;
}

.f-single-select-inline__select.ss-content .ss-list .ss-option:hover {
  --option-text-decoration-color: transparent;
  color: var(--c-conversion-500);
  background-color: #0000;
}

.f-single-select-inline__select.ss-open-above, .f-single-select-inline__select.ss-open-below {
  --ss-bg-color: #fff;
}

.f-single-select-inline__select.ss-open-above.ss-content .ss-list, .f-single-select-inline__select.ss-open-below.ss-content .ss-list {
  pointer-events: auto;
}

.f-single-select-inline__select.ss-open-above.ss-content {
  transform: translateY(-1.375rem) !important;
}

.f-single-select-inline__select.ss-main {
  background-color: #0000;
  border: none;
  border-radius: 0;
  outline: none;
  width: auto;
  padding: 0;
  font-weight: 400;
}

.f-single-select-inline__select.ss-main:after {
  content: "";
  pointer-events: none;
  background-image: url("ss-inline-arrow.89bc4778.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 1.5rem;
  margin-left: .25rem;
  transition: scale .3s;
  display: block;
  scale: 1;
}

.f-single-select-inline__select.ss-main:focus {
  box-shadow: none;
}

.f-single-select-inline__select.ss-main.ss-open-above, .f-single-select-inline__select.ss-main.ss-open-below {
  border-color: var(--ss-border-color);
  border-bottom-color: #0000;
}

.f-single-select-inline__select.ss-main .ss-arrow {
  display: none;
}

.f-switch {
  --width: 3rem;
  --height: 1.5rem;
  --offset: .25rem;
  --background: #7fd4d2;
  --background-checked: #00a9a6;
  --transition-timing: .2s;
  --border-radius: calc(var(--height) / 2);
  --handle-size: calc(var(--height)  - var(--offset) * 2);
  --handle-radius: calc(var(--handle-size) / 2);
  --handle-background: #fff;
  --handle-translate-x: var(--offset);
  --handle-translate-x-checked: calc(var(--width)  - var(--handle-size)  - var(--offset));
}

.f-switch.--disabled {
  --background: #abacac;
  --background-checked: #abacac;
  --handle-background: #ebebeb;
}

.f-switch__visual {
  width: var(--width);
  height: var(--height);
  border-radius: var(--border-radius);
  background-color: var(--background);
  transition: background-color var(--transition-timing) ease;
}

.f-switch__visual:after {
  content: "";
  transform: translateY(-50%) translateX(var(--handle-translate-x));
  width: var(--handle-size);
  height: var(--handle-size);
  border-radius: var(--handle-radius);
  background-color: var(--handle-background);
  z-index: 2;
  transition: transform var(--transition-timing) ease;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
}

.f-switch__input {
  top: calc(var(--height) / 2);
}

.f-switch__input:checked ~ .f-switch__visual {
  --background: var(--background-checked);
  --handle-translate-x: var(--handle-translate-x-checked);
}

.f-text__input {
  border-radius: var(--radius);
}

.f-text {
  --border-color: var(--c-primary-500);
  --radius: 2.3125rem;
}

.f-text.--active {
  --border-color: var(--c-conversion-500);
}

.f-text.--invalid {
  --border-color: #e21c3d;
}

.f-text.--icon-before .f-text__input {
  padding-left: 3rem;
}

.f-text.--icon-after .f-text__input {
  padding-right: 3rem;
}

.f-text__wrap {
  border: .0625rem solid var(--border-color);
  border-radius: var(--radius);
  transition: border-color .3s;
}

.f-text__toggle {
  --on-opacity: 0;
  --off-opacity: 1;
  --size: 1.5rem;
  width: var(--size);
  height: var(--size);
  right: 1rem;
}

.f-text__toggle.--active {
  --on-opacity: 1;
  --off-opacity: 0;
}

.f-text__toggle-icon {
  width: var(--size);
  height: var(--size);
  grid-row-start: 1;
  grid-column-start: 1;
}

.f-text__toggle-icon.--on {
  opacity: var(--on-opacity);
}

.f-text__toggle-icon.--off {
  opacity: var(--off-opacity);
}

.f-textarea {
  --border-color: var(--c-primary-500);
  --radius: 1.5rem;
}

.f-textarea.--active {
  --border-color: var(--c-conversion-500);
}

.f-textarea.--invalid {
  --border-color: #e21c3d;
}

.f-textarea__wrap {
  border: .0625rem solid var(--border-color);
  border-radius: var(--radius);
}

.f-textarea__input {
  border-radius: var(--radius);
}

.f-newsletter {
  --border-radius: 2.5rem;
  border-radius: var(--border-radius);
  background-color: var(--c-primary-900);
}

@media (width >= 48em) {
  .f-newsletter {
    --border-radius: 5rem;
  }
}

@media (width >= 75em) {
  .f-newsletter__grid {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
  }
}

.f-newsletter__header {
  gap: 1rem;
}

@media (width >= 36em) {
  .f-newsletter__header .base-heading {
    text-wrap: initial;
  }
}

.f-newsletter__inputs-col {
  --inputs-radius: 2.5rem;
  background-color: var(--c-conversion-100);
  border-radius: var(--inputs-radius);
}

@media (width <= 35.999em) {
  .f-newsletter__inputs-col {
    --inputs-radius: 1.5rem;
    flex-direction: column;
  }
}

@media (width <= 47.999em) {
  .f-newsletter__submit {
    width: 100%;
  }
}

.f-newsletter__terms .f-checkbox__title {
  font-weight: 325;
}

.f-newsletter__terms .f-checkbox__title a {
  --color: #404140;
}

.f-newsletter__inputs {
  gap: 1rem;
}

.f-newsletter__inputs .f-text__input {
  min-height: 2.875rem;
  padding-block: .5rem;
}

@media (width >= 48em) {
  .f-newsletter__inputs {
    gap: 0;
  }

  .f-newsletter__inputs .f-text {
    margin-right: -3.125rem;
  }

  .f-newsletter__inputs .f-text .f-text__input {
    padding-right: 3.75rem;
  }
}

.g-anchors__hr {
  border: 0;
  height: .0625rem;
}

@media (width <= 35.999em) {
  .g-breadcrumbs__list-links .i-breadcrumb:not(:last-child) {
    display: none;
  }
}

.g-buttons {
  --spacing: 3.5rem;
  margin-top: var(--spacing);
}

.g-buttons.--spacing-xs {
  --spacing: .5rem;
}

.g-buttons.--spacing-lg {
  --spacing: 2.5rem;
}

.g-buttons.--spacing-xl {
  --spacing: 4rem;
}

.g-buttons.--spacing-none {
  --spacing: 0;
}

.g-buttons.--hidden {
  display: none;
}

.g-contact-pill {
  min-height: 9.5rem;
}

.g-demands table {
  border-collapse: collapse;
}

.g-demands tr {
  background-color: var(--c-primary-100);
}

.g-demands tr td:first-child {
  border-top-left-radius: 2rem;
  border-bottom-left-radius: 2rem;
}

.g-demands tr td:last-child {
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
}

.g-demands tr:nth-child(2n) {
  background-color: var(--c-white);
}

.g-demands__service .link__title {
  flex-shrink: 0;
}

@media (width >= 75em) {
  .g-events {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
  }
}

.g-hero-slider__button.btn {
  --offset: 50%;
  min-width: auto;
}

.g-hero-slider__button.btn.--prev {
  transform: translateX(calc(-1 * var(--offset)));
}

.g-hero-slider__button.btn.--next {
  transform: translateX(var(--offset));
}

@media (width <= 35.999em) {
  .g-hero-slider__button.btn {
    --offset: .5rem;
  }
}

.g-hero-slider__button.btn.swiper-button-disabled {
  opacity: 0;
  pointer-events: none;
}

.g-hero-slider__pagination.swiper-pagination {
  --swiper-pagination-bullet-horizontal-gap: 0;
  --swiper-pagination-bullet-border-radius: 0;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  min-height: .5rem;
  margin-top: 0;
  display: flex;
  position: relative;
  transform: translateY(-1rem);
}

.g-hero-slider__pagination.swiper-pagination .swiper-pagination-bullet {
  transform-origin: center;
  border-radius: 1rem;
  transition: width .3s;
}

.g-hero-slider__pagination.swiper-pagination .swiper-pagination-bullet-active {
  --swiper-pagination-bullet-width: 2rem;
}

.g-hero-slider.swiper.swiper-initialized {
  overflow-clip-margin: 2.5rem;
  overflow: clip;
}

.g-hero-slider .swiper-slide {
  backface-visibility: hidden;
  flex-shrink: 0;
  padding: 2.5rem;
  transform: translate3d(0, 0, 0);
  width: 100% !important;
}

.g-hero-slider .swiper-wrapper {
  height: auto;
}

.g-jobs {
  width: 100%;
  max-width: 58rem;
  margin: 0 auto;
}

.g-jobs.--card {
  max-width: unset;
  flex-flow: wrap;
  justify-content: center;
  gap: 2rem;
  display: flex;
}

@media (width <= 359px) {
  .g-partner {
    margin-inline: auto;
  }

  .g-partner .col-6 {
    width: 100%;
  }
}

.g-payment .i-payment:nth-child(2n) {
  background-color: var(--c-white);
}

.g-persons__cell.--inline {
  order: -1;
  width: 100%;
  max-width: 100%;
}

@media (width <= 61.999em) {
  .g-persons__cell.--inline {
    max-width: 33rem;
  }
}

@media (width <= 47.999em) {
  .g-persons__cell.--inline {
    max-width: 32rem;
  }
}

@media (width <= 93.749em) {
  .g-persons__cell {
    max-width: 30rem;
  }
}

.g-posts {
  grid-template-columns: repeat(3, 1fr);
  gap: 3.5rem 2rem;
  display: grid;
}

@media (width <= 61.999em) {
  .g-posts {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (width <= 47.999em) {
  .g-posts {
    flex-direction: column;
    display: flex;
  }
}

.g-posts.--compact {
  grid-template-columns: repeat(2, 1fr);
}

@media (width <= 47.999em) {
  .g-posts.--compact {
    flex-direction: column;
    display: flex;
  }
}

.g-posts.--inline {
  flex-direction: column;
  display: flex;
}

.g-pricetable {
  overflow-x: auto;
}

.g-pricetable__row:nth-child(odd) {
  background-color: #bfe9e8;
}

.g-pricetable__cell .icon {
  margin: 0 auto;
}

.g-references-slider__button {
  --offset: calc(50% + .5rem);
}

.g-references-slider__button.--prev {
  transform: translateX(calc(-1 * var(--offset)));
}

.g-references-slider__button.--next {
  transform: translateX(var(--offset));
}

@media (width <= 35.999em) {
  .g-references-slider__button {
    --offset: 1rem;
  }
}

.g-references-slider__button.swiper-button-disabled {
  opacity: 0;
  pointer-events: none;
}

.g-references-slider.swiper {
  overflow: initial;
  align-items: center;
  height: 100%;
  display: flex;
}

.g-references-slider .swiper-wrapper {
  display: flex;
}

.g-references-slider .swiper-slide {
  box-sizing: border-box;
  backface-visibility: hidden;
  opacity: 0;
  pointer-events: none;
  height: auto;
  transition: opacity .3s;
  transform: translate3d(0, 0, 0);
}

.g-references-slider .swiper-slide-visible {
  opacity: 1;
  pointer-events: auto;
}

.g-references-slider__buttons:before {
  content: "";
  z-index: 1;
  pointer-events: none;
  background-color: #fff;
  width: 26rem;
  height: .0625rem;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(calc(5rem - 100%), -50%);
}

@media (width >= 48em) and (width <= 74.999em) {
  .g-signpost.--cols-4 {
    max-width: 44.875rem;
    margin-inline: auto;
  }
}

@media (width <= 47.999em) {
  .g-signpost.--cols-4 {
    max-width: 22.5rem;
    margin-inline: auto;
  }

  .g-signpost.--cols-3 {
    max-width: 28rem;
    margin-inline: auto;
  }
}

.g-steps {
  row-gap: 4.5rem;
}

.g-steps .g-steps__cell:first-child .i-step.--column:before {
  width: calc(50% + 1rem);
  left: 50%;
  transform: translateX(0);
}

.g-steps .g-steps__cell:last-child .i-step.--column:before {
  width: calc(50% + 1rem);
  left: -1rem;
  transform: translateX(0);
}

.g-steps .g-steps__cell:last-child .i-step__inner.--column:before, .g-steps .g-steps__cell:last-child .i-step__inner.--inline:before {
  display: none;
}

.g-steps .g-steps__cell:last-child .i-step.--inline:before {
  height: 50%;
}

.g-testimonials__button {
  --offset: 50%;
  pointer-events: auto;
}

.g-testimonials__button.--prev {
  transform: translateX(calc(-1 * var(--offset)));
}

.g-testimonials__button.--next {
  transform: translateX(var(--offset));
}

@media (width <= 35.999em) {
  .g-testimonials__button {
    --offset: .5rem;
  }
}

.g-testimonials__pagination.swiper-pagination {
  --swiper-pagination-bullet-horizontal-gap: 0;
  --swiper-pagination-bullet-border-radius: 0;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  min-height: .5rem;
  margin-top: .5rem;
  display: flex;
  position: static;
}

.g-testimonials__pagination.swiper-pagination .swiper-pagination-bullet {
  transform-origin: center;
  transition: width .3s;
}

.g-testimonials__pagination.swiper-pagination .swiper-pagination-bullet-active {
  --swiper-pagination-bullet-width: 2rem;
}

.g-testimonials .swiper-slide {
  backface-visibility: hidden;
  flex-shrink: 0;
  width: fit-content;
  transform: translate3d(0, 0, 0);
}

.g-testimonials .swiper-wrapper {
  align-items: center;
}

.g-testimonials__buttons {
  pointer-events: none;
}

@media (width <= 61.999em) {
  .g-usps {
    flex-wrap: wrap;
  }
}

.g-usps__cell {
  --width: 10.9375rem;
  flex-basis: var(--width);
}

@media (width <= 61.999em) {
  .g-usps__cell {
    --width: 8.75rem;
  }
}

@media (width >= 48em) and (width <= 74.999em) {
  .g-widgets {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2rem;
    display: grid;
  }
}

@media (width <= 47.999em) {
  .g-widgets {
    max-width: 20.5rem;
    margin-inline: auto;
  }
}

.icon {
  --size: 1.25rem;
  color: #404140;
  flex: 0 0 var(--size);
  max-width: var(--size);
  height: var(--size);
}

.icon svg {
  width: 100%;
  height: 100%;
}

.icon.--colored {
  color: var(--color);
}

.icon.--colored path {
  fill: var(--color);
  transition: fill .3s;
}

.icon.--primary {
  --color: #00a9a6;
}

.icon.--conversion {
  --color: var(--c-conversion-500);
}

.icon.--white {
  --color: #fff;
}

.icon.--black {
  --color: #404140;
}

.icon.--danger {
  --color: #e21c3d;
}

.icon.--none {
  --color: none;
}

.icon.--4xl {
  --size: 7rem;
}

.icon.--xxxl {
  --size: 3rem;
}

.icon.--xxl {
  --size: 2.5rem;
}

.icon.--xl {
  --size: 2rem;
}

.icon.--lg {
  --size: 1.5rem;
}

.icon.--md {
  --size: 1.25rem;
}

.icon.--sm {
  --size: 1rem;
}

.icon.--xs {
  --size: .625rem;
}

.img {
  --placeholder-color: var(--c-conversion-100);
}

.img.--ratio-4-3 {
  aspect-ratio: 4 / 3;
}

.img.--ratio-16-9 {
  aspect-ratio: 16 / 9;
}

.img.--ratio-3-2 {
  aspect-ratio: 3 / 2;
}

.img.--ratio-1-1 {
  aspect-ratio: 1;
}

.img.--ratio-3-4 {
  aspect-ratio: 3 / 4;
}

.img.--contain {
  width: 100%;
  height: 100%;
}

.img.--contain img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.img.--contain picture, .img.--cover {
  width: 100%;
  height: 100%;
}

.img.--cover img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.img.--cover picture {
  width: 100%;
  height: 100%;
}

.img.--placeholder {
  --opacity: .3;
}

.img.--placeholder:has(img.loaded) {
  --opacity: 0;
}

.img.--placeholder:before {
  content: "";
  background-color: var(--placeholder-color);
  z-index: 0;
  opacity: var(--opacity);
  width: 100%;
  height: 100%;
  transition: opacity .3s;
  display: block;
  position: absolute;
  inset: 0;
}

.i-alert {
  --background: #fff;
  --border-color: #ebebeb;
  --color: #404140;
  --opacity: 0;
  --translate: -2rem;
  --icon-background: #fff;
  background-color: var(--background);
  color: var(--color);
  opacity: var(--opacity);
  border-color: var(--border-color);
  transform: translateY(var(--translate));
  pointer-events: none;
  border-style: solid;
  border-width: .0625rem;
  border-radius: .25rem;
  transition: opacity .3s, transform .3s;
}

.i-alert.--show {
  --opacity: 1;
  --translate: 0;
  pointer-events: auto;
}

.i-alert.--success {
  --border-color: #48a463;
  --background: #e7f4e4;
  --color: #48a463;
  --icon-background: #48a463;
}

.i-alert.--danger {
  --border-color: #e21c3d;
  --background: #f9dede;
  --color: #e21c3d;
  --icon-background: #e21c3d;
}

.i-alert.--warning {
  --border-color: #fdb81e;
  --background: #fff1d2;
  --color: #fdb81e;
  --icon-background: #fdb81e;
}

.i-alert.--primary {
  --border-color: #00a9a6;
  --background: #00a9a6;
  --color: #fff;
  --icon-background: #00a9a6;
}

.i-alert__icon, .i-alert__close {
  background-color: var(--icon-background);
}

.i-author__description p {
  margin-bottom: 0;
}

.i-author.--compact {
  align-items: center;
}

@media (width <= 35.999em) {
  .i-author.--compact {
    flex-direction: column;
    align-items: start;
  }
}

.i-author.--compact .i-author__description {
  display: none;
}

@media (width <= 35.999em) {
  .i-author {
    flex-direction: column;
    align-items: start;
  }
}

.i-author__image {
  --size: 4rem;
  width: var(--size);
  height: var(--size);
  max-width: var(--size);
}

.i-before-after__slider {
  --divider-color: var(--c-primary-300);
}

.i-before-after__slider:focus {
  outline: none;
  box-shadow: 0 .0625rem 1.25rem #42123d26;
}

.i-breadcrumb__divider {
  width: 1.5rem;
  height: 1.5rem;
}

.i-breadcrumb__divider:before {
  content: "";
  background-color: var(--c-primary-300);
  border-radius: .125rem;
  width: .125rem;
  height: calc(100% + .125rem);
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.i-breadcrumb.--homepage .i-breadcrumb__link {
  gap: 0;
}

.i-breadcrumb.--homepage .i-breadcrumb__divider {
  display: none;
}

.i-check__icon {
  --size: 1.5rem;
  max-width: var(--size);
  flex: 0 0 var(--size);
  height: var(--size);
  border: .125rem solid var(--c-conversion-500);
}

.i-clinic__opening-hour {
  grid-column: span 2;
  grid-template-columns: subgrid;
}

.i-clinic__opening-hours-list {
  max-width: 17.8125rem;
}

.i-clinic-detail__contact .i-social {
  --size: 1.5rem;
  background-color: #0000;
}

.i-contact {
  --logo-width: 14.0625rem;
  --logo-height: 4.5rem;
}

.i-contact__logo {
  height: var(--logo-height);
  max-width: var(--logo-width);
  margin-inline: auto;
}

.i-contact__logo img {
  max-width: 100%;
}

.i-contact__separator {
  height: .0625rem;
}

.i-contact__info-label {
  width: 8.75rem;
}

.i-contact__infos-table-wrapper {
  width: min(100%, 19rem);
}

.i-contact-pill__icon {
  --size: 4.5rem;
  --svg-size: 2.5rem;
  max-width: var(--size);
  height: var(--size);
  flex: 0 0 var(--size);
}

.i-contact-pill__icon svg {
  width: var(--svg-size);
  height: var(--svg-size);
}

@media (width <= 35.999em) {
  .i-contact-pill__icon {
    --size: 3rem;
    --svg-size: 1.5rem;
  }
}

.i-document {
  border-radius: .5rem;
}

@media (width <= 47.999em) {
  .i-event {
    flex-direction: column;
    max-width: 20rem;
    margin-inline: auto;
  }
}

@media (width >= 48em) {
  .i-event__image-link {
    width: 17.5rem;
    height: 13.125rem;
  }
}

.i-faq__body {
  grid-template-rows: var(--rows);
  border-radius: 0 0 var(--border-radius) var(--border-radius);
  background-color: #bfe9e8;
  transition: grid-template-rows .5s;
}

.i-faq__body-inner:before {
  content: "";
  background-color: #7fd4d2;
  width: 100%;
  height: .0625rem;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.i-faq {
  --border-radius: .375rem;
  --rows: 0fr;
  --svg-scale: 1;
  border-radius: var(--border-radius);
  border: .0625rem solid #7fd4d2;
}

.i-faq.--open {
  --rows: 1fr;
  --svg-scale: -1;
}

.i-faq__toggler svg {
  transform: scale(var(--svg-scale));
  transition: transform .3s;
}

.i-hero-banner__body {
  max-width: 36.75rem;
}

.i-hero-image {
  --img-size: 18.375rem;
  border: .5rem solid #fff;
  margin-bottom: 3.5rem;
}

.i-hero-image img {
  aspect-ratio: 1 / .333;
  min-height: var(--img-size);
  max-height: var(--img-size);
}

@media (width <= 47.999em) {
  .i-hero-image {
    --img-size: 14rem;
  }

  .i-hero-image img {
    min-height: var(--img-size);
    max-height: var(--img-size);
  }
}

@media (width <= 35.999em) {
  .i-hero-image {
    margin-bottom: 2rem;
  }
}

.i-hero-slide__body {
  --radius: 2.5rem;
  border-radius: var(--radius);
  backdrop-filter: blur(.25rem);
  left: var(--offset);
  max-width: 35.5rem;
}

.i-hero-slide__body:before {
  content: "";
  z-index: -1;
  opacity: .6;
  border-radius: var(--radius);
  background-color: #fff;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media (width <= 61.999em) {
  .i-hero-slide__body {
    --radius: .5rem;
    gap: 1rem;
    width: 100%;
    max-width: 100%;
    position: static;
    left: auto;
    right: auto;
    transform: translate3d(0, 0, 0);
  }
}

@media (width <= 35.999em) {
  .i-hero-slide__body {
    padding: 1rem;
  }
}

.i-hero-slide {
  --offset: 4rem;
  overflow: hidden;
}

@media (width <= 61.999em) {
  .i-hero-slide {
    align-items: flex-end;
    width: 100%;
    min-height: 27rem;
    padding: 0 1.5rem 1.5rem;
    display: flex;
  }
}

@media (width <= 35.999em) {
  .i-hero-slide {
    padding: 1rem;
  }
}

.i-hero-slide__image {
  border: .5rem solid #fff;
}

@media (width <= 61.999em) {
  .i-hero-slide__image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

@media (width <= 93.749em) {
  .i-hero-slide__heading.h1 {
    font-size: 3rem;
  }
}

@media (width <= 35.999em) {
  .i-job-empty-box__btn {
    width: 100%;
  }
}

.i-job-header {
  margin-bottom: 3.5rem;
}

@media (width <= 35.999em) {
  .i-job-header {
    margin-bottom: 2rem;
  }
}

.i-job-header__header:before {
  content: "";
  background-color: #d3d4d4;
  width: 100%;
  height: .0625rem;
  position: absolute;
  bottom: -1.5rem;
  left: 0;
  transform: translate(0, 50%);
}

.i-job-header__info {
  row-gap: .5rem;
}

@media (width >= 36em) {
  .i-job-header__info {
    flex-direction: row;
    justify-content: space-around;
  }
}

.i-job-header__tag.i-tag.--md {
  --min-height: 1.5rem;
  --font-size: 1rem;
  min-height: var(--min-height);
}

.i-job-header__title {
  max-width: 33rem;
}

.i-job-header__btn.btn {
  min-width: auto;
}

@media (width >= 48em) {
  .i-job-header__btn.btn {
    min-width: 20.4375rem;
  }
}

.i-job__image {
  width: var(--image-size);
  height: var(--image-size);
  border-radius: 1rem;
  grid-area: image;
}

.i-job__info {
  row-gap: .5rem;
}

@media (width >= 36em) {
  .i-job__info {
    flex-direction: row;
  }
}

.i-job {
  --image-size: 9rem;
  grid-template-columns: var(--image-size) 1fr 1fr;
  grid-template-areas: "image header header"
                       "image tags tags"
                       "image footer footer";
  display: grid;
}

@media (width <= 47.999em) {
  .i-job {
    grid-template-areas: "image header header"
                         "image tags tags"
                         "footer footer footer";
    column-gap: 1rem;
  }
}

@media (width <= 35.999em) {
  .i-job {
    flex-direction: column;
    grid-template-areas: "image header header"
                         "tags tags tags"
                         "footer footer footer";
    display: flex;
  }
}

.i-job__tags {
  grid-area: tags;
}

.i-job__footer {
  grid-area: footer;
}

.i-job__header {
  grid-area: header;
}

.i-media .img {
  aspect-ratio: 1 / .662;
}

.i-media.--video-only .img {
  aspect-ratio: 1 / .567;
}

.i-menu__submenu-button {
  --size: 1.5rem;
  width: var(--size);
  height: var(--size);
  rotate: var(--submenu-button-rotate);
}

@media (width <= 74.999em) {
  .i-menu__submenu-button {
    --size: 2.5rem;
    scale: var(--submenu-button-scale);
    transition: scale .3s;
    top: 0;
    right: 1rem;
  }

  .i-menu__submenu-button:hover svg {
    transform: translateY(.25rem);
  }
}

.i-menu__submenu-button svg {
  transition: transform .3s;
}

.i-menu {
  --submenu-display: none;
  --submenu-button-scale: 1;
  --submenu-button-rotate: 0deg;
  --padding-left: 1rem;
  --padding-right: 3rem;
  --background: transparent;
  --background-hover: #bfe9e8;
  --color: var(--c-primary-900);
  --color-hover: var(--c-conversion-600);
  --color-active: var(--c-conversion-500);
  --min-height: 2.5rem;
}

.i-menu.--open {
  --submenu-display: block;
  --submenu-button-scale: -1;
}

.i-menu.--active {
  --color: var(--color-active);
}

.i-menu > .i-menu__submenu {
  display: var(--submenu-display);
}

.i-menu__link {
  min-height: var(--min-height);
  padding-left: var(--padding-left);
  padding-right: var(--padding-right);
  background-color: var(--background);
  color: var(--color);
  border-radius: 2.5rem;
  text-decoration: none;
  transition: color .3s, background-color .3s;
}

@media (hover: hover) {
  .i-menu__link:hover {
    --color: var(--color-hover);
    background-color: var(--c-primary-100);
  }
}

.i-menu.--level-2 {
  --padding-left: 1.5rem;
  --padding-right: 1rem;
}

.i-menu.--level-3 {
  --padding-left: 2rem;
}

@media (width >= 75em) {
  .i-menu {
    --padding-left: 1rem;
    --padding-right: 1rem;
    --submenu-display: flex;
    --opacity: 0;
    --translate-y: .5rem;
    --pointer-events: none;
  }

  .i-menu .i-menu__submenu {
    z-index: 50;
    opacity: var(--opacity);
    pointer-events: var(--pointer-events);
    transform: translateY(var(--translate-y));
    background-color: #fff;
    width: 100%;
    min-width: 15rem;
    transition: opacity .3s, transform .3s;
    position: absolute;
    box-shadow: 0 .0625rem 2.5rem #40414040;
  }

  .i-menu.--level-1 > .i-menu__link {
    margin-block: .5rem;
    padding-block: .5rem;
  }
}

@media (width >= 75em) and (width >= 75em) and (width <= 93.749em) {
  .i-menu.--level-1 > .i-menu__link {
    padding-inline: .5rem;
  }
}

@media (width >= 75em) {
  .i-menu.--level-1 > .i-menu__submenu {
    top: 100%;
    left: 0;
  }

  .i-menu.--level-1:hover {
    --pointer-events: auto;
    --translate-y: 0;
    --opacity: 1;
  }

  .i-menu.--level-2 {
    --submenu-button-rotate: -90deg;
    position: relative;
  }

  .i-menu.--level-2 > .i-menu__submenu {
    top: 0;
    left: 100%;
  }

  .i-menu.--level-2 > .i-menu__submenu.--mirror {
    left: auto;
    right: 100%;
  }

  .i-menu.--level-2:hover {
    --pointer-events: auto;
    --translate-y: 0;
    --opacity: 1;
  }
}

@media (width <= 74.999em) {
  .i-menu {
    --padding-left: 1rem;
    --padding-right: 1rem;
    --min-height: 3.5rem;
  }

  .i-menu.--level-1 {
    margin-inline: 1rem;
  }

  .i-menu.--open {
    background-color: var(--c-primary-100);
    border-radius: 1rem;
  }

  .i-menu.--level-2 {
    --padding-left: 1.5rem;
    --padding-right: 1rem;
    margin: 0;
    padding-inline: 0;
  }
}

.i-notification {
  min-height: 2.5rem;
}

@media (width <= 74.999em) {
  .i-notification:has( ~ .m-header.--user-open) {
    display: none;
  }
}

@media (width <= 35.999em) {
  .i-notification {
    background-color: var(--c-primary-900);
  }
}

@media (width >= 62em) {
  .i-notification__container {
    padding-inline: 2.875rem;
  }

  .i-notification__close {
    position: absolute;
    right: 1rem;
  }
}

@media (width <= 35.999em) {
  .i-notification__close {
    position: absolute;
    top: .25rem;
    right: .25rem;
  }
}

.i-package__price {
  min-height: 4rem;
}

.i-package__price-icon {
  --size: 3rem;
  max-width: var(--size);
  flex: 0 0 var(--size);
  height: var(--size);
}

.i-partner {
  --logo-width: 9.75rem;
  --logo-height: 4.375rem;
}

.i-partner__logo {
  width: var(--logo-width);
  height: var(--logo-height);
  max-width: var(--logo-width);
  margin-inline: auto;
}

.i-payment__icon {
  --size: 3rem;
  max-width: var(--size);
  height: var(--size);
  flex: 0 0 var(--size);
}

.i-payment {
  grid-template-columns: 17.5rem 1fr 11.25rem;
  gap: 1.5rem;
  min-height: 7.5rem;
}

.i-person-compact.--col-4 {
  border-radius: 1.5rem;
  padding: .5rem;
}

.i-person-compact.--col-4 .i-person-compact__title {
  font-size: 1rem;
  line-height: 1.5rem;
}

.i-person-compact.--col-4 .i-person-compact__role {
  font-size: .875rem;
  line-height: 1rem;
}

.i-person-compact.--col-4 .i-person-compact__image {
  border-radius: 1rem;
}

.i-person-compact__social {
  background-color: var(--c-conversion-100);
  transition: background-color .3s;
}

.i-person-compact__social:hover {
  background-color: var(--c-conversion-300);
}

.i-person-compact__social:hover path {
  fill: var(--c-conversion-600);
}

.i-person-compact__contact .i-social {
  --size: 1.5rem;
  background-color: #0000;
}

.i-person.--inline {
  --align-items: flex-start;
  background-color: var(--c-primary-300);
  border: 1.5rem solid var(--c-primary-300);
  border-radius: 3rem;
}

@media (width >= 62em) {
  .i-person.--inline .i-person__available {
    justify-content: start;
  }

  .i-person.--inline .i-person__body {
    grid-template-rows: 1fr;
    grid-template-columns: 17.5rem 1fr 1fr;
    gap: 1.5rem;
    padding-inline: 1rem;
    display: grid;
  }

  .i-person.--inline .i-person__location {
    text-align: left;
  }

  .i-person.--inline .i-person__tag {
    width: fit-content;
  }

  .i-person.--inline .i-person__header {
    margin-top: 0;
  }

  .i-person.--inline .i-person__description {
    width: auto;
    margin-top: 0;
  }

  .i-person.--inline .i-person__inner, .i-person.--inline .i-person__header, .i-person.--inline .i-person__body, .i-person.--inline .i-person__middle-info {
    align-items: var(--align-items);
  }
}

@media (width <= 47.999em) {
  .i-person.--inline {
    border-width: 1rem;
    border-radius: 1.5rem;
  }
}

.i-person__detail-link {
  min-height: 2.5rem;
  padding-block: .25rem;
}

.i-person__description.entry-content ul:first-child {
  margin-top: 0;
}

@media (width >= 36em) {
  .i-person__description.entry-content ul.columns {
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    display: grid;
  }
}

.i-person__title.--link {
  color: var(--c-primary-900);
  text-decoration: none;
  transition: color .3s;
}

.i-person__title.--link:hover {
  color: var(--c-conversion-600);
  text-decoration: underline;
}

@media (width >= 62em) {
  .i-post.--hero {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 2rem;
    display: grid;
  }
}

@media (width <= 61.999em) {
  .i-post.--hero {
    max-width: 43rem;
    margin-inline: auto;
  }
}

.i-post.--hero .i-post__tags {
  margin-top: 0;
}

@media (width >= 62em) {
  .i-post.--hero .i-post__content-col {
    padding-block: 2rem;
  }
}

.i-post.--hero .i-post__content {
  justify-content: center;
}

@media (width >= 62em) {
  .i-post.--inline {
    grid-template-columns: minmax(10rem, 20.5rem) auto;
    gap: 2rem;
    display: grid;
  }

  .i-post.--inline .i-post__content {
    max-width: 70ch;
  }
}

@media (width <= 61.999em) {
  .i-post {
    max-width: 28rem;
    margin-inline: auto;
  }
}

.i-post .base-heading.u-h4 {
  --size: 1.25rem;
  line-height: 1.5rem;
}

@media (width <= 47.999em) {
  .i-post .base-heading.u-h4 {
    --size: 1.125rem;
  }
}

.i-post__heading-link {
  color: var(--c-primary-900);
  text-decoration: none;
  transition: color .3s;
}

.i-post__heading-link:hover {
  color: var(--c-conversion-600);
}

.i-priceplan {
  border: .0625rem solid #bfe9e8;
  border-radius: 1.5rem;
}

.i-priceplan.--highlight {
  border: none;
  box-shadow: 0 .5rem 1rem .125rem #0f1c7b26;
}

@media (width <= 61.999em) {
  .i-priceplan {
    max-width: 28rem;
    margin-inline: auto;
  }
}

.i-priceplan__ribbon {
  color: #476cfe;
  background: #bfe9e8;
  border-radius: .5rem;
  width: calc(100% - 4rem);
  top: -1rem;
}

.i-priceplan__price-before {
  text-decoration: line-through;
}

.i-priceplan__price {
  font-size: 4rem;
}

.i-references-slide {
  min-height: 24rem;
}

.i-references-slide__content.entry-content p:last-child {
  margin-bottom: 0;
}

.btn.i-selected-tag {
  --border-radius: 3.375rem;
  --min-height: 2rem;
  --font-size: .875rem;
  --font-color: var(--c-conversion-900);
  --padding-y: .5rem;
  --padding-x: .5rem;
  --svg-size: 1rem;
  background-color: var(--c-conversion-100);
  outline: .0625rem solid var(--c-conversion-300);
  text-transform: none;
  min-width: auto;
  padding-left: 1rem;
}

@media (hover: hover) {
  .btn.i-selected-tag:hover {
    background-color: var(--c-conversion-300);
  }
}

.i-service__body {
  max-width: 24.0625rem;
}

.i-service__image {
  --size: 11.375rem;
  flex: 0 0 var(--size);
  max-width: var(--size);
  height: var(--size);
}

@media (width <= 47.999em) {
  .i-share-panel {
    border-radius: 1.5rem;
  }
}

@media (width <= 35.999em) {
  .i-simple-gallery {
    max-width: 28rem;
  }
}

.i-social {
  --size: 2.5rem;
  --img-size: 1.5rem;
  width: var(--size);
  height: var(--size);
  flex: 0 0 var(--size);
  max-width: var(--size);
  transition: background-color .3s;
}

.i-social img {
  width: var(--img-size);
  height: var(--img-size);
}

a.i-social:hover {
  background-color: #f3f3f4;
}

.i-step__icon {
  width: var(--size);
  height: var(--size);
}

.i-step {
  --size: 7rem;
}

.i-step:before {
  content: "";
  display: block;
  position: absolute;
}

.i-step.--column {
  justify-content: center;
}

.i-step.--column:before {
  left: 50%;
  top: calc(var(--size) / 2);
  background-image: url("line.df59d161.svg");
  background-repeat: round;
  width: calc(100% + 2rem);
  height: .25rem;
  transform: translateX(-50%);
}

@media (width <= 35.999em) {
  .i-step.--column:before {
    display: none;
  }

  .i-step.--column .i-step__body {
    width: 100%;
    max-width: 11rem;
  }
}

.i-step.--inline:before {
  top: 0;
  left: calc(var(--size) / 2);
  background-image: url("line-vertical.5b194eb2.svg");
  width: .25rem;
  height: calc(100% + 4.5rem);
}

@media (width <= 35.999em) {
  .i-step.--inline:before {
    display: none;
  }
}

.i-step.--inline .i-step__body {
  width: 100%;
}

@media (width <= 35.999em) {
  .i-step.--inline .i-step__body {
    max-width: 11rem;
  }
}

.i-step.--inline .i-step__link {
  margin-left: auto;
}

@media (width <= 35.999em) {
  .i-step.--inline {
    justify-content: center;
  }
}

.i-step__inner:before {
  content: "";
  left: 3.5rem;
  top: calc(var(--size) / 2);
  background-image: url("line-vertical.5b194eb2.svg");
  width: .25rem;
  height: calc(100% + 2rem);
  display: block;
  position: absolute;
  transform: translateX(-50%);
}

@media (width >= 36em) {
  .i-step__inner:before {
    display: none;
  }
}

.i-step__inner.--column {
  text-align: center;
  flex-direction: column;
  align-items: center;
}

@media (width <= 35.999em) {
  .i-step__inner.--column, .i-step__inner.--inline {
    text-align: left;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 1rem;
  }

  .i-step__inner.--inline .i-step__link {
    margin-inline: auto;
  }
}

.i-step__count {
  color: var(--c-conversion-500);
  font-size: 2.75rem;
  line-height: 2.75rem;
}

.i-step__count:after {
  content: ".";
}

.i-tag {
  --padding-x: 1rem;
  --padding-y: .25rem;
  --min-height: 2rem;
  --font-size: 1rem;
  --line-height: 1.5rem;
  padding-block: var(--padding-y);
  padding-inline: var(--padding-x);
  min-height: var(--min-height);
  font-size: var(--font-size);
  line-height: var(--line-height);
}

.i-tag.--sm {
  --padding-x: .5rem;
  --padding-y: .125rem;
  --min-height: 1.5rem;
  --font-size: .875rem;
}

.i-tag.--md {
  --padding-x: 1rem;
  --padding-y: .25rem;
  --min-height: 2rem;
  --font-size: .875rem;
  --line-height: 1rem;
}

.i-tag.--lg {
  --padding-x: 1.5rem;
  --padding-y: .25rem;
  --min-height: 2.5rem;
}

.i-tag.--xl {
  --padding-x: 1.5rem;
  --padding-y: 1rem;
  --min-height: 3.5rem;
}

.i-tag {
  --font-color: var(--c-primary-900);
  --background-color: var(--c-primary-100);
  --border-color: var(--background-color);
  background-color: var(--background-color);
  color: var(--font-color);
  outline: .0625rem solid var(--border-color);
  transition: color .3s;
}

.i-tag.--outline {
  --border-color: var(--c-conversion-500);
}

.i-tag.--white {
  --font-color: var(--c-primary-900);
  --background-color: var(--c-white);
}

.i-tag.--outline-white {
  --border-color: var(--c-conversion-500);
  --background-color: #fff;
  --font-color: var(--c-conversion-500);
  transition-property: outline, color, background-color;
}

a.i-tag.--outline-white:hover {
  --border-color: var(--c-conversion-500);
  --background-color: var(--c-conversion-100);
  --font-color: var(--c-conversion-600);
  color: var(--font-color);
}

.i-tag.--outline-conversion-100 {
  --border-color: var(--c-conversion-300);
  --background-color: var(--c-conversion-100);
  --font-color: var(--c-conversion-900);
}

.i-tag.--blank {
  --font-color: #404140;
  --background-color: transparent;
  min-height: auto;
  padding: 0;
}

.i-tag.--icon-before .i-tag__icon {
  order: -1;
}

.i-tag.--icon-only {
  width: var(--min-height);
  height: var(--min-height);
  min-height: auto;
  padding: 0;
}

.i-tag.--icon-only .i-tag__title {
  display: none;
}

@media (width >= 75em) and (width <= 93.749em) {
  .i-user-panel {
    padding: 1rem;
  }
}

@media (width <= 35.999em) {
  .i-user-panel {
    padding: 1.5rem;
  }
}

.i-user-panel__link {
  padding: .625rem;
}

.i-user-panel__link.--active {
  background-color: var(--c-conversion-100);
  transition: background-color .3s;
}

.i-user-panel__link.--active:hover, .i-user-panel__link.--active:focus {
  background-color: var(--c-conversion-100);
}

.i-user-panel__perex {
  word-break: break-all;
  line-height: 1rem;
}

.i-user-panel__logout.btn {
  min-width: auto;
}

.i-usp {
  --height: 10rem;
  height: var(--height);
}

@media (width <= 61.999em) {
  .i-usp {
    --height: 8.75rem;
  }
}

.i-usp__icon {
  width: 7rem;
  height: 4.375rem;
}

.i-usp__hero-text {
  height: 4.375rem;
  font-size: 2.75rem;
  line-height: 3.5rem;
}

.c-language-select {
  --dropdown-opacity: 0;
  --dropdown-pointer-events: none;
  --color: var(--c-primary-900);
  --background: transparent;
}

.c-language-select:hover {
  --dropdown-opacity: 1;
  --dropdown-pointer-events: auto;
  --color: var(--c-primary-900);
  --background: var(--c-primary-100);
}

.c-language-select__drowpdown {
  opacity: var(--dropdown-opacity);
  pointer-events: var(--dropdown-pointer-events);
  background-color: #fff;
  border-radius: 1rem;
  transition: opacity .3s;
  top: 100%;
  left: 0;
  box-shadow: 0 .0625rem 2.5rem #40414040;
}

.c-language-select__active {
  color: var(--color);
  background-color: var(--background);
  min-height: 2.5rem;
  transition: background-color .3s, color .3s;
}

@media (width <= 440px) {
  .c-language-select__active {
    margin-right: .5rem;
  }
}

.c-language-select__dropdown-link {
  color: var(--color);
  text-decoration: none;
  transition: background-color .3s;
}

.c-language-select__dropdown-link:hover {
  color: var(--color);
  background-color: var(--c-conversion-100);
}

.c-language-select__dropdown-link:focus {
  color: var(--color);
}

.link.--animation-right svg {
  transition-timing-function: cubic-bezier(.68, -.55, .27, 1.55);
}

.link.--animation-right:hover svg {
  transform: translateX(.5rem);
}

.link.--animation-zoom:hover svg {
  transform: scale(1.1);
}

.link.--black {
  --color: #404140;
}

.link.--primary {
  --color: #00a9a6;
}

.link.--primary-900-to-conversion-500 {
  --color: var(--c-primary-900);
}

.link.--primary-900-to-conversion-500:hover {
  --color: var(--c-conversion-500);
}

.link.--white {
  --color: #fff;
}

.link.--gray {
  --color: #abacac;
}

.link.--primary-900 {
  --color: var(--c-primary-900);
}

.link.--conversion {
  --color: var(--c-conversion-500);
}

.link.--colored:not(.--underline-initial) {
  color: var(--color);
  text-decoration-color: #0000;
}

.link.--colored:not(.--underline-initial):hover, .link.--colored:not(.--underline-initial):focus {
  color: var(--color);
  -webkit-text-decoration-color: var(--color);
  text-decoration-color: var(--color);
}

.link.--colored.--underline-initial {
  -webkit-text-decoration-color: inherit;
  text-decoration-color: inherit;
  color: var(--color);
}

.link.--colored.--underline-initial:hover, .link.--colored.--underline-initial:focus {
  text-decoration-color: #0000;
}

.link__icon svg {
  transition: transform .3s;
}

.link {
  text-decoration: underline #0000;
  transition: text-decoration-color .3s, color .3s;
}

.link:hover {
  -webkit-text-decoration-color: inherit;
  text-decoration-color: inherit;
}

.link.--icon-before .link__icon {
  order: -1;
}

.link.--underline-initial {
  -webkit-text-decoration-color: inherit;
  text-decoration-color: inherit;
}

.link.--underline-initial:hover, .link.--underline-initial:focus {
  text-decoration-color: #0000;
}

.link.--underline-none {
  text-decoration: none;
}

.c-listing-navigation:has(.c-listing-navigation__button) {
  margin-top: 3.5rem;
}

@media (width >= 75em) and (width <= 93.749em) {
  .main-footer-column__order-button.--size-default {
    --font-size: .75rem;
    --padding-y: .625rem;
    --padding-x: 1.125rem;
    --min-height: 2.5rem;
    --svg-size: 1rem;
    min-width: auto;
  }
}

@media (width >= 62em) and (width <= 74.999em) {
  .main-footer-column__order-button.--size-default {
    --font-size: .75rem;
    --padding-y: .625rem;
    --padding-x: 1.125rem;
    --min-height: 2.5rem;
    --svg-size: 1rem;
    min-width: auto;
  }
}

.main-footer {
  --spacing: var(--section-spacing);
  padding-block: var(--spacing);
  margin-bottom: -.0625rem;
  transform: translateY(-.0625rem);
}

@media (width <= 35.999em) {
  .m-header-logo {
    max-width: 7.5rem;
  }
}

.m-header-search {
  --translate-y: -150%;
  --pointer-events: none;
  --opacity: 0;
}

.m-header-search.--open {
  --translate-y: -50%;
  --pointer-events: auto;
  --opacity: 1;
}

.m-header-search__form {
  background-color: var(--c-primary-100);
  transform: translateY(var(--translate-y));
  pointer-events: var(--pointer-events);
  opacity: var(--opacity);
  width: 100%;
  height: 100%;
  transition: opacity .3s, transform .3s;
  position: absolute;
  top: 50%;
  left: 0;
}

.--js--main-search-opener.btn {
  --svg-size: 1.5rem;
}

.m-header-user {
  --opacity: 0;
  --pointer-events: none;
  --background: transparent;
  --svg-color: #404140;
}

.m-header-user.--open {
  --opacity: 1;
  --pointer-events: auto;
}

@media (width >= 75em) {
  .m-header-user:hover {
    --opacity: 1;
    --pointer-events: auto;
    --background: #f3f3f4;
    --svg-color: #00a9a6;
  }
}

@media (width <= 74.999em) {
  .m-header-user {
    position: static;
  }
}

.m-header-user__trigger {
  background-color: var(--background);
  min-width: 2.5rem;
  height: 2.5rem;
}

.m-header-user__trigger path {
  stroke: var(--svg-color);
  transition: stroke .3s;
}

.m-header-user__dropdown {
  opacity: var(--opacity);
  pointer-events: var(--pointer-events);
  width: min(100vw - 1rem, 20.5rem);
  transition: opacity .3s;
  top: 100%;
  transform: translate(1rem);
  box-shadow: 0 0 1.25rem #40414026;
}

@media (width >= 75em) {
  .m-header-user__dropdown {
    padding: 0;
  }
}

@media (width <= 74.999em) {
  .m-header-user__dropdown {
    box-shadow: none;
    background: none;
    border-radius: 0;
    width: 100%;
    padding-top: 4.5rem;
    transition: opacity .3s;
    top: 0;
    left: 0;
    transform: translate3d(0, 0, 0);
  }
}

.m-header-user__panel, .m-header-user__dropdown-logged-out {
  padding: 2rem;
}

@media (width <= 74.999em) {
  .m-header-user__panel, .m-header-user__dropdown-logged-out {
    background-color: #0000;
    padding-inline: 0;
  }
}

.m-header {
  --shadow-color: transparent;
  --background: transparent;
  --min-height: 4.5rem;
  background-color: var(--background);
  box-shadow: .25rem .5rem 1.5rem var(--shadow-color);
  transition: box-shadow .3s, transform .3s;
}

.m-header.headroom--not-top {
  --background: transparent;
}

@media (width <= 74.999em) {
  .m-header .m-header-search, .m-header .c-language-select {
    display: none;
  }
}

.m-header__body {
  min-height: var(--min-height);
}

@media (width <= 440px) {
  .m-header__side-menu {
    gap: .5rem;
  }
}

.m-header__contact-button.btn, .m-header__contact-button.btn.--xs {
  --font-size: 1rem;
  line-height: 1.25rem;
}

.m-header__functions {
  position: relative;
}

@media (width >= 75em) {
  .m-header__phone {
    margin-left: .5rem;
  }

  .m-header__phone:before {
    content: "";
    background-color: var(--c-primary-300);
    width: .0625rem;
    height: calc(100% - .3125rem);
    position: absolute;
    top: 50%;
    left: -.5rem;
    transform: translateY(-50%);
  }
}

@media (width <= 47.999em) {
  .m-header__phone .link__title {
    display: none;
  }
}

.m-header__phone .i-social {
  background-color: #0000;
}

@media (width <= 47.999em) {
  .m-header__phone .i-social {
    border: .0625rem solid var(--c-conversion-500);
    --size: 3rem;
    background-color: #fff;
  }
}

.m-nav {
  --transition-timing: 0;
}

@media (width >= 75em) {
  .m-nav {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (width <= 74.999em) {
  .m-nav {
    transition: transform var(--transition-timing) ease;
    z-index: 100;
    background-color: #fff;
    border-radius: 2rem 0 0 2rem;
    width: 100%;
    max-width: 26.25rem;
    height: 100%;
    padding-bottom: 3rem;
    position: fixed;
    top: 1rem;
    right: 0;
    overflow: hidden auto;
    transform: translateX(100%);
    box-shadow: 0 .0625rem 2.5rem #40414040;
  }

  .m-nav.--open {
    transform: translateX(0);
  }
}

@media (width <= 74.999em) and (width <= 74.999em) {
  .m-nav.--open .m-nav__header .m-header-search, .m-nav.--open .m-nav__header .c-language-select {
    display: block;
  }

  .m-nav.--open .m-nav__header .m-header-user__trigger {
    display: none;
  }

  .m-nav.--open .--js-main-nav-closer {
    margin: 0;
  }
}

@media (width <= 74.999em) {
  .m-nav.--transition {
    --transition-timing: .3s;
  }
}

.m-nav__search-field .f-base {
  padding-right: 3.25rem;
}

.m-nav-search__submit {
  margin-left: -3rem;
}

@media (width <= 74.999em) {
  .m-nav__search {
    padding-inline: 1rem;
  }
}

.f-m-nav__search {
  background-color: var(--c-primary-100);
  border-radius: 2rem;
  padding: .5rem;
}

.c-modal__content {
  pointer-events: all;
  width: var(--width);
  background-clip: padding-box;
  background-color: var(--content-background-color);
  border-radius: var(--content-border-radius);
  box-shadow: var(--content-box-shadow);
  transform: translateY(var(--content-translate));
  transition: transform var(--transtion-length) ease;
  max-height: calc(calc(var(--vh, 1vh) * 100)  - 4rem);
  max-height: calc(100vh - 4rem);
}

.c-modal__content .os-scrollbar {
  --os-padding-axis: 2.5rem;
}

@media (width <= 35.999em) {
  .c-modal__content .os-scrollbar {
    --os-padding-axis: 2rem;
  }
}

.c-modal__dialog {
  margin: 2rem auto;
}

.c-modal__body {
  padding: var(--content-padding-y) var(--content-padding-x);
}

.c-modal__header {
  padding: var(--content-padding-y) var(--content-padding-x) 0;
}

.c-modal__button-container {
  top: 1rem;
  right: 1rem;
}

@media (width <= 35.999em) {
  .c-modal__button-container {
    top: .75rem;
    right: .75rem;
  }
}

.c-modal__close-cross.btn {
  --min-height: 1.5rem;
  --svg-size: 1.5rem;
  background: none;
}

.c-modal__footer {
  padding: 0 var(--content-padding-x) var(--content-padding-y);
}

.c-modal {
  --transtion-length: .3s;
  --width: min(100% - 2rem, 58rem);
  --dialog-background-color: #00000040;
  --content-translate: 2rem;
  --content-padding-x: 2.5rem;
  --content-padding-y: 2.5rem;
  --content-background-color: var(--c-white);
  --content-border-radius: 2.5rem;
  --content-box-shadow: 0 .0625rem 2.5rem 0 #40414040;
  z-index: 9002;
  background-color: var(--dialog-background-color);
  transition: opacity var(--transtion-length) ease;
}

.c-modal.--open {
  --content-translate: 0;
  opacity: 1;
  pointer-events: all;
  visibility: visible;
}

.c-modal.--hidden {
  visibility: hidden;
  pointer-events: none;
  z-index: -1;
}

.c-modal.--size-sm {
  --width: min(100% - 2rem, 30rem);
}

.c-modal.--size-xl {
  --width: min(100% - 2rem, 71.25rem);
}

@media (width <= 35.999em) {
  .c-modal {
    --content-padding-x: 2rem;
    --content-padding-y: 2rem;
    --content-border-radius: 2rem;
  }
}

.m-person-filter .c-modal__header, .m-person-filter .c-modal__footer {
  padding: 0;
}

.m-person-filter:not(:has(.i-selected-tag)) .m-person-filter__reset-btn {
  display: none;
}

@media (width >= 48em) {
  .m-person-filter__reset, .m-person-filter__submit {
    width: 50%;
  }
}

.c-overlay {
  --opacity: 0;
  --pointer-events: none;
  --position: fixed;
  background-color: var(--c-primary-900);
  opacity: var(--opacity);
  pointer-events: var(--pointer-events);
  position: var(--position);
  transition: opacity .3s;
  inset: 0;
}

.c-overlay.--show {
  --opacity: .5;
  --pointer-events: auto;
}

.c-overlay.--absolute {
  --position: absolute;
}

.link.c-pagination__link {
  --background: transparent;
  --size: 2.5rem;
  background-color: var(--background);
  min-height: var(--size);
  border-radius: 5.625rem;
  padding: .5rem 1rem;
  font-weight: 350;
  transition-property: text-decoration-color, color, background-color;
}

.link.c-pagination__link:hover {
  --background: var(--c-conversion-100);
}

.link.c-pagination__link.--page-number {
  min-width: var(--size);
  padding: .5rem;
  text-decoration: none;
}

.link.c-pagination__link.--active {
  --background: var(--c-white);
  pointer-events: none;
  outline: .0625rem solid var(--c-primary-300);
  color: var(--c-conversion-500) !important;
}

.link.c-pagination__link.--blank {
  pointer-events: none;
}

@media (width <= 47.999em) {
  .c-pagination__items {
    flex-basis: 100%;
    order: -1;
    justify-content: center;
  }

  .c-pagination {
    justify-content: center;
  }
}

.c-scroll-up {
  --size: 2.5rem;
  --offset: 2.5rem;
  --radius: 1.25rem;
  --background: var(--c-conversion-500);
  --background-hover: var(--c-conversion-600);
  --icon-size: 1rem;
  --icon-color: #fff;
  width: var(--size);
  height: var(--size);
  bottom: var(--offset);
  right: var(--offset);
  border-radius: var(--radius);
  background-color: var(--background);
  z-index: 50;
  transform: translateY(var(--offset)) scale(.8);
  transition: background-color .3s, opacity .3s cubic-bezier(.68, -.55, .27, 1.55), transform .3s cubic-bezier(.68, -.55, .27, 1.55);
}

.c-scroll-up:hover {
  --background: var(--background-hover);
}

.c-scroll-up:after {
  content: "";
  opacity: 0;
  border-radius: 1.25rem;
  width: 100%;
  height: 100%;
  transition: opacity .5s, box-shadow .5s;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 0 .625rem 2.5rem #fff;
}

.c-scroll-up:active:after {
  opacity: 1;
  transition: all;
  position: absolute;
  box-shadow: 0 0 #fff;
}

.c-scroll-up svg {
  width: var(--icon-size);
  height: var(--icon-size);
}

.c-scroll-up path {
  fill: var(--icon-color);
}

.c-scroll-up.--active {
  opacity: 1;
  pointer-events: all;
  transform: translateY(0)scale(1);
}

@media (width <= 35.999em) {
  .c-scroll-up {
    --offset: 1rem;
  }
}

.s-calendar__app {
  --fc-border-color: #ebebeb;
  --fc-today-bg-color: #bfe9e8;
  --fc-event-bg-color: #00a9a6;
  --fc-button-bg-color: #00a9a6;
  --fc-button-border-color: #00a9a6;
  --fc-button-active-bg-color: #476cfe;
  --fc-button-active-border-color: #476cfe;
  --fc-button-hover-bg-color: #476cfe;
  --fc-button-hover-border-color: #476cfe;
  height: 35.625rem;
}

.s-calendar__app.fc {
  height: auto;
}

.s-calendar__app.--close {
  display: none;
}

.s-calendar__app .fc-daygrid {
  background-color: #fff;
}

.s-calendar__app .fc-event {
  border: none;
  border-radius: 0;
}

.s-calendar__app .fc-daygrid-day-number {
  color: #00a9a6;
  text-decoration: none;
}

.s-calendar__app .fc-event-time {
  flex-shrink: 0;
}

.s-calendar__app .fc-col-header-cell-cushion {
  text-decoration: none;
}

.s-calendar__app .fc-col-header-cell {
  padding: .5rem;
}

.s-calendar__toggler.--close svg {
  transform: scale(-1);
}

.s-content-w-line__icon {
  --square: 5.5rem;
  max-width: var(--square);
  aspect-ratio: 1;
}

@media (width <= 74.999em) {
  .s-content-w-line__icon {
    flex: 0 0 var(--square);
  }
}

@media (width <= 35.999em) {
  .s-content-w-line__icon {
    --square: 4rem;
  }
}

.s-content-w-line__line {
  width: .0625rem;
}

@media (width <= 74.999em) {
  .s-content-w-line__line {
    width: 100%;
    height: .0625rem;
  }
}

.s-error-404__img {
  margin-bottom: 3.125rem;
}

@media (width <= 47.999em) {
  .s-error-404__img {
    margin-bottom: 2rem;
  }
}

.s-error-404__sub-heading {
  max-width: 58rem;
  font-weight: 350;
}

.s-error-404__perex {
  max-width: 58rem;
}

.s-error-404__btn {
  margin-top: 2.5rem;
}

.s-hero-slider {
  overflow: hidden;
}

.s-hero-slider:first-child .i-hero-slide {
  box-shadow: none;
}

.s-hero-slider__slider:has(.swiper) {
  margin: -2.5rem;
}

.s-job-tag {
  margin-bottom: 3.5rem;
}

@media (width <= 35.999em) {
  .s-job-tag {
    margin-bottom: 2rem;
  }
}

.s-jobs__footer {
  background-color: #bfe9e8;
}

@media (width >= 48em) {
  .s-jobs__footer {
    padding: 3rem 4rem;
  }
}

.s-jobs__empty.--hidden, .s-jobs__sort.--hidden {
  display: none;
}

.s-person-filter {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}

@media (width <= 35.999em) {
  .s-person-filter__reset-btn {
    margin-inline: auto;
  }
}

.s-person-filter__wrap {
  border: .0625rem solid #d3d4d4;
  border-radius: 1.5rem;
}

.s-person-filter__wrap:not(:has(.i-selected-tag)) .s-person-filter__reset-btn {
  display: none;
}

.js-open-filter.btn {
  --font-size: 1rem;
  --padding-y: .5rem;
  --padding-x: 1rem;
}

.js-filter-clear.btn {
  min-width: auto;
}

.s-person-filter__selected-items:not(:has(.i-selected-tag)) {
  display: none;
}

.s-person-headshot__image {
  --size: 17.5rem;
  max-width: var(--size);
  flex: 0 0 var(--size);
  height: var(--size);
}

.s-post-detail__meta {
  --border-color: var(--c-primary-300);
  border-top: .0625rem solid var(--border-color);
  border-bottom: .0625rem solid var(--border-color);
}

.s-price-list__banner {
  margin-top: var(--section-spacing);
}

@media (width >= 36em) {
  .s-price-list__filter .f-category {
    min-width: 13rem;
  }
}

.s-references-slider {
  overflow: hidden;
}

.s-separator-wave img {
  width: 100vw;
  max-width: 100vw;
  margin-inline: auto;
}

.s-separator-wave.--up, .s-separator-wave.--up img {
  background-color: #fff;
}

.s-separator-wave.--down {
  background-color: #f3f3f4;
}

.s-separator-wave.--down img {
  background-color: #fff;
}

.s-separator-wave.--down-dark {
  background-color: #404140;
}

.s-separator-wave.--down-dark svg {
  width: 100%;
  height: 100%;
}

.s-separator-wave.--down-dark img, .s-separator-wave.--down-dark svg {
  background-color: #f3f3f4;
}

@media (width >= 62em) {
  .s-text-media__grid {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
  }
}

.s-text-media__col.--content-col {
  order: var(--content-order);
  padding-left: var(--content-offset-left);
  padding-right: var(--content-offset-right);
}

@media (width >= 62em) {
  .s-text-media__col.--content-col {
    margin-top: 1.5rem;
  }
}

.s-text-media {
  --content-offset-left: 0;
  --content-offset-right: 0;
  --content-order: 1;
}

@media (width >= 62em) {
  .s-text-media.--reverse {
    --content-order: -1;
  }
}

.s-user-password .base-header {
  --spacing: 3.5rem;
}

@media (width <= 35.999em) {
  .s-user-password .base-header {
    --spacing: 2.5rem;
  }
}

.s-play__triangle {
  fill: #fff !important;
}

.c-svg {
  opacity: 0;
  transition: opacity .3s;
}

.c-svg.entered {
  opacity: 1;
}

.c-svg.--colored path {
  fill: var(--color);
  transition: fill .3s;
}

.c-svg.--primary {
  --color: var(--c-primary-500);
}

.c-svg.--conversion {
  --color: var(--c-conversion-500);
}

.c-svg.--conversion-600 {
  --color: var(--c-conversion-600);
}

.c-svg.--text {
  --color: var(--c-primary-900);
}

.c-svg.--white {
  --color: var(--c-white);
}

.w-categories__item-link {
  min-height: 3.5rem;
}

.w-clinic {
  margin-bottom: 3.5rem;
}

@media (width <= 74.999em) {
  .w-clinic {
    width: min(100%, 28rem);
    margin-inline: auto;
  }
}

@media (width <= 35.999em) {
  .w-clinic {
    margin-bottom: 2rem;
  }
}

.w-job {
  aspect-ratio: 1 / 1.27;
  border: .5rem solid #fff;
  box-shadow: 0 .0625rem 2.5rem #40414040;
}

@media (width <= 74.999em) {
  .w-job {
    width: min(100%, 28rem);
    margin-inline: auto;
  }
}

.w-job__content {
  background-color: #fffc;
  width: calc(100% - 2rem);
  bottom: 1rem;
  left: 50%;
  transform: translate(-50%);
}

@media (width <= 35.999em) {
  .w-job__content {
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 1rem;
    bottom: 0;
  }
}

.w-person {
  margin-bottom: 3.5rem;
}

@media (width <= 74.999em) {
  .w-person {
    width: min(100%, 28rem);
    margin-inline: auto;
  }
}

@media (width <= 35.999em) {
  .w-person {
    margin-bottom: 2rem;
  }
}

.widget_categories select {
  --border-color: #abacac;
  --radius: .25rem;
  border: .0625rem solid var(--border-color);
  border-radius: var(--radius);
  width: 100%;
  height: 3rem;
  margin: 0;
  padding: .5rem;
}

.wp-aside-widgets .cat-item {
  align-items: center;
  padding-right: .5rem;
  display: flex;
}

.wp-aside-widgets .cat-item > a {
  width: 100%;
}

.widget_media_image a {
  display: block;
}

.widget_nav_menu .menu-item-has-children {
  --submenu-display: none;
  --svg-rotate: 0deg;
  position: relative;
}

.widget_nav_menu .menu-item-has-children .sub-menu {
  padding-left: 1rem;
}

.widget_nav_menu .menu-item-has-children > .sub-menu {
  display: var(--submenu-display);
}

.widget_nav_menu .menu-item-has-children > a {
  padding-right: 3rem;
}

.widget_nav_menu .menu-item-has-children.--open {
  --submenu-display: block;
  --svg-rotate: 180deg;
}

.widget_pages .page_item.active {
  text-decoration: underline;
}

.widget_pages .page_item_has_children {
  --submenu-display: none;
  --svg-rotate: 0deg;
  position: relative;
}

.widget_pages .page_item_has_children .sub-menu {
  padding-left: 1rem;
}

.widget_pages .page_item_has_children > .children {
  display: var(--submenu-display);
}

.widget_pages .page_item_has_children > a {
  padding-right: 3rem;
}

.widget_pages .page_item_has_children.--open {
  --submenu-display: block;
  --svg-rotate: 180deg;
}

.widget_search form > div {
  gap: .5rem;
  display: flex;
}

.widget_search input[type="text"] {
  --border-color: #abacac;
  --radius: .25rem;
  border: .0625rem solid var(--border-color);
  border-radius: var(--radius);
  -webkit-appearance: none;
  appearance: none;
  background-clip: padding-box;
  width: 100%;
  min-height: 2.875rem;
  padding-left: .5rem;
  display: block;
}

.widget_search input[type="text"]:focus {
  border-color: none;
  outline: none;
}

.widget_search input[type="submit"] {
  --border-radius: .375rem;
  --background: #00a9a6;
  --font-color: #fff;
  color: var(--font-color);
  background-color: var(--background);
  border-radius: var(--border-radius);
  -webkit-user-select: none;
  user-select: none;
  z-index: 1;
  touch-action: manipulation;
  border: none;
  height: 100%;
  min-height: 2.875rem;
  padding-block: .5rem;
  padding-inline: 1rem;
  text-decoration: none;
  transition: background-color .3s;
  display: block;
}

.widget_search input[type="submit"]:hover {
  color: var(--font-color);
  text-decoration: none;
}

.widget_search input[type="submit"]:focus, .widget_search input[type="submit"]:active {
  color: var(--font-color);
  outline: 0;
  text-decoration: none;
}

@media (hover: hover) {
  .widget_search input[type="submit"]:hover {
    --background: #476cfe;
  }
}

.wp-aside-widgets ul {
  flex-direction: column;
  display: flex;
}

.wp-aside-widgets .widget {
  flex-direction: column;
  gap: 1.5rem;
  display: flex;
}

@media (width <= 61.999em) {
  .wp-aside-widgets .widget {
    --content-display: none;
    --svg-rotate: 0deg;
  }

  .wp-aside-widgets .widget ul, .wp-aside-widgets .widget form {
    display: var(--content-display);
  }

  .wp-aside-widgets .widget.--open {
    --content-display: flex;
    --svg-rotate: 180deg;
  }
}

.wp-aside-widgets .widgettitle {
  --size: 1.5rem;
  font-size: var(--size);
  font-weight: 700;
  display: block;
  position: relative;
}

@media (width <= 47.999em) {
  .wp-aside-widgets .widgettitle {
    --size: 1.25rem;
  }
}

.wp-aside-widgets .wp-caption {
  flex-direction: column;
  gap: .5rem;
  max-width: 100%;
  margin: 0;
  display: flex;
}

.wp-aside-widgets li {
  --color: #404140;
  display: block;
}

.wp-aside-widgets li > a {
  color: var(--color);
  padding-block: .5rem;
  min-height: 2.5rem;
  padding-left: .5rem;
  padding-right: .5rem;
  text-decoration-color: #0000;
  transition: text-decoration-color .3s;
  display: block;
}

.wp-aside-widgets li > a:hover {
  -webkit-text-decoration-color: var(--color);
  text-decoration-color: var(--color);
}

.widget__dropdown-btn {
  --size: 1.5rem;
  cursor: pointer;
  width: var(--size);
  height: var(--size);
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.widget__dropdown-btn svg {
  rotate: var(--svg-rotate);
  transition: rotate .3s;
}

@media (width >= 62em) {
  .widget__dropdown-btn {
    display: none;
  }
}

.wp-aside-widgets .sub-menu__button {
  --size: 2.5rem;
  cursor: pointer;
  width: var(--size);
  height: var(--size);
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.wp-aside-widgets .sub-menu__button svg {
  rotate: var(--svg-rotate);
  transition: rotate .3s;
}

.wp-breadcrumbs {
  --gap: 1.5rem;
}

.wp-breadcrumbs__group > span {
  gap: var(--gap);
  display: flex;
}

@media (width <= 47.999em) {
  .wp-breadcrumbs__group {
    display: none;
  }
}

.wp-breadcrumbs__group > span span {
  --height: 3.5rem;
  min-height: var(--height);
  align-items: center;
  display: flex;
}

.wp-breadcrumbs__group > span span a {
  display: block;
}

.wp-breadcrumbs__group > span span:first-child a {
  color: #0000;
  height: var(--height);
  -webkit-user-select: none;
  user-select: none;
  background-image: url("home.81535a37.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1rem;
  width: 1.5rem;
  display: inline-block;
  position: relative;
}

.wp-breadcrumbs__group > span span.breadcrumb_last {
  font-weight: 600;
}

.wp-breadcrumbs__group > span span:not(:first-child) {
  position: relative;
}

.wp-breadcrumbs__group > span span:not(:first-child):before {
  content: "";
  left: calc(var(--gap) / -2);
  background-color: #00a9a6;
  width: .25rem;
  height: .25rem;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.entry-content > * + * {
  margin-block-start: 1rem;
}

.entry-content strong:empty, .entry-content b:empty, .entry-content p:empty {
  display: none;
}

.entry-content hr {
  background: #ebebeb;
  border: none;
  height: .0625rem;
  margin-block: 3rem;
}

.entry-content .wp-caption {
  text-align: center;
  border: 0;
  max-width: 100%;
  padding: 0;
}

.entry-content .wp-caption-text {
  text-align: center;
  margin-bottom: 0;
  margin-left: 0;
  padding: .25rem .625rem;
  font-size: 1rem;
  transform: none;
}

.entry-content blockquote {
  background-color: var(--c-conversion-100);
  border: .0625rem solid var(--c-conversion-300);
  border-radius: 1.5rem;
  flex-direction: column;
  gap: .5rem;
  margin-block: 1.5rem;
  padding: 1rem;
  display: flex;
  position: relative;
}

.entry-content blockquote ul {
  margin-top: 0;
}

.entry-content blockquote ul li:last-child {
  margin-bottom: 0;
}

.entry-content blockquote p {
  margin-bottom: 0;
  font-style: italic;
}

.entry-content .column {
  width: 100%;
  min-height: .0625rem;
  margin-bottom: 1.5rem;
  padding-left: .9375rem;
  padding-right: .9375rem;
  position: relative;
}

.entry-content .column img {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  display: block;
  float: none !important;
}

@media (width <= 61.999em) {
  .entry-content .column h2:first-of-type, .entry-content .column h3:first-of-type, .entry-content .column h4:first-of-type, .entry-content .column h5:first-of-type, .entry-content .column h6:first-of-type {
    margin-top: 0;
  }
}

.entry-content .columns-1, .entry-content .columns-1 .column {
  display: block;
}

.entry-content .columns-2 {
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  display: grid;
}

@media (width <= 61.999em) {
  .entry-content .columns-2 {
    flex-direction: column;
    display: flex;
  }
}

.entry-content .columns-3 {
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  display: grid;
}

@media (width <= 61.999em) {
  .entry-content .columns-3 {
    flex-direction: column;
    display: flex;
  }
}

.entry-content .columns-4 {
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  display: grid;
}

@media (width <= 74.999em) {
  .entry-content .columns-4 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (width <= 61.999em) {
  .entry-content .columns-4 {
    flex-direction: column;
    display: flex;
  }
}

.entry-content h1, .entry-content h2, .entry-content h3, .entry-content h4, .entry-content h5, .entry-content h6 {
  margin-top: 3.5rem;
  margin-bottom: 1rem;
}

.entry-content h1 {
  margin-top: 0;
  position: relative;
}

.entry-content h2 {
  position: relative;
}

.entry-content > h2:first-child, .entry-content > h3:first-child {
  margin-top: 0;
}

.entry-content {
  --img-border-radius: 2.5rem;
  --img-border-width: .5rem;
}

@media (width <= 35.999em) {
  .entry-content {
    --img-border-radius: 1.25rem;
    --img-border-width: .25rem;
  }
}

.entry-content > a {
  display: block;
}

.entry-content > img {
  max-width: 100%;
  margin-block: 1rem;
}

.entry-content > img, .entry-content .img {
  border-radius: var(--img-border-radius);
  border: var(--img-border-width) solid #fff;
  box-shadow: 0 0 1.25rem #40414026;
}

.entry-content .i-person img, .entry-content .i-media img {
  box-shadow: none;
  border: none;
  border-radius: 0;
  margin-block: 0;
}

.entry-content ol > li {
  counter-increment: item;
  padding-left: 1.5rem;
  position: relative;
}

.entry-content ol > li:before {
  content: counter(item) ".";
  color: var(--c-conversion-500);
  width: 1.75rem;
  height: 1.75rem;
  margin-left: -2.1875rem;
  margin-right: .5rem;
  padding: .0625rem .25rem .0625rem .375rem;
  font-size: 1.125rem;
  font-weight: 700;
  display: inline-flex;
  top: .0625rem;
}

.entry-content ol > li ol > li {
  counter-increment: sub-item;
}

.entry-content ol > li ol > li:before {
  content: counter(sub-item) ".";
}

.entry-content ol > li ol > li ol > li {
  counter-increment: sub-sub-item;
}

.entry-content ol > li ol > li ol > li:before {
  content: counter(sub-sub-item) ".";
}

.entry-content ol[style*="list-style-type: lower-alpha"] {
  list-style-type: none !important;
}

.entry-content ol[style*="list-style-type: lower-alpha"] li:before {
  content: counter(item, lower-alpha) ")";
}

.entry-content ol[style*="list-style-type: upper-alpha"] {
  list-style-type: none !important;
}

.entry-content ol[style*="list-style-type: upper-alpha"] li:before {
  content: counter(item, upper-alpha) ")";
}

.entry-content ol[style*="list-style-type: lower-latin"] {
  list-style-type: none !important;
}

.entry-content ol[style*="list-style-type: lower-latin"] li:before {
  content: counter(item, lower-latin) ")";
}

.entry-content ol[style*="list-style-type: upper-latin"] {
  list-style-type: none !important;
}

.entry-content ol[style*="list-style-type: upper-latin"] li:before {
  content: counter(item, upper-latin) ")";
}

.entry-content ol[style*="list-style-type: lower-greek"] {
  list-style-type: none !important;
}

.entry-content ol[style*="list-style-type: lower-greek"] li:before {
  content: counter(item, lower-greek) ")";
}

.entry-content ol[style*="list-style-type: upper-greek"] {
  list-style-type: none !important;
}

.entry-content ol[style*="list-style-type: upper-greek"] li:before {
  content: counter(item, upper-greek) ")";
}

.entry-content ol[style*="list-style-type: lower-roman"] {
  list-style-type: none !important;
}

.entry-content ol[style*="list-style-type: lower-roman"] li:before {
  content: counter(item, lower-roman) ")";
}

.entry-content ol[style*="list-style-type: upper-roman"] {
  list-style-type: none !important;
}

.entry-content ol[style*="list-style-type: upper-roman"] li:before {
  content: counter(item, upper-roman) ")";
}

.entry-content ul {
  list-style-type: none;
}

.entry-content ul > li {
  padding-left: 1.5rem;
  position: relative;
}

.entry-content ul > li:before {
  content: "";
  background-color: var(--c-conversion-500);
  border-radius: 50%;
  width: .5rem;
  height: .5rem;
  display: block;
  position: absolute;
  top: .5rem;
  left: 0;
}

.entry-content ul, .entry-content ol {
  margin: 1rem 0 0;
  padding: 0;
  list-style: none;
}

.entry-content ul ul, .entry-content ul ol, .entry-content ol ul, .entry-content ol ol {
  margin-top: .5rem;
  margin-bottom: 0;
}

.entry-content ul li, .entry-content ol li {
  margin-bottom: .5rem;
}

p {
  margin-bottom: 0;
}

p a {
  display: inline;
}

.entry-content .table-responsive {
  border: 1rem solid var(--c-white);
  margin: 1rem 0;
  overflow: auto;
}

.entry-content th, .entry-content td, .entry-content tr {
  border: none !important;
}

.entry-content td, .entry-content th {
  text-align: center;
  padding: 1rem;
  position: relative;
}

.entry-content tfoot {
  background-color: var(--c-white);
  color: var(--c-primary-900);
  font-size: 1rem;
  font-weight: 400;
}

.entry-content tfoot th:before, .entry-content tfoot td:before {
  content: "";
  background: var(--c-primary-300);
  width: 100%;
  height: .0625rem;
  position: absolute;
  top: 0;
  right: 0;
}

.entry-content thead {
  background-color: var(--c-primary-300);
  color: var(--c-primary-900);
  font-size: 1rem;
  font-weight: 400;
}

.entry-content thead th:first-child, .entry-content thead td:first-child {
  border-radius: 1.5rem 0 0 1.5rem;
}

.entry-content thead th:last-child, .entry-content thead td:last-child {
  border-radius: 0 1.5rem 1.5rem 0;
}

.entry-content tbody {
  background-color: var(--c-white);
  color: var(--c-primary-900);
  font-size: 1rem;
  font-weight: 325;
}

.entry-content tbody tr:nth-child(2n) {
  background-color: #f3f3f4;
}

.entry-content tbody td, .entry-content tbody th {
  padding: 1rem 1.5rem;
}

.entry-content tbody td:first-child, .entry-content tbody th:first-child {
  border-radius: 1.5rem 0 0 1.5rem;
}

.entry-content tbody td:last-child, .entry-content tbody th:last-child {
  border-radius: 0 1.5rem 1.5rem 0;
}

.entry-content table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 0 !important;
}

.entry-content table p {
  font-size: 1rem;
  transform: none;
  margin-bottom: 0 !important;
}

.g-search-results {
  flex-direction: column;
  gap: 2rem;
  padding-top: 5rem;
  display: flex;
}

.i-search-result {
  max-width: 80ch;
}

.i-search-result__heading-link {
  text-decoration-color: #0000;
}

.i-search-result__heading-link:hover {
  -webkit-text-decoration-color: var(--c-conversion-500);
  text-decoration-color: var(--c-conversion-500);
}

.i-search-result__link {
  text-decoration-color: #0000;
  transition-property: color, text-decoration-color;
}

.i-search-result__link:hover {
  color: var(--c-conversion-500);
  -webkit-text-decoration-color: var(--c-conversion-500);
  text-decoration-color: var(--c-conversion-500);
}

.entry-content .gallery-item {
  aspect-ratio: 1 / .75;
  margin: 0;
  padding: 0;
  position: relative;
}

.entry-content .gallery-item a {
  transition: opacity .3s;
  display: block;
  position: relative;
}

.entry-content .gallery-item a:hover {
  opacity: .7;
}

.entry-content .gallery-item img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  margin: 0;
}

.search-form__wrap {
  align-items: center;
  gap: 1rem 2rem;
  max-width: 80ch;
  display: flex;
  position: relative;
}

@media (width <= 61.999em) {
  .search-form__wrap {
    flex-wrap: wrap;
  }
}

.search-form__heading {
  flex-shrink: 0;
}

.search-form {
  background-color: var(--c-white);
  box-shadow: inset 0 0 0 .0625rem var(--c-primary-500);
  flex-grow: 1;
  display: flex;
  position: relative;
}

.search-form__input {
  color: var(--c-primary-900);
  background: none;
  border: 0;
  outline: 0;
  flex-grow: 1;
  padding: 0 1rem;
  font-weight: 350;
}

@media (width <= 35.999em) {
  .search-form__input {
    min-width: 0;
  }
}

.search-form__submit {
  cursor: pointer;
  background-color: #0000;
  border: none;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  transition: background-color .2s;
  display: flex;
}

.search-form__submit svg {
  fill: var(--c-conversion-500);
  width: 1.125rem;
  height: 1.125rem;
  transition: fill .2s;
}

.search-form__submit:hover {
  background-color: var(--c-conversion-500);
}

.search-form__submit:hover svg, .search-form__submit:hover path {
  fill: var(--c-white);
}

.search-form__submit:active, .search-form__submit:focus {
  outline: 0;
}

.entry-content .gallery {
  align-items: center;
  gap: 1.5rem;
  margin-top: 2.5rem;
  margin-bottom: 0;
  display: grid;
}

@media (width <= 47.999em) {
  .entry-content .gallery {
    --img-border-radius: 1.25rem;
    --img-border-width: .25rem;
  }
}

@media (width <= 61.999em) {
  .entry-content .gallery {
    gap: 1rem;
  }
}

@media (width <= 35.999em) {
  .entry-content .gallery {
    gap: .5rem;
  }
}

.entry-content .gallery > br {
  display: none;
}

.entry-content .gallery-columns-9 {
  grid-template-columns: repeat(8, 1fr);
}

@media (width <= 61.999em) {
  .entry-content .gallery-columns-9 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.entry-content .gallery-columns-8 {
  grid-template-columns: repeat(8, 1fr);
}

@media (width <= 61.999em) {
  .entry-content .gallery-columns-8 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.entry-content .gallery-columns-7 {
  grid-template-columns: repeat(7, 1fr);
}

@media (width <= 61.999em) {
  .entry-content .gallery-columns-7 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.entry-content .gallery-columns-6 {
  grid-template-columns: repeat(6, 1fr);
}

@media (width <= 61.999em) {
  .entry-content .gallery-columns-6 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.entry-content .gallery-columns-5 {
  --img-border-radius: 2rem;
  grid-template-columns: repeat(5, 1fr);
  gap: 1rem;
}

@media (width <= 47.999em) {
  .entry-content .gallery-columns-5 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.entry-content .gallery-columns-4 {
  grid-template-columns: repeat(4, 1fr);
}

@media (width <= 47.999em) {
  .entry-content .gallery-columns-4 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.entry-content .gallery-columns-3 {
  grid-template-columns: repeat(3, 1fr);
}

@media (width <= 61.999em) {
  .entry-content .gallery-columns-3 {
    --img-border-radius: 1.5rem;
  }
}

@media (width <= 35.999em) {
  .entry-content .gallery-columns-3 {
    gap: .5rem;
  }
}

@media (width <= 47.999em) {
  .entry-content .gallery-columns-3 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.entry-content .gallery-columns-2 {
  grid-template-columns: repeat(2, 1fr);
}

@media (width <= 47.999em) {
  .entry-content .gallery-columns-2 {
    grid-template-columns: auto;
    max-width: 28rem;
    margin-inline: auto;
  }
}

.entry-content .wp-gallery-no-gutter .gallery {
  gap: 0;
}
/*# sourceMappingURL=index.css.map */
