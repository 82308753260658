.u-rounded-full {
  border-radius: 9999px;
}

.u-rounded-2 {
  border-radius: 16px;
}

.u-rounded-3 {
  border-radius: 24px;
}

.u-rounded-4 {
  border-radius: 32px;
}

.u-rounded-5 {
  border-radius: 40px;
}

.u-rounded-9 {
  border-radius: 72px;
}

.u-rounded-10 {
  border-radius: 80px;
}

.u-rounded-sm-9 {
  @include media-breakpoint-up(sm) {
    border-radius: 72px;
  }
}

.u-rounded-md-10 {
  @include media-breakpoint-up(md) {
    border-radius: 80px;
  }
}

.u-rounded-lg-10 {
  @include media-breakpoint-up(lg) {
    border-radius: 80px;
  }
}

.u-rounded-lg-5 {
  @include media-breakpoint-up(lg) {
    border-radius: 40px;
  }
}

.u-rounded-md-5 {
  @include media-breakpoint-up(md) {
    border-radius: 40px;
  }
}

.u-rounded-md-4 {
  @include media-breakpoint-up(md) {
    border-radius: 32px;
  }
}
