@use "settings/s-colors" as colors;

.btn {
  &.--primary {
    --background: var(--c-conversion-500);

    background-color: var(--background);
    transition: background-color 0.3s ease;
    transition-property: background-color, opacity;

    @media (hover: hover) {
      &:hover {
        --background: var(--c-conversion-600);
      }
    }
  }

  &.--outline {
    --font-color: var(--c-conversion-500);
    --border-color: var(--c-conversion-500);
    --background: var(--c-white);

    background-color: var(--background);
    border: 1px solid var(--border-color);
    transition: border-color 0.3s ease;
    transition-property: color, border-color, opacity, background-color;

    @media (hover: hover) {
      &:hover {
        --background: var(--c-conversion-100);
      }
    }
  }

  &.--semi-transparent {
    --font-color: var(--c-conversion-500);
    --background: #{colors.$white-o-70};

    background-color: var(--background);
    transition: opacity 0.3s ease;
    transition-property: background-color, opacity, box-shadow;
    box-shadow: 0 0 20px 0 #40414026;

    @media (hover: hover) {
      &:hover {
        --font-color: var(--c-conversion-500);
        --background: var(--c-white);

        box-shadow: 0 1px 40px 0 #40414040;
      }
    }
  }

  &.--ghost {
    --background: transparent;
    --font-color: var(--c-conversion-500);

    background-color: var(--background);
    transition: background-color 0.3s ease;
    transition-property: color, background-color;

    @media (hover: hover) {
      &:hover {
        --background: #{colors.$gray-100};
        --font-color: var(--c-conversion-600);
      }
    }
  }

  &.--link {
    --border-radius: 0;
    --font-size: 14px;
    --font-weight: 400;
    --font-color: var(--c-primary-900);
    --background: transparent;
    --underline-color: var(--c-primary-900);
    --min-height: 20px;

    background-color: var(--background);
    color: var(--font-color);
    text-transform: none;
    padding: 0;
    min-width: auto;
    width: fit-content;
    transition: color 0.3s ease;

    .btn__title {
      text-decoration: underline;
      text-decoration-color: var(--underline-color);
      transition: text-decoration-color 0.3s ease;
    }

    path {
      transition: fill 0.3s ease;
      will-change: fill;
    }

    &:hover {
      --font-color: var(--c-conversion-500);
      --underline-color: transparent;
    }
  }

  &.--facebook {
    --background: #{colors.$facebook};
    --border-radius: 8px;
    --background-hover: rgb(59 89 152 / 80%);

    background-color: var(--background);
    transition: background-color 0.3s ease;
    transition-property: background-color;

    &.--icon-only {
      width: 100%;
      flex-shrink: 1;
    }

    @media (hover: hover) {
      &:hover {
        background-color: var(--background-hover);
      }
    }
  }

  &.--google {
    --background: var(--c-white);
    --border-radius: 8px;
    --background-hover: rgb(0 0 0 / 5%);

    path {
      fill: revert-layer;
    }

    border: 1px solid colors.$gray-400;
    background-color: var(--background);
    transition: background-color 0.3s ease;
    transition-property: background-color;

    &.--icon-only {
      width: 100%;
      flex-shrink: 1;
    }

    @media (hover: hover) {
      &:hover {
        background-color: var(--background-hover);
      }
    }
  }

  &.--apple {
    --background: #{colors.$apple};
    --border-radius: 8px;
    --background-hover: rgb(17 17 17 / 80%);

    background-color: var(--background);
    transition: background-color 0.3s ease;
    transition-property: background-color;

    &.--icon-only {
      width: 100%;
      flex-shrink: 1;
    }

    @media (hover: hover) {
      &:hover {
        background-color: var(--background-hover);
      }
    }
  }
}
