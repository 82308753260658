@use "settings/s-colors" as colors;

.i-job__image {
  width: var(--image-size);
  height: var(--image-size);
  border-radius: 16px;
  grid-area: image;
}

.i-job__info {
  row-gap: 8px;

  @include media-breakpoint-up(sm) {
    flex-direction: row;
  }
}

.i-job {
  --image-size: 144px;

  display: grid;
  grid-template-columns: var(--image-size) 1fr 1fr;
  grid-template-areas:
    "image header header"
    "image tags tags"
    "image footer footer";

  @include media-breakpoint-down(md) {
    column-gap: 16px;
    grid-template-areas:
      "image header header"
      "image tags tags"
      "footer footer footer";
  }

  @include media-breakpoint-down(sm) {
    display: flex;
    flex-direction: column;
    grid-template-areas:
      "image header header"
      "tags tags tags"
      "footer footer footer";
  }
}

.i-job__tags {
  grid-area: tags;
}

.i-job__footer {
  grid-area: footer;
}

.i-job__header {
  grid-area: header;
}
