@use "settings/s-colors" as colors;

.i-faq__body {
  grid-template-rows: var(--rows);
  transition: grid-template-rows 0.5s ease;
  background-color: colors.$primary-100;
  border-radius: 0 0 var(--border-radius) var(--border-radius);
}

.i-faq__body-inner {
  &::before {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: colors.$primary-300;
  }
}

.i-faq {
  --border-radius: 6px;
  --rows: 0fr;
  --svg-scale: 1;

  border: 1px solid colors.$primary-300;
  border-radius: var(--border-radius);

  &.--open {
    --rows: 1fr;
    --svg-scale: -1;
  }
}

.i-faq__toggler {
  svg {
    transform: scale(var(--svg-scale));
    transition: transform 0.3s ease;
  }
}
