.i-package__price {
  min-height: 64px;
}

.i-package__price-icon {
  --size: 48px;

  max-width: var(--size);
  flex: 0 0 var(--size);
  height: var(--size);
}
