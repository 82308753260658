@use "settings/s-colors.scss" as colors;

.i-user-panel {
  @include media-breakpoint-only(xl) {
    padding: 16px;
  }

  @include media-breakpoint-down(sm) {
    padding: 24px;
  }
}

.i-user-panel__link {
  padding: 10px;

  &.--active {
    background-color: var(--c-conversion-100);
    transition: background-color 0.3s ease;

    &:hover,
    &:focus {
      background-color: var(--c-conversion-100);
    }
  }
}

.i-user-panel__perex {
  line-height: 16px;
  word-break: break-all;
}

.i-user-panel__logout.btn {
  min-width: auto;
}
