.g-buttons {
  --spacing: 56px;

  margin-top: var(--spacing);

  &.--spacing-xs {
    --spacing: 8px;
  }

  &.--spacing-lg {
    --spacing: 40px;
  }

  &.--spacing-xl {
    --spacing: 64px;
  }

  &.--spacing-none {
    --spacing: 0;
  }

  &.--hidden {
    display: none;
  }
}
