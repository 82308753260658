.s-price-list__banner {
  margin-top: var(--section-spacing);
}

.s-price-list__filter {
  .f-category {
    @include media-breakpoint-up(sm) {
      min-width: 208px;
    }
  }
}
