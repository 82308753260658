@use "../../../scss/settings/s-general.scss" as general;
@use "../../../scss/settings/s-colors.scss" as colors;

.entry-content {
  .gallery {
    margin-top: general.$spacing * 5;
    margin-bottom: 0;
    display: grid;
    gap: 24px;
    align-items: center;

    @include media-breakpoint-down(md) {
      --img-border-radius: 20px;
      --img-border-width: 4px;
    }

    @include media-breakpoint-down(lg) {
      gap: 16px;
    }

    @include media-breakpoint-down(sm) {
      gap: 8px;
    }

    > br {
      display: none;
    }
  }

  .gallery-columns-9 {
    grid-template-columns: repeat(8, 1fr);

    @include media-breakpoint-down(lg) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .gallery-columns-8 {
    grid-template-columns: repeat(8, 1fr);

    @include media-breakpoint-down(lg) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .gallery-columns-7 {
    grid-template-columns: repeat(7, 1fr);

    @include media-breakpoint-down(lg) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .gallery-columns-6 {
    grid-template-columns: repeat(6, 1fr);

    @include media-breakpoint-down(lg) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .gallery-columns-5 {
    --img-border-radius: 32px;

    gap: 16px;
    grid-template-columns: repeat(5, 1fr);

    @include media-breakpoint-down(md) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .gallery-columns-4 {
    grid-template-columns: repeat(4, 1fr);

    @include media-breakpoint-down(md) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .gallery-columns-3 {
    grid-template-columns: repeat(3, 1fr);

    @include media-breakpoint-down(lg) {
      --img-border-radius: 24px;
    }

    @include media-breakpoint-down(sm) {
      gap: 8px;
    }

    @include media-breakpoint-down(md) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .gallery-columns-2 {
    grid-template-columns: repeat(2, 1fr);

    @include media-breakpoint-down(md) {
      grid-template-columns: auto;
      max-width: 448px;
      margin-inline: auto;
    }
  }

  //WP gallery full width - no spaces between items

  .wp-gallery-no-gutter {
    .gallery {
      gap: 0;
    }
  }
}
