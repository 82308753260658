@use "settings/s-colors" as colors;

.s-jobs__footer {
  background-color: colors.$primary-100;

  @include media-breakpoint-up(md) {
    padding: 48px 64px;
  }
}

.s-jobs__empty,
.s-jobs__sort {
  &.--hidden {
    display: none;
  }
}
