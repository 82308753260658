.entry-content {
  --img-border-radius: 40px;
  --img-border-width: 8px;

  @include media-breakpoint-down(sm) {
    --img-border-radius: 20px;
    --img-border-width: 4px;
  }

  > a {
    display: block;
  }

  > img {
    margin-block: 16px;
    max-width: 100%;
  }

  > img,
  .img {
    box-shadow: 0 0 20px 0 #40414026;
    border-radius: var(--img-border-radius);
    border: var(--img-border-width) solid #fff;
  }

  .i-person,
  .i-media {
    img {
      margin-block: 0;
      border-radius: 0;
      box-shadow: none;
      border: none;
    }
  }
}
