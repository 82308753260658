@use "settings/s-colors" as colors;

.widget_nav_menu {
  .menu-item-has-children {
    --submenu-display: none;
    --svg-rotate: 0deg;

    position: relative;

    .sub-menu {
      padding-left: 16px;
    }

    > .sub-menu {
      display: var(--submenu-display);
    }

    > a {
      padding-right: 48px;
    }

    &.--open {
      --submenu-display: block;
      --svg-rotate: 180deg;
    }
  }
}
