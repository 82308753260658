@use "settings/s-colors" as colors;

.i-breadcrumb__divider {
  width: 24px;
  height: 24px;

  &::before {
    content: "";
    position: absolute;
    display: block;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 2px;
    height: calc(100% + 2px);
    background-color: var(--c-primary-300);
    border-radius: 2px;
  }
}

.i-breadcrumb {
  &.--homepage {
    .i-breadcrumb__link {
      gap: 0;
    }

    .i-breadcrumb__divider {
      display: none;
    }
  }
}
