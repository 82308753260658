@use "settings/s-colors" as colors;

.i-person-compact {
  &.--col-4 {
    padding: 8px;
    border-radius: 24px;

    .i-person-compact__title {
      font-size: 16px;
      line-height: 24px;
    }

    .i-person-compact__role {
      font-size: 14px;
      line-height: 16px;
    }

    .i-person-compact__image {
      border-radius: 16px;
    }
  }
}

.i-person-compact__social {
  transition: background-color 0.3s ease;
  background-color: var(--c-conversion-100);

  &:hover {
    background-color: var(--c-conversion-300);

    path {
      fill: var(--c-conversion-600);
    }
  }
}

.i-person-compact__contact .i-social {
  --size: 24px;

  background-color: transparent;
}
