.w-clinic {
  margin-bottom: 56px;

  @include media-breakpoint-down(xl) {
    width: min(100%, 448px);
    margin-inline: auto;
  }

  @include media-breakpoint-down(sm) {
    margin-bottom: 32px;
  }
}
