@use "settings/s-colors" as colors;

.f-file {
  --files-display: flex;

  &.--empty {
    --files-display: none;
  }
}

.f-file__files {
  display: var(--files-display, flex);
}
