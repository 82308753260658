@use "settings/s-colors" as colors;
@use "settings/s-shadows" as shadows;

.i-menu__submenu-button {
  --size: 24px;

  width: var(--size);
  height: var(--size);
  rotate: var(--submenu-button-rotate);

  @include media-breakpoint-down(xl) {
    --size: 40px;

    transition: scale 0.3s ease;
    scale: var(--submenu-button-scale);
    top: 0;
    right: 16px;

    &:hover {
      svg {
        transform: translateY(4px);
      }
    }
  }

  svg {
    transition: transform 0.3s ease;
  }
}

.i-menu {
  --submenu-display: none;
  --submenu-button-scale: 1;
  --submenu-button-rotate: 0deg;
  --padding-left: 16px;
  --padding-right: 48px;
  --background: transparent;
  --background-hover: #{colors.$primary-100};
  --color: var(--c-primary-900);
  --color-hover: var(--c-conversion-600);
  --color-active: var(--c-conversion-500);
  --min-height: 40px;

  &.--open {
    --submenu-display: block;
    --submenu-button-scale: -1;
  }

  &.--active {
    --color: var(--color-active);
  }

  & > .i-menu__submenu {
    display: var(--submenu-display);
  }
}

.i-menu__link {
  min-height: var(--min-height);
  padding-left: var(--padding-left);
  padding-right: var(--padding-right);
  text-decoration: none;
  background-color: var(--background);
  transition: color 0.3s ease;
  transition-property: color, background-color;
  color: var(--color);
  border-radius: 40px;

  @media (hover: hover) {
    &:hover {
      --color: var(--color-hover);

      background-color: var(--c-primary-100);
    }
  }
}

.i-menu.--level-2 {
  --padding-left: 24px;
  --padding-right: 16px;
}

.i-menu.--level-3 {
  --padding-left: 32px;
}

// Desktop

@include media-breakpoint-up(xl) {
  .i-menu {
    --padding-left: 16px;
    --padding-right: 16px;
    --submenu-display: flex;
    --opacity: 0;
    --translate-y: 8px;
    --pointer-events: none;

    .i-menu__submenu {
      z-index: 50;
      width: 100%;
      min-width: 240px;
      position: absolute;
      background-color: colors.$white;
      box-shadow: shadows.$shadow;
      opacity: var(--opacity);
      pointer-events: var(--pointer-events);
      transform: translateY(var(--translate-y));
      transition: opacity 0.3s ease;
      transition-property: opacity, transform;
    }

    &.--level-1 {
      & > .i-menu__link {
        padding-block: 8px;
        margin-block: 8px;

        @include media-breakpoint-only(xl) {
          padding-inline: 8px;
        }
      }

      & > .i-menu__submenu {
        top: 100%;
        left: 0;
      }

      &:hover {
        --pointer-events: auto;
        --translate-y: 0;
        --opacity: 1;
      }
    }

    &.--level-2 {
      --submenu-button-rotate: -90deg;

      position: relative;

      & > .i-menu__submenu {
        top: 0;
        left: 100%;

        &.--mirror {
          left: auto;
          right: 100%;
        }
      }

      &:hover {
        --pointer-events: auto;
        --translate-y: 0;
        --opacity: 1;
      }
    }
  }
}

@include media-breakpoint-down(xl) {
  .i-menu {
    --padding-left: 16px;
    --padding-right: 16px;
    --min-height: 56px;

    &.--level-1 {
      margin-inline: 16px;
    }

    &.--open {
      background-color: var(--c-primary-100);
      border-radius: 16px;
    }

    &.--level-2 {
      --padding-left: 24px;
      --padding-right: 16px;

      margin: 0;
      padding-inline: 0;
    }
  }
}
