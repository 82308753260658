@use "settings/s-general.scss" as general;
@use "settings/s-colors.scss" as colors;

p {
  margin-bottom: 0;

  a {
    display: inline;
  }
}
