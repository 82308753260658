@use "settings/s-colors.scss" as colors;

.link {
  &.--animation-right {
    svg {
      transition-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
    }

    &:hover {
      svg {
        transform: translateX(8px);
      }
    }
  }

  &.--animation-zoom {
    &:hover {
      svg {
        transform: scale(1.1);
      }
    }
  }
}
