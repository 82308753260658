@use "settings/s-shadows" as shadows;

.i-before-after__slider {
  --divider-color: var(--c-primary-300);

  &:focus {
    outline: none;
    box-shadow: shadows.$shadow-sm;
  }
}
