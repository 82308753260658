@use "settings/s-colors" as colors;

.f-error {
  --display: none;

  top: calc(100% + 4px);
  left: 4px;
  color: colors.$danger;
  display: var(--display);

  &.--show {
    --display: flex;
  }
}

.f-error__icon {
  height: 22px;
}
