@use "settings/s-colors.scss" as colors;

.u-color-conversion-100 {
  color: var(--c-conversion-100);
}

.u-color-conversion-500 {
  color: var(--c-conversion-500);
}

.u-color-white {
  color: var(--c-white);
}

.u-color-text {
  color: var(--c-primary-900);
}

.u-color-primary-500 {
  color: var(--c-primary-500);
}
