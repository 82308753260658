.s-content-w-line__icon {
  --square: 88px;

  max-width: var(--square);
  aspect-ratio: 1 / 1;

  @include media-breakpoint-down(xl) {
    flex: 0 0 var(--square);
  }

  @include media-breakpoint-down(sm) {
    --square: 64px;
  }
}

.s-content-w-line__line {
  width: 1px;

  @include media-breakpoint-down(xl) {
    width: 100%;
    height: 1px;
  }
}
