.i-person {
  &.--inline {
    --align-items: flex-start;

    background-color: var(--c-primary-300);
    border: 24px solid var(--c-primary-300);
    border-radius: 48px;

    @include media-breakpoint-up(lg) {
      .i-person__available {
        justify-content: start;
      }

      .i-person__body {
        display: grid;
        grid-template-columns: 280px 1fr 1fr;
        grid-template-rows: 1fr;
        gap: 24px;
        padding-inline: 16px;
      }

      .i-person__location {
        text-align: left;
      }

      .i-person__tag {
        width: fit-content;
      }

      .i-person__header {
        margin-top: 0;
      }

      .i-person__description {
        width: auto;
        margin-top: 0;
      }

      .i-person__inner,
      .i-person__header,
      .i-person__body,
      .i-person__middle-info {
        align-items: var(--align-items);
      }
    }

    @include media-breakpoint-down(md) {
      border-radius: 24px;
      border-width: 16px;
    }
  }
}
