.i-step__icon {
  width: var(--size);
  height: var(--size);
}

.i-step {
  --size: 112px;

  &::before {
    content: "";
    position: absolute;
    display: block;
  }

  &.--column {
    justify-content: center;

    &::before {
      left: 50%;
      top: calc(var(--size) / 2);
      transform: translateX(-50%);
      height: 4px;
      width: calc(100% + 32px);
      background-image: url("../images/svg/line.svg");
      background-repeat: round;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    .i-step__body {
      @include media-breakpoint-down(sm) {
        width: 100%;
        max-width: 176px;
      }
    }
  }

  &.--inline {
    &::before {
      top: 0;
      left: calc(var(--size) / 2);
      height: calc(100% + 72px);
      width: 4px;
      background-image: url("../images/svg/line-vertical.svg");

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    .i-step__body {
      width: 100%;

      @include media-breakpoint-down(sm) {
        max-width: 176px;
      }
    }

    .i-step__link {
      margin-left: auto;
    }

    @include media-breakpoint-down(sm) {
      justify-content: center;
    }
  }
}

.i-step__inner {
  &::before {
    content: "";
    position: absolute;
    display: block;
    left: 56px;
    top: calc(var(--size) / 2);
    transform: translateX(-50%);
    height: calc(100% + 32px);
    width: 4px;
    background-image: url("../images/svg/line-vertical.svg");

    @include media-breakpoint-up(sm) {
      display: none;
    }
  }

  &.--column {
    flex-direction: column;
    align-items: center;
    text-align: center;

    @include media-breakpoint-down(sm) {
      flex-direction: row;
      align-items: flex-start;
      text-align: left;
      justify-content: center;
      gap: 16px;
    }
  }

  &.--inline {
    @include media-breakpoint-down(sm) {
      flex-direction: row;
      align-items: flex-start;
      text-align: left;
      justify-content: center;
      gap: 16px;

      .i-step__link {
        margin-inline: auto;
      }
    }
  }
}

.i-step__count {
  font-size: 44px;
  line-height: 44px;
  color: var(--c-conversion-500);

  &::after {
    content: ".";
  }
}
