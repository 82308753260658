@use "settings/s-colors" as colors;

.btn.i-selected-tag {
  --border-radius: 54px;
  --min-height: 32px;
  --font-size: 14px;
  --font-color: var(--c-conversion-900);
  --padding-y: 8px;
  --padding-x: 8px;
  --svg-size: 16px;

  padding-left: 16px;
  background-color: var(--c-conversion-100);
  outline: 1px solid var(--c-conversion-300);
  text-transform: none;
  min-width: auto;

  @media (hover: hover) {
    &:hover {
      background-color: var(--c-conversion-300);
    }
  }
}
