@use "settings/s-colors" as colors;

.f-single-select__select.slim-select {
  &.ss-main {
    padding-inline: var(--padding-inline);
    box-shadow: none;
    transition:
      border 0.3s ease,
      border-radius 0.3s ease;

    &.ss-disabled {
      --ss-font-color: #{colors.$gray-300};
    }
  }

  &.ss-values {
    &.ss-single {
      margin: 0;
    }
  }

  &.ss-content {
    position: absolute;
  }

  .ss-list .ss-option {
    padding-inline: 16px;
  }

  .ss-arrow {
    display: none;
  }

  .ss-search {
    input {
      --ss-input-border-color: var(--c-primary-500);

      padding: 10px 16px;
      border: 1px solid var(--ss-input-border-color);
      border-radius: var(--radius);
      transition: border-color 0.3s ease;
      font-weight: 350;

      &:focus {
        --ss-input-border-color: var(--c-conversion-500);

        box-shadow: none;
      }

      &::placeholder {
        color: colors.$gray;
        font-weight: 325;
      }
    }
  }
}

.f-single-select,
.f-single-select__select.slim-select {
  --radius: 24px;
  --height: 48px;
  --padding-inline: 16px;
  --padding-block: 12px;
  --border-color: var(--c-primary-500);
  --ss-primary-color: var(--c-conversion-300);
  --ss-bg-color: #fff;
  --ss-font-color: var(--c-primary-900);
  --ss-font-placeholder-color: #{colors.$gray};
  --ss-disabled-color: var(--c-primary-300);
  --ss-border-color: var(--border-color);
  --ss-highlight-color: #fffb8c;
  --ss-success-color: #00b755;
  --ss-error-color: #dc3545;
  --ss-main-height: var(--height);
  --ss-content-height: 300px;
  --ss-spacing-l: 7px;
  --ss-spacing-m: 12px;
  --ss-spacing-s: 0;
  --ss-animation-timing: 0.2s;
  --ss-border-radius: var(--radius);
  --arrow-scale: 1;

  font-weight: 350;
  font-size: 16px;
  line-height: 24px;

  &:has(.ss-open-above, .ss-open-below) {
    --arrow-scale: -1;
  }

  &.--native {
    .f-single-select__wrap {
      display: grid;
      align-items: center;
      grid-template-areas: "select";
    }

    .f-single-select__select {
      color: colors.$gray-900;
      font-weight: 350;
      border-radius: var(--radius);
      width: 100%;
      min-height: var(--height);
      border: 1px solid var(--border-color);
      background-color: colors.$white;
      padding-block: var(--padding-block);
      padding-inline: var(--padding-inline);
      padding-right: 40px;
      cursor: pointer;
      outline: none;
      margin: 0;
      appearance: none;
      grid-area: select;
      transition: border 0.3s ease;

      &:focus {
        --border-color: var(--c-conversion-500);
      }
    }
  }

  &.ss-open-below {
    --border-color: var(--c-conversion-500);

    &::after {
      scale: -1;
    }
  }

  &.--invalid {
    --border-color: #{colors.$danger};

    .ss-main {
      --border-color: #{colors.$danger};
    }
  }

  .ss-single {
    padding-right: 24px;
  }
}

.f-single-select__arrow {
  right: 16px;

  svg {
    transition: transform 0.3s ease;
    transform: scale(var(--arrow-scale));
  }
}
