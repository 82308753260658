@use "settings/s-colors" as colors;

.i-author__description {
  p {
    margin-bottom: 0;
  }
}

.i-author {
  &.--compact {
    align-items: center;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: start;
    }

    .i-author__description {
      display: none;
    }
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    align-items: start;
  }
}

.i-author__image {
  --size: 64px;

  width: var(--size);
  height: var(--size);
  max-width: var(--size);
}
