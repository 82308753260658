.g-demands {
  table {
    border-collapse: collapse;
  }

  tr {
    background-color: var(--c-primary-100);

    td {
      &:first-child {
        border-top-left-radius: 32px;
        border-bottom-left-radius: 32px;
      }

      &:last-child {
        border-top-right-radius: 32px;
        border-bottom-right-radius: 32px;
      }
    }

    &:nth-child(even) {
      background-color: var(--c-white);
    }
  }
}

.g-demands__service {
  .link__title {
    flex-shrink: 0;
  }
}
