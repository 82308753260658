.i-media {
  .img {
    aspect-ratio: 1 / 0.662;
  }

  &.--video-only {
    .img {
      aspect-ratio: 1 / 0.567;
    }
  }
}
