@use "settings/s-colors.scss" as colors;

.i-tag {
  --font-color: var(--c-primary-900);
  --background-color: var(--c-primary-100);
  --border-color: var(--background-color);

  background-color: var(--background-color);
  color: var(--font-color);
  outline: 1px solid var(--border-color);
  transition: color 0.3s ease;

  &.--outline {
    --border-color: var(--c-conversion-500);
  }

  &.--white {
    --font-color: var(--c-primary-900);
    --background-color: var(--c-white);
  }

  &.--outline-white {
    --border-color: var(--c-conversion-500);
    --background-color: #{colors.$white};
    --font-color: var(--c-conversion-500);

    transition-property: outline, color, background-color;

    @at-root a#{&} {
      &:hover {
        --border-color: var(--c-conversion-500);
        --background-color: var(--c-conversion-100);
        --font-color: var(--c-conversion-600);

        color: var(--font-color);
      }
    }
  }

  &.--outline-conversion-100 {
    --border-color: var(--c-conversion-300);
    --background-color: var(--c-conversion-100);
    --font-color: var(--c-conversion-900);
  }

  &.--blank {
    --font-color: #{colors.$gray-900};
    --background-color: transparent;

    padding: 0;
    min-height: auto;
  }

  &.--icon-before {
    .i-tag__icon {
      order: -1;
    }
  }

  &.--icon-only {
    width: var(--min-height);
    height: var(--min-height);
    min-height: auto;
    padding: 0;

    .i-tag__title {
      display: none;
    }
  }
}
