@use "settings/s-colors" as colors;

.m-header-search {
  --translate-y: -150%;
  --pointer-events: none;
  --opacity: 0;

  &.--open {
    --translate-y: -50%;
    --pointer-events: auto;
    --opacity: 1;
  }
}

.m-header-search__form {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--c-primary-100);
  top: 50%;
  transform: translateY(var(--translate-y));
  pointer-events: var(--pointer-events);
  opacity: var(--opacity);
  transition: transform 0.3s ease;
  transition-property: opacity, transform;
}

.--js--main-search-opener.btn {
  --svg-size: 24px;
}
