.base-section {
  --spacing: var(--section-spacing);
  --background: #fff;

  padding-block: var(--spacing);
  background-color: var(--background);

  &.--background {
    --background: var(--section-background-color);
  }

  &.--top-0 {
    padding-top: 0;
  }

  &.--bottom-0 {
    padding-bottom: 0;

    &:has(+ .s-separator-wave) {
      @include media-breakpoint-down(sm) {
        padding-bottom: var(--spacing);
      }
    }
  }

  &.--none {
    --spacing: 0;
  }
}
