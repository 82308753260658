@use "settings/s-fonts.scss" as fonts;

body {
  font-family: fonts.$primary;
  color: var(--c-primary-900);
  background-color: var(--c-primary-100);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale !important;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
  line-height: 1.5;
  font-weight: 325;

  &.--uro {
    --c-primary-100: #f3f3f4;
    --c-primary-300: #b2b8c1;
    --c-primary-500: #5d6e89;
    --c-primary-900: #0a2144;
    --c-conversion-100: #e8f6ff;
    --c-conversion-300: #bde4ff;
    --c-conversion-500: #0c58a9;
    --c-conversion-600: #09427e;
  }

  &.--inter {
    --c-primary-100: #f3f3f4;
    --c-primary-300: #cec5c0;
    --c-primary-500: #87614c;
    --c-primary-900: #331606;
    --c-conversion-100: #f5ecee;
    --c-conversion-300: #e2c7cc;
    --c-conversion-500: #8e2136;
    --c-conversion-600: #6a1828;
  }

  &.--dent {
    --c-primary-100: #f3f3f4;
    --c-primary-300: #acbcc6;
    --c-primary-500: #4d8299;
    --c-primary-900: #062634;
    --c-conversion-100: #e9f7f7;
    --c-conversion-300: #bfe9e8;
    --c-conversion-500: #00a9a6;
    --c-conversion-600: #007e7c;
  }

  &.--lab {
    --c-primary-100: #f3f3f4;
    --c-primary-300: #aab0b6;
    --c-primary-500: #4f5d6c;
    --c-primary-900: #222f3a;
    --c-conversion-100: #f9f2f2;
    --c-conversion-300: #eed8da;
    --c-conversion-500: #b04e4b;
    --c-conversion-600: #933b3b;
  }
}
