@use "settings/s-colors.scss" as colors;

.link {
  &.--black {
    --color: #{colors.$black};
  }

  &.--primary {
    --color: #{colors.$primary};
  }

  &.--primary-900-to-conversion-500 {
    --color: var(--c-primary-900);

    &:hover {
      --color: var(--c-conversion-500);
    }
  }

  &.--white {
    --color: #{colors.$white};
  }

  &.--gray {
    --color: #{colors.$gray};
  }

  &.--primary-900 {
    --color: var(--c-primary-900);
  }

  &.--conversion {
    --color: var(--c-conversion-500);
  }

  &.--colored:not(.--underline-initial) {
    color: var(--color);
    text-decoration-color: transparent;

    &:hover,
    &:focus {
      color: var(--color);
      text-decoration-color: var(--color);
    }
  }

  &.--colored.--underline-initial {
    text-decoration-color: inherit;
    color: var(--color);

    &:hover,
    &:focus {
      text-decoration-color: transparent;
    }
  }
}
