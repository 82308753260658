@use "settings/s-colors" as colors;

.i-references-slide {
  min-height: 384px;
}

.i-references-slide__content.entry-content {
  p:last-child {
    margin-bottom: 0;
  }
}
