.main-footer-column__order-button.--size-default {
  @include media-breakpoint-only(xl) {
    --font-size: 12px;
    --padding-y: 10px;
    --padding-x: 18px;
    --min-height: 40px;
    --svg-size: 16px;

    min-width: auto;
  }

  @include media-breakpoint-only(lg) {
    --font-size: 12px;
    --padding-y: 10px;
    --padding-x: 18px;
    --min-height: 40px;
    --svg-size: 16px;

    min-width: auto;
  }
}
