@use "settings/s-general.scss" as general;
@use "settings/s-colors.scss" as colors;

.entry-content {
  blockquote {
    padding: 16px;
    margin-block: 24px;
    position: relative;
    background-color: var(--c-conversion-100);
    border: 1px solid var(--c-conversion-300);
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    ul {
      margin-top: 0;

      li:last-child {
        margin-bottom: 0;
      }
    }

    p {
      font-style: italic;
      margin-bottom: 0;
    }
  }
}
