.f-multicheckbox {
  --rows: 152px;
  --transform: scale(1);

  &.--open {
    --rows: 1fr;
    --transform: scale(-1);
  }

  &.--no-more {
    --rows: 1fr;
  }

  .f-label {
    line-height: 24px;
  }
}

.f-multicheckbox__body {
  grid-template-rows: var(--rows);
  transition: grid-template-rows 0.5s ease;
}

.f-multicheckbox__body-inner {
  overflow: clip;
}

.f-multicheckbox__more.btn {
  justify-content: flex-start;
  padding-bottom: 8px;

  .icon {
    transform: var(--transform);
  }
}
