.i-contact {
  --logo-width: 225px;
  --logo-height: 72px;
}

.i-contact__logo {
  height: var(--logo-height);
  max-width: var(--logo-width);
  margin-inline: auto;

  img {
    max-width: 100%;
  }
}

.i-contact__separator {
  height: 1px;
}

.i-contact__info-label {
  width: 140px;
}

.i-contact__infos-table-wrapper {
  width: min(100%, 304px);
}
