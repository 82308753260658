.u-outline-0 {
  outline: 0;
}

.u-outline-8px {
  outline: 8px solid var(--c-white);
}

.u-outline-conversion {
  outline: 1px solid var(--c-conversion-500);
}

.u-outline-conversion-300 {
  outline: 1px solid var(--c-conversion-300);
}

.u-outline-primary-100 {
  outline: 1px solid var(--c-primary-100);
}
