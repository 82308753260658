@use "settings/s-colors" as colors;

.icon {
  --size: 20px;

  color: colors.$black;
  flex: 0 0 var(--size);
  max-width: var(--size);
  height: var(--size);

  svg {
    width: 100%;
    height: 100%;
  }

  &.--colored {
    color: var(--color);

    path {
      fill: var(--color);
      transition: fill 0.3s ease;
    }
  }

  &.--primary {
    --color: #{colors.$primary};
  }

  &.--conversion {
    --color: var(--c-conversion-500);
  }

  &.--white {
    --color: #{colors.$white};
  }

  &.--black {
    --color: #{colors.$black};
  }

  &.--danger {
    --color: #{colors.$danger};
  }

  &.--none {
    --color: none;
  }

  &.--4xl {
    --size: 112px;
  }

  &.--xxxl {
    --size: 48px;
  }

  &.--xxl {
    --size: 40px;
  }

  &.--xl {
    --size: 32px;
  }

  &.--lg {
    --size: 24px;
  }

  &.--md {
    --size: 20px;
  }

  &.--sm {
    --size: 16px;
  }

  &.--xs {
    --size: 10px;
  }
}
