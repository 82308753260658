.i-notification {
  min-height: 40px;

  @include media-breakpoint-down(xl) {
    &:has(~ .m-header.--user-open) {
      display: none;
    }
  }

  @include media-breakpoint-down(sm) {
    background-color: var(--c-primary-900);
  }
}

@include media-breakpoint-up(lg) {
  .i-notification__container {
    padding-inline: 46px;
  }

  .i-notification__close {
    position: absolute;
    right: 16px;
  }
}

.i-notification__close {
  @include media-breakpoint-down(sm) {
    position: absolute;
    right: 4px;
    top: 4px;
  }
}
