.f-category {
  --min-height: 64px;

  min-height: var(--min-height);
  transition: background-color 0.3s ease;
  transition-property: background-color, color;

  @include media-breakpoint-down(sm) {
    --min-height: 48px;
  }

  &:has(.f-category__input:checked) {
    background-color: var(--c-conversion-100);
    color: var(--c-conversion-500);
  }
}
