.btn {
  &.--sm {
    --font-size: 14px;
    --padding-y: 12px;
    --padding-x: 24px;
    --min-height: 48px;
  }

  &.--xs {
    --font-size: 12px;
    --padding-y: 10px;
    --padding-x: 18px;
    --min-height: 40px;
    --svg-size: 16px;
  }

  &.--xxs {
    --font-size: 14px;
    --padding-y: 8px;
    --padding-x: 8px;
    --min-height: 32px;
  }

  &.--size-default {
    @include media-breakpoint-up(sm) {
      min-width: 240px;
    }

    @include media-breakpoint-down(sm) {
      --font-size: 12px;
      --padding-y: 10px;
      --padding-x: 24px;
      --min-height: 40px;
      --svg-size: 16px;
    }
  }
}
