.base-header {
  --spacing: 0;

  margin-inline: auto;
  max-width: 928px;
  margin-bottom: var(--spacing);

  &.--left {
    align-items: flex-start;
    text-align: left;
    margin-inline: 0;
  }

  &:has(~ *) {
    --spacing: 56px;

    @include media-breakpoint-down(sm) {
      --spacing: 32px;
    }

    &.--spacing-none {
      --spacing: 0;
    }

    &.--spacing-sm {
      --spacing: 24px;
    }

    &.--spacing-md {
      --spacing: 40px;

      @include media-breakpoint-down(sm) {
        --spacing: 32px;
      }
    }
  }
}

.base-header__description {
  p {
    margin-bottom: 0;
  }
}

.base-header__toggle {
  --short-display: block;
  --long-display: none;

  &.--open {
    --short-display: none;
    --long-display: block;
  }
}

.base-header__toggle-long {
  display: var(--long-display);

  .link__icon {
    rotate: 180deg;
  }
}

.base-header__toggle-short {
  display: var(--short-display);
}
