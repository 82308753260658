@use "settings/s-colors" as colors;

.s-separator-wave {
  img {
    margin-inline: auto;
    max-width: 100vw;
    width: 100vw;
  }

  &.--up {
    background-color: colors.$white;

    img {
      background-color: colors.$white;
    }
  }

  &.--down {
    background-color: colors.$gray-100;

    img {
      background-color: colors.$white;
    }
  }

  &.--down-dark {
    background-color: colors.$gray-900;

    svg {
      height: 100%;
      width: 100%;
    }

    img,
    svg {
      background-color: colors.$gray-100;
    }
  }
}
