@use "settings/s-colors.scss" as colors;

.s-user-password {
  .base-header {
    --spacing: 56px;

    @include media-breakpoint-down(sm) {
      --spacing: 40px;
    }
  }
}
