@use "settings/s-colors" as colors;

.i-job-header {
  margin-bottom: 56px;

  @include media-breakpoint-down(sm) {
    margin-bottom: 32px;
  }
}

.i-job-header__header {
  &::before {
    content: "";
    position: absolute;
    bottom: -24px;
    left: 0;
    transform: translate(0, 50%);
    height: 1px;
    width: min(100%, 100%);
    background-color: colors.$gray-300;
  }
}

.i-job-header__info {
  row-gap: 8px;

  @include media-breakpoint-up(sm) {
    flex-direction: row;
    justify-content: space-around;
  }
}

.i-job-header__tag.i-tag.--md {
  --min-height: 24px;
  --font-size: 16px;

  min-height: var(--min-height);
}

.i-job-header__title {
  max-width: 528px;
}

.i-job-header__btn.btn {
  min-width: auto;

  @include media-breakpoint-up(md) {
    min-width: 327px;
  }
}
