@use "settings/s-colors.scss" as colors;

.i-post {
  @include media-breakpoint-down(lg) {
    max-width: 448px;
    margin-inline: auto;
  }

  .base-heading.u-h4 {
    --size: 20px;

    line-height: 24px;

    @include media-breakpoint-down(md) {
      --size: 18px;
    }
  }
}

.i-post__heading-link {
  color: var(--c-primary-900);
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    color: var(--c-conversion-600);
  }
}
