@use "settings/s-colors" as colors;

.i-hero-image {
  --img-size: 294px;

  border: 8px solid colors.$white;
  margin-bottom: 56px;

  img {
    aspect-ratio: 1 / 0.333;
    min-height: var(--img-size);
    max-height: var(--img-size);
  }

  @include media-breakpoint-down(md) {
    --img-size: 224px;

    img {
      min-height: var(--img-size);
      max-height: var(--img-size);
    }
  }

  @include media-breakpoint-down(sm) {
    margin-bottom: 32px;
  }
}
