@use "settings/s-colors" as colors;

.f-checkbox-select__header {
  min-height: 48px;
  border: 1px solid colors.$gray-200;
  background-color: var(--header-background);
}

.f-checkbox-select__clear {
  &.--active {
    display: inline-flex;
  }
}

.f-checkbox-select__toggler {
  width: 32px;
  height: 32px;
  transition: background-color 0.3s ease;

  svg {
    width: 14px;
    height: 14px;
    transition: scale 0.3s ease;
    transform: scale(var(--toggler-svg-scale));
  }

  &:hover {
    background-color: colors.$gray-200;
  }
}

.f-checkbox-select__label {
  --end: 0;

  height: 32px;

  > span {
    transition: left 2s linear;
  }

  &::after {
    content: "";
    pointer-events: none;
    position: absolute;
    display: block;
    right: 0;
    top: 0;
    width: 16px;
    height: 100%;
    background: transparent linear-gradient(270deg, var(--header-background) 0%, #fff0 100%) 0% 0% no-repeat padding-box; /* stylelint-disable-line max-line-length */
    z-index: 1;
  }

  &:hover {
    > span {
      left: var(--end);
    }
  }
}

.f-checkbox-select__body {
  background-color: colors.$white;
  border: 1px solid colors.$gray;
  width: calc(100% - 1px);
  top: calc(100% - 1px);
  left: 50%;
  z-index: 5;
  transform: var(--body-transform);
  transition: opacity 0.3s ease;
  transition-property: opacity, transform;
  transform-origin: top;
  pointer-events: var(--body-pointer-events);
  opacity: var(--body-opacity);
}

.f-checkbox-select__body-inner {
  max-height: 160px;
}

.f-checkbox-select {
  --header-background: #{colors.$white};
  --body-opacity: 0;
  --body-pointer-events: none;
  --body-transform: translateX(-50%) scaleY(0.25);
  --toggler-svg-scale: 1;

  min-width: 0;

  &.--active {
    --header-background: #{colors.$primary-100};
    --body-opacity: 1;
    --body-pointer-events: auto;
    --body-transform: translateX(-50%) scaleY(1);
    --toggler-svg-scale: -1;
  }
}
