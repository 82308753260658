.g-jobs {
  width: 100%;
  max-width: 928px;
  margin: 0 auto;

  &.--card {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    max-width: unset;
    gap: 32px;
  }
}
