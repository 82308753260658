.s-hero-slider {
  overflow: hidden;

  &:first-child {
    .i-hero-slide {
      box-shadow: none;
    }
  }
}

.s-hero-slider__slider {
  &:has(.swiper) {
    margin: -40px;
  }
}
