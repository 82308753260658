@use "settings/s-colors" as colors;

.i-hero-slide__body {
  --radius: 40px;

  max-width: 568px;
  border-radius: var(--radius);
  backdrop-filter: blur(4px);
  left: var(--offset);

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: colors.$white;
    z-index: -1;
    opacity: 0.6;
    border-radius: var(--radius);
  }

  @include media-breakpoint-down(lg) {
    position: static;
    width: 100%;
    transform: translate3d(0, 0, 0);
    left: auto;
    right: auto;
    max-width: 100%;
    gap: 16px;
  }

  @include media-breakpoint-down(lg) {
    --radius: 8px;
  }

  @include media-breakpoint-down(sm) {
    padding: 16px;
  }
}

.i-hero-slide {
  --offset: 64px;

  overflow: hidden;

  @include media-breakpoint-down(lg) {
    width: 100%;
    display: flex;
    align-items: flex-end;
    padding: 24px;
    padding-top: 0;
    min-height: 432px;
  }

  @include media-breakpoint-down(sm) {
    padding: 16px;
  }
}

.i-hero-slide__image {
  border: 8px solid colors.$white;

  @include media-breakpoint-down(lg) {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

.i-hero-slide__heading.h1 {
  @include media-breakpoint-down(xxl) {
    font-size: 48px;
  }
}
