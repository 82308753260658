@use "settings/s-colors" as colors;

.m-header-user {
  --opacity: 0;
  --pointer-events: none;
  --background: transparent;
  --svg-color: #{colors.$gray-900};

  &.--open {
    --opacity: 1;
    --pointer-events: auto;
  }

  @include media-breakpoint-up(xl) {
    &:hover {
      --opacity: 1;
      --pointer-events: auto;
      --background: #{colors.$gray-100};
      --svg-color: #{colors.$primary};
    }
  }

  @include media-breakpoint-down(xl) {
    position: static;
  }
}

.m-header-user__trigger {
  min-width: 40px;
  height: 40px;
  background-color: var(--background);

  path {
    stroke: var(--svg-color);
    transition: stroke 0.3s ease;
  }
}

.m-header-user__dropdown {
  top: 100%;
  transform: translate(16px, 0);
  width: min(calc(100vw - 16px), 328px);
  box-shadow: 0 0 20px 0 #40414026;
  opacity: var(--opacity);
  pointer-events: var(--pointer-events);
  transition: opacity 0.3s ease;

  @include media-breakpoint-up(xl) {
    padding: 0;
  }

  @include media-breakpoint-down(xl) {
    width: 100%;
    top: 0;
    left: 0;
    padding-top: 72px;
    border-radius: 0;
    background: transparent;
    box-shadow: none;
    transform: translate3d(0, 0, 0);
    transition: opacity 0.3s ease;
  }
}

.m-header-user__panel,
.m-header-user__dropdown-logged-out {
  padding: 32px;

  @include media-breakpoint-down(xl) {
    padding-inline: 0;
    background-color: transparent;
  }
}
