@use "settings/s-colors.scss" as colors;

$icon-height: 70px;

.i-usp {
  --height: 160px;

  height: var(--height);

  @include media-breakpoint-down(lg) {
    --height: 140px;
  }
}

.i-usp__icon {
  width: 112px;
  height: $icon-height;
}

.i-usp__hero-text {
  height: $icon-height;
  font-size: 44px;
  line-height: 56px;
}
