.u-h1 {
  --size: 44px;

  @include media-breakpoint-down(md) {
    --size: 36px;
  }
}

.u-h2 {
  --size: 36px;

  @include media-breakpoint-down(md) {
    --size: 28px;
  }
}

.u-h3 {
  --size: 30px;

  @include media-breakpoint-down(md) {
    --size: 20px;
  }
}

.u-h4 {
  --size: 24px;

  @include media-breakpoint-down(md) {
    --size: 18px;
  }
}

.u-h5 {
  --size: 18px;

  @include media-breakpoint-down(md) {
    --size: 16px;
  }
}

.u-h1,
.u-h2,
.u-h3,
.u-h4,
.u-h5 {
  font-size: var(--size);
}
