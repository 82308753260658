@use "settings/s-colors" as colors;

.f-newsletter {
  --border-radius: 40px;

  border-radius: var(--border-radius);
  background-color: var(--c-primary-900);

  @include media-breakpoint-up(md) {
    --border-radius: 80px;
  }
}

.f-newsletter__grid {
  @include media-breakpoint-up(xl) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.f-newsletter__header {
  gap: 16px;

  @include media-breakpoint-up(sm) {
    .base-heading {
      text-wrap: initial;
    }
  }
}

.f-newsletter__inputs-col {
  --inputs-radius: 40px;

  background-color: var(--c-conversion-100);
  border-radius: var(--inputs-radius);

  @include media-breakpoint-down(sm) {
    --inputs-radius: 24px;

    flex-direction: column;
  }
}

.f-newsletter__submit {
  @include media-breakpoint-down(md) {
    width: 100%;
  }
}

.f-newsletter__terms {
  .f-checkbox__title {
    font-weight: 325;

    a {
      --color: #{colors.$gray-900};
    }
  }
}

.f-newsletter__inputs {
  gap: 16px;

  .f-text__input {
    padding-block: 8px;
    min-height: 46px;
  }

  @include media-breakpoint-up(md) {
    gap: 0;

    .f-text {
      margin-right: -50px;

      .f-text__input {
        padding-right: 60px;
      }
    }
  }
}
