.i-payment__icon {
  --size: 48px;

  max-width: var(--size);
  height: var(--size);
  flex: 0 0 var(--size);
}

.i-payment {
  grid-template-columns: 280px 1fr 180px;
  gap: 24px;
  min-height: 120px;
}
