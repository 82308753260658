@use "settings/s-general" as general;

.s-error-404__img {
  margin-bottom: 50px;

  @include media-breakpoint-down(md) {
    margin-bottom: 32px;
  }
}

.s-error-404__sub-heading {
  max-width: 928px;
  font-weight: 350;
}

.s-error-404__perex {
  max-width: 928px;
}

.s-error-404__btn {
  margin-top: 40px;
}
