.g-widgets {
  @include media-breakpoint-between(md, xl) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 32px;
  }

  @include media-breakpoint-down(md) {
    max-width: 328px;
    margin-inline: auto;
  }
}
