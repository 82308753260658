@use "settings/s-colors" as colors;

.g-steps {
  row-gap: 72px;

  .g-steps__cell {
    &:first-child {
      .i-step.--column {
        &::before {
          width: calc(50% + 16px);
          left: 50%;
          transform: translateX(0);
        }
      }
    }

    &:last-child {
      .i-step.--column {
        &::before {
          width: calc(50% + 16px);
          left: -16px;
          transform: translateX(0);
        }
      }

      .i-step__inner.--column,
      .i-step__inner.--inline {
        &::before {
          display: none;
        }
      }

      .i-step.--inline {
        &::before {
          height: 50%;
        }
      }
    }
  }
}
