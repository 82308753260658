@use "settings/s-general.scss" as general;
@use "settings/s-colors.scss" as colors;

.entry-content {
  // Lobotomized Owls
  & > * + * {
    margin-block-start: 16px;
  }

  // Empty tags white space fix

  strong:empty,
  b:empty,
  p:empty {
    display: none;
  }

  hr {
    border: none;
    background: colors.$gray-200;
    height: 1px;
    margin-block: 48px;
  }

  .wp-caption {
    border: 0;
    padding: 0;
    text-align: center;
    max-width: 100%;
  }

  .wp-caption-text {
    font-size: 16px;
    margin-left: 0;
    transform: none;
    text-align: center;
    padding: 4px 10px;
    margin-bottom: 0;
  }
}
