@use "settings/s-colors" as colors;

.g-pricetable {
  overflow-x: auto;
}

.g-pricetable__row:nth-child(odd) {
  background-color: colors.$primary-100;
}

.g-pricetable__cell .icon {
  margin: 0 auto;
}
