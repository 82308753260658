h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
  text-wrap: balance;
  font-weight: 400;
}
