.widget_pages {
  .page_item {
    &.active {
      text-decoration: underline;
    }
  }

  .page_item_has_children {
    --submenu-display: none;
    --svg-rotate: 0deg;

    position: relative;

    .sub-menu {
      padding-left: 16px;
    }

    > .children {
      display: var(--submenu-display);
    }

    > a {
      padding-right: 48px;
    }

    &.--open {
      --submenu-display: block;
      --svg-rotate: 180deg;
    }
  }
}
