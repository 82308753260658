@use "settings/s-colors" as colors;
@use "settings/s-shadows" as shadows;

.m-nav {
  --transition-timing: 0;

  @include media-breakpoint-up(xl) {
    margin-left: auto;
    margin-right: auto;
  }

  @include media-breakpoint-down(xl) {
    position: fixed;
    right: 0;
    top: 16px;
    background-color: colors.$white;
    box-shadow: shadows.$shadow;
    width: 100%;
    max-width: 420px;
    transform: translateX(100%);
    transition: transform var(--transition-timing) ease;
    z-index: 100;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    border-radius: 32px 0 0 32px;
    padding-bottom: 48px;

    &.--open {
      transform: translateX(0);

      @include media-breakpoint-down(xl) {
        .m-nav__header {
          .m-header-search {
            display: block;
          }

          .c-language-select {
            display: block;
          }

          .m-header-user__trigger {
            display: none;
          }
        }

        .--js-main-nav-closer {
          margin: 0;
        }
      }
    }

    &.--transition {
      --transition-timing: 0.3s;
    }
  }
}

.m-nav__search-field .f-base {
  padding-right: 52px;
}

.m-nav-search__submit {
  margin-left: -48px;
}

.m-nav__search {
  @include media-breakpoint-down(xl) {
    padding-inline: 16px;
  }
}

.f-m-nav__search {
  padding: 8px;
  background-color: var(--c-primary-100);
  border-radius: 32px;
}
