.content-w-aside__content {
  .base-section {
    &:not(.--none) {
      --spacing: 56px;
    }

    .container {
      padding-inline: 0;
    }

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  @include media-breakpoint-up(xl) {
    grid-column: 1 / span 8;
  }
}

.content-w-aside__row {
  gap: 32px;
  row-gap: 64px;

  @include media-breakpoint-up(xl) {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(12, 1fr);
  }

  @include media-breakpoint-down(xl) {
    display: flex;
    flex-direction: column;
  }

  & > .content-w-aside__content:last-child {
    grid-column: 3 / span 8;
  }
}

.content-w-aside__aside {
  .widget:last-child {
    margin-bottom: 0;
  }

  @include media-breakpoint-up(xl) {
    grid-column: span 4/ -1;
  }
}

.content-w-aside {
  --spacing: var(--section-spacing);

  padding-block: var(--spacing);

  &.--top-0 {
    padding-top: 0;
  }

  &.--bottom-0 {
    padding-bottom: 0;
  }

  &.--none {
    --spacing: 0;
  }

  &.--reverse {
    .content-w-aside__aside {
      order: -1;
      grid-column: span 3;
    }

    .content-w-aside__content {
      grid-column: 4 / span 9;
    }
  }
}
