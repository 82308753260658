@use "settings/s-colors.scss" as colors;

.u-bg-white {
  background-color: var(--c-white);
}

.u-bg-white-o-70 {
  background-color: var(--c-white-o-70);
}

.u-bg-conversion {
  background-color: var(--c-conversion-500);
}

.u-bg-conversion-100 {
  background-color: var(--c-conversion-100);
}

.u-bg-conversion-300 {
  background-color: var(--c-conversion-300);
}

.u-bg-primary-900 {
  background-color: var(--c-primary-900);
}

.u-bg-primary-100 {
  background-color: var(--c-primary-100);
}

.u-bg-primary-300 {
  background-color: var(--c-primary-300);
}
