.c-base-decoration {
  --inset: 0;
  --translate-x: 0;
  --translate-y: 0;
  --depth: 0;

  inset: var(--inset);
  transform: translateX(var(--translate-x)) translateY(var(--translate-y));
  z-index: var(--depth);

  &.--depth-under {
    --depth: -1;
  }

  &.--position-center-left {
    --inset: 50% auto auto auto;
    --translate-y: -50%;

    &.--translate-center {
      --translate-x: -50%;
    }

    &.--translate-out {
      --translate-x: -100%;
    }
  }

  &.--position-center-right {
    --inset: 50% 0 auto auto;
    --translate-y: -50%;

    &.--translate-center {
      --translate-x: 50%;
    }

    &.--translate-out {
      --translate-x: 100%;
    }
  }

  &.--position-top-left {
    --inset: 0 auto auto 0;

    &.--translate-center {
      --translate-x: -50%;
      --translate-y: -50%;
    }

    &.--translate-out {
      --translate-x: -100%;
      --translate-y: -100%;
    }
  }

  &.--position-top-right {
    --inset: 0 0 auto auto;

    &.--translate-center {
      --translate-x: 50%;
      --translate-y: -50%;
    }

    &.--translate-out {
      --translate-x: 100%;
      --translate-y: -100%;
    }
  }

  &.--position-bottom-left {
    --inset: auto auto 0 0;

    &.--translate-center {
      --translate-x: -50%;
      --translate-y: 50%;
    }

    &.--translate-out {
      --translate-x: -100%;
      --translate-y: 100%;
    }
  }

  &.--position-bottom-right {
    --inset: auto 0 0 auto;

    &.--translate-center {
      --translate-x: 50%;
      --translate-y: 50%;
    }

    &.--translate-out {
      --translate-x: 100%;
      --translate-y: 100%;
    }
  }
}
