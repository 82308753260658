@use "settings/s-colors" as colors;

.s-person-filter {
  margin-top: 56px;
  margin-bottom: 56px;
}

.s-person-filter__reset-btn {
  @include media-breakpoint-down(sm) {
    margin-inline: auto;
  }
}

.s-person-filter__wrap {
  border: 1px solid colors.$gray-300;
  border-radius: 24px;

  &:not(:has(.i-selected-tag)) .s-person-filter__reset-btn {
    display: none;
  }
}

.js-open-filter.btn {
  --font-size: 16px;
  --padding-y: 8px;
  --padding-x: 16px;
}

.js-filter-clear.btn {
  min-width: auto;
}

.s-person-filter__selected-items {
  &:not(:has(.i-selected-tag)) {
    display: none;
  }
}
