.g-usps {
  @include media-breakpoint-down(lg) {
    flex-wrap: wrap;
  }
}

.g-usps__cell {
  --width: 175px;

  flex-basis: var(--width);

  @include media-breakpoint-down(lg) {
    --width: 140px;
  }
}
