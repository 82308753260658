@use "settings/s-colors" as colors;

.link.c-pagination__link {
  --background: transparent;
  --size: 40px;

  font-weight: 350;
  padding: 8px 16px;
  border-radius: 90px;
  background-color: var(--background);
  min-height: var(--size);
  transition-property: text-decoration-color, color, background-color;

  &:hover {
    --background: var(--c-conversion-100);
  }

  &.--page-number {
    padding: 8px;
    text-decoration: none;
    min-width: var(--size);
  }

  &.--active {
    --background: var(--c-white);

    color: var(--c-conversion-500) !important;
    pointer-events: none;
    outline: 1px solid var(--c-primary-300);
  }

  &.--blank {
    pointer-events: none;
  }
}

.c-pagination__items {
  @include media-breakpoint-down(md) {
    order: -1;
    flex-basis: 100%;
    justify-content: center;
  }
}

.c-pagination {
  @include media-breakpoint-down(md) {
    justify-content: center;
  }
}
