@use "settings/s-colors" as colors;

.f-date__icon {
  display: var(--icon-display);
}

.f-date__input {
  border-radius: var(--radius);
  cursor: var(--cursor);
}

.f-date {
  --border-color: #{colors.$gray};
  --radius: 4px;
  --icon-display: block;
  --cursor: pointer;

  &:not(.--native) {
    .f-date__input {
      padding-right: 48px;
    }
  }

  &.--native {
    --icon-display: none;
    --cursor: pointer;
  }

  &.--invalid {
    --border-color: #{colors.$danger};
  }
}

.f-date__wrap {
  border: 1px solid var(--border-color);
  border-radius: var(--radius);
  transition: border-color 0.3s ease;
}
